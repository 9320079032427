import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { signInAsUser } from 'src/actions/accountActions';
import helpers from 'src/helpers';
import CardAdminSignInAs from 'src/containers/AdminSignInAs/CardAdminSignInAs';
import * as Yup from 'yup';

function AdminSignInAsForm({
  classes,
  usersList,
}) {
  const dispatch = useDispatch();
  const { loading, users } = usersList;

  const isEmailCorrect = (value) => !!helpers.transform.toArray(users).find(({ email }) => email === helpers.transform.stringFromObjectLabelValue(value));

  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.mixed()
      .when({
        is: (email) => helpers.isObject(email),
        then: Yup.object().typeError('Required').test('emailIsNotCorrect', 'Choose from drop-down list', isEmailCorrect).required('Required'),
        otherwise: Yup.string().typeError('Required').test('emailIsNotCorrect', 'Choose from drop-down list', isEmailCorrect).required('Required'),
      }),
  });

  const onSubmit = async (values, {
    resetForm,
    setErrors,
    setStatus,
    setSubmitting
  }) => {
    const doWhenTry = () => {
      resetForm();
      setStatus({ success: true });
      setSubmitting(false);
    };
    const doWhenCatch = (error) => {
      setStatus({ success: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    };

    const sendData = {
      email: helpers.transform.stringToNull(helpers.transform.stringFromObjectLabelValue(values.email)),
    };

    dispatch(signInAsUser(sendData, doWhenTry, doWhenCatch));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const {
    handleSubmit,
    setFieldTouched,
  } = formik;

  const handleFocusFieldValidate = (event) => {
    helpers.formValidation.validateField(event, setFieldTouched);
  };

  return (
    <form
      id="formAdminSignInAs"
      className={classes.form}
      onSubmit={handleSubmit}
    >
      <CardAdminSignInAs
        formik={formik}
        classes={classes}
        loading={loading}
        users={users}
        handleFocusFieldValidate={handleFocusFieldValidate}
      />
    </form>
  );
}

AdminSignInAsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  usersList: PropTypes.object.isRequired,
};

AdminSignInAsForm.defaultProps = {};

export default AdminSignInAsForm;
