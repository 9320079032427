import axios from 'src/utils/axios';
import { NOTIFICATION_STATUS } from 'src/constants';
import { addNotification } from 'src/actions/notificationActions';

export const SET_CURRENT_MESSAGE = '@message/set-current-message';
export const SET_FILE_UPLOAD_TO_MESSAGE = '@message/set-file-upload-to-message';
export const SET_IS_MODAL_VISIBLE = '@message/set-modal-visible';
export const SET_IS_MODAL_LOADING = '@message/set-modal-loading';
export const GET_MESSAGES = '@message/get-messages';
export const GET_MESSAGES_SUCCESS = '@message/get-messages-success';
export const GET_MESSAGES_ERROR = '@message/get-messages-error';
export const UPDATE_MESSAGE = '@message/update-message';
export const CREATE_MESSAGE = '@message/create-message';
export const DELETE_MESSAGE = '@message/delete-message';
export const GET_USER_MESSAGE_CODES = '@message/get-user-message-codes';

export const SET_MESSAGE_NAVIGATION_TAB = '@message/set-message-navigation-tab';

export const setCurrentMessage = (message) => ({
  type: SET_CURRENT_MESSAGE,
  payload: message
});

export const setFileUploadToMessage = (file) => ({
  type: SET_FILE_UPLOAD_TO_MESSAGE,
  payload: file
});

export const setIsModalVisible = () => ({
  type: SET_IS_MODAL_VISIBLE
});

export const setIsModalLoading = (loading) => ({
  type: SET_IS_MODAL_LOADING,
  payload: loading
});

export const getMessages = () => async (dispatch, getState) => {
  try {
    const { currentNavigationTab } = getState()?.messages || {};
    dispatch({
      type: GET_MESSAGES,
    });
    const response = await axios.get('/api/followupmessages', {
      params: {
        currentNavigationTab,
      },
    });
    dispatch({
      type: GET_MESSAGES_SUCCESS,
      payload: response.data
    });
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(GET_MESSAGES_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_MESSAGES_ERROR,
    });
  }
};

export const getUserMessageCode = () => async (dispatch) => {
  try {
    dispatch(setIsModalLoading(true));
    const response = await axios.post('/api/followupmessages/used-code', {
      type: 'user',
    });
    dispatch({
      type: GET_USER_MESSAGE_CODES,
      payload: response.data
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_USER_MESSAGE_CODES, message || error?.message || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch(setIsModalLoading(false));
  }
};

export const createMessage = (messageData, doWhenTry = () => {}, doWhenCatch = () => {}) => async (dispatch) => {
  try {
    dispatch(setIsModalLoading(true));
    const response = await axios.post('/api/followupmessages', messageData);
    dispatch({
      type: CREATE_MESSAGE,
      payload: response?.data,
    });
    dispatch(addNotification(CREATE_MESSAGE, 'Message has been created', NOTIFICATION_STATUS.SUCCESS));
    dispatch(getUserMessageCode());
    dispatch(getMessages());
    doWhenTry();
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(CREATE_MESSAGE, message || error?.message || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch(setIsModalLoading(false));
    doWhenCatch(error, message);
  }
};

export const updateMessage = (messageChanges, doWhenTry = () => {}, doWhenCatch = () => {}) => async (dispatch) => {
  try {
    dispatch(setIsModalLoading(true));
    const response = await axios.put('/api/followupmessages', { messageChanges });
    dispatch({
      type: UPDATE_MESSAGE,
      payload: response?.data?.message,
    });
    dispatch(addNotification(UPDATE_MESSAGE, 'Message updated', NOTIFICATION_STATUS.SUCCESS));
    dispatch(getUserMessageCode());
    dispatch(getMessages());
    doWhenTry();
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(UPDATE_MESSAGE, message || error?.message || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch(setIsModalLoading(false));
    doWhenCatch(error, message);
  }
};

export const setMessageNavigationTab = (tabName) => async (dispatch) => {
  dispatch({
    type: SET_MESSAGE_NAVIGATION_TAB,
    payload: tabName
  });
};

export const deleteMessage = (messageId) => async (dispatch) => {
  try {
    dispatch(setIsModalLoading(true));
    await axios.delete(`/api/followupmessages/${messageId}`);
    dispatch({
      type: DELETE_MESSAGE,
    });
    dispatch(addNotification(DELETE_MESSAGE, 'Message has been deleted', NOTIFICATION_STATUS.SUCCESS));
    dispatch(getUserMessageCode());
    dispatch(getMessages());
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(DELETE_MESSAGE, message || error?.message || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch(setIsModalLoading(false));
  }
};
