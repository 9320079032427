/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_PARTY_VENUE,
  GET_PARTY_VENUE_SUCCESS,
  GET_PARTY_VENUE_ERROR,
  DELETE_PARTY_VENUE,
  DELETE_PARTY_VENUE_SUCCESS,
  DELETE_PARTY_VENUE_ERROR,
  CLEAR_PARTY_VENUE_WAS_DELETED,
  UPDATE_PARTY_VENUE,
  UPDATE_PARTY_VENUE_SUCCESS,
  UPDATE_PARTY_VENUE_ERROR,
  CREATE_PARTY_VENUE,
  CREATE_PARTY_VENUE_SUCCESS,
  CREATE_PARTY_VENUE_ERROR,
  SET_PARTY_VENUE_DETAILS_TAB,
  SET_FORM_CREATE_UPDATE_PARTY_VENUE,
  SET_IS_MODAL_VISIBLE,
  SET_IS_MODAL_LOADING,
  SET_MODAL_MESSAGE,
} from 'src/actions/partyVenueDetailsActions';

const modalMessage = {
  isVisible: false,
  modalTitle: null,
  modalBody: '',
};

const initialState = {
  partyVenue: {},
  partyVenueWasDeleted: false,
  loading: false,
  currentNavigationTab: 'details',
  modalMessage,
  isModalVisible: false,
  isModalLoading: false,
};

const partyVenueDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PARTY_VENUE: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_PARTY_VENUE_SUCCESS: {
      return produce(state, (draft) => {
        draft.partyVenue = payload?.partyVenue || {};
        draft.loading = false;
      });
    }

    case GET_PARTY_VENUE_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case DELETE_PARTY_VENUE: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case DELETE_PARTY_VENUE_SUCCESS: {
      return produce(state, (draft) => {
        draft.partyVenue = payload ? initialState.partyVenue : state.partyVenue;
        draft.loading = false;
        draft.partyVenueWasDeleted = true;
      });
    }

    case DELETE_PARTY_VENUE_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case CLEAR_PARTY_VENUE_WAS_DELETED: {
      return produce(state, (draft) => {
        draft.partyVenueWasDeleted = false;
      });
    }

    case CREATE_PARTY_VENUE:
    case UPDATE_PARTY_VENUE: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case CREATE_PARTY_VENUE_SUCCESS:
    case CREATE_PARTY_VENUE_ERROR:
    case UPDATE_PARTY_VENUE_SUCCESS:
    case UPDATE_PARTY_VENUE_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_PARTY_VENUE_DETAILS_TAB: {
      return produce(state, (draft) => {
        draft.currentNavigationTab = payload || initialState.currentNavigationTab;
      });
    }

    case SET_FORM_CREATE_UPDATE_PARTY_VENUE: {
      return produce(state, (draft) => {
        draft.formCreateUpdateContact = payload ? {
          ...state.formCreateUpdateVenue,
          ...payload,
        } : initialState.formCreateUpdateVenue;
      });
    }

    case SET_IS_MODAL_LOADING: {
      return produce(state, (draft) => {
        draft.isModalLoading = payload;
      });
    }

    case SET_IS_MODAL_VISIBLE: {
      return produce(state, (draft) => {
        draft.isModalVisible = !state.isModalVisible;
      });
    }

    case SET_MODAL_MESSAGE: {
      return produce(state, (draft) => {
        draft.modalMessage = {
          ...state.modalMessage,
          ...payload,
        };
      });
    }

    default: {
      return state;
    }
  }
};

export default partyVenueDetailsReducer;
