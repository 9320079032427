import { STYLE_OPTIONS } from 'src/constants';

const typographyContainerStyle = {
  typography: {
    fontFamily: STYLE_OPTIONS.FONT_FAMILY,
    color: STYLE_OPTIONS.COLOR_TEXT,

    '& h2': {
      fontSize: '24px',
      marginTop: 12,
      marginBottom: 7,
    },

    '& h3': {
      fontSize: '20px',
      marginTop: 10,
      marginBottom: 7,
    },

    '& h4': {
      fontSize: '14px',
      marginTop: 10,
      marginBottom: 7,
    },

    '& h2:first-child, & h3:first-child, , & h4:first-child': {
      marginTop: 0,
    },

    '& p': {
      margin: '5px 0px',
    },

    '& ul': {
      margin: '15px 20px',
    },

    '& li': {
      margin: '7px 0px',
    },

    '& .red': {
      color: STYLE_OPTIONS.COLOR_RED,
    },

    '& .errorBox': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'start',
      padding: '10px 0px',

      '& .errorBoxLeft': {
        width: 22,
        marginRight: 15,
      },

      '& .errorBoxRight': {
        width: '100%',
        paddingTop: 1,
      }
    },

    '& .breakSpaces': {
      whiteSpace: 'break-spaces',
    },

    '& a': {
      color: '#263238',
      fontWeight: 500,

      '&:hover': {
        textDecoration: 'none',
      },
    },

    '& iframe': {
      maxWidth: '100%',
    },

  },
};

export default typographyContainerStyle;
