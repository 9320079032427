const fontFamily = 'Roboto';
const color = '#546167';
const backgroundColor = '#ffffff';

const tooltipStyles = {
  tooltip: {
    fontFamily,
    color,
    fontSize: '12px',
    backgroundColor,
    boxShadow: `0px 0px 7px 0px ${color}`,
    padding: 12,
    whiteSpace: 'pre-line',

    '& .nbsp': {
      display: 'inline-block',
      width: '0.3em',
    },
  }
};

export default tooltipStyles;
