const tableCellPadding = {
  paddingTop: 6,
  paddingRight: 16,
  paddingBottom: 6,
  paddingLeft: 16,
};

const resultsTableStyle = {
  root: {
    '& .ps__rail-x': {
      opacity: '0.25'
    },
    '& .ps__rail-y': {
      opacity: '0.25'
    },
  },
  table: {
    '& .MuiTableCell-sizeSmall': {
      ...tableCellPadding,
    },
  },
  tableHead: {
    '& th': {
      height: 50,
    }
  },
  tableRow: {
    height: 20,
    verticalAlign: 'top',
    '& td.MuiTableCell-paddingCheckbox': {
      ...tableCellPadding,
      paddingTop: 3,
    }
  },
  actions: {
    minWidth: 125
  },
  rows: {
    height: 10
  },
};

export default resultsTableStyle;
