/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_LOADING,
  SET_CURRENT_NAVIGATION_TAB,
} from 'src/actions/followUpActions';

// const initialFollowUpSettings = {
// };

const initialCurrentNavigationTab = 'orders';

const initialState = {
  currentNavigationTab: initialCurrentNavigationTab,
};

const followUpReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING: {
      return produce(state, (draft) => {
        draft.loading = payload;
      });
    }

    case SET_CURRENT_NAVIGATION_TAB: {
      return produce(state, (draft) => {
        draft.currentNavigationTab = payload || initialCurrentNavigationTab;
      });
    }

    default: {
      return state;
    }
  }
};

export default followUpReducer;
