import React from 'react';
import PropTypes from 'prop-types';
import TextFieldEmoji from 'src/components/TextFieldFormik/TextFieldEmoji';

import helpTextForFields from 'src/views/Settings/MessageView/EditMessage/options';

function TextFieldEmojiMaxLength({
  title,
  loading,
  valueName,
  currentValue,
  touchedValue,
  errorValue,
  maxTextMessageLength,
  handleBlur,
  handleChange,
  setFieldValue,
  setFieldTouched,
  defaultScreenSpacing,
  screenName,
}) {
  return (
    <TextFieldEmoji
      title={title}
      className="formGridGrope"
      loading={loading}
      valueName={valueName}
      currentValue={currentValue}
      handleBlur={handleBlur}
      handleChange={handleChange}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      maxTextMessageLength={maxTextMessageLength}
      errorValue={errorValue}
      touchedValue={touchedValue}
      defaultScreenSpacing={defaultScreenSpacing}
      helpTextForTitle={helpTextForFields.systemGenMessage}
      screenName={screenName}
    />
  );
}

TextFieldEmojiMaxLength.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  valueName: PropTypes.string.isRequired,
  currentValue: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  maxTextMessageLength: PropTypes.number.isRequired,
  defaultScreenSpacing: PropTypes.number.isRequired,
  errorValue: PropTypes.any,
  touchedValue: PropTypes.any,
  screenName: PropTypes.string,
};

TextFieldEmojiMaxLength.defaultProps = {
  errorValue: null,
  touchedValue: null,
  screenName: null,
};

export default TextFieldEmojiMaxLength;
