import React from 'react';
import PropTypes from 'prop-types';
import { Button, SvgIcon } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Popover from 'src/components/Popover/';
import { ReactComponent as EmojiIcon } from 'src/assets/images/emoji.svg';

import popoverEmojiStyle from 'src/components/PopoverEmoji/popoverEmoji.style';

function PopoverEmoji({
  classes,
  addEmoji,
  currentValue,
}) {
  const handleAddEmoji = (event) => {
    addEmoji(event, currentValue);
  };

  return (
    <Popover
      className={classes.popover}
      text={(
        <Picker
          data={data}
          style={{ maxWidth: '100%', width: 300 }}
          onEmojiSelect={handleAddEmoji}
        />
      )}
    >
      <Button className={classes.svgButton}>
        <SvgIcon
          className="svgIcon"
          component={EmojiIcon}
          viewBox="0 0 32 32"
        />
      </Button>
    </Popover>
  );
}

PopoverEmoji.propTypes = {
  classes: PropTypes.object.isRequired,
  addEmoji: PropTypes.func.isRequired,
  currentValue: PropTypes.string.isRequired,
};

export default withStyles(PopoverEmoji, popoverEmojiStyle);
