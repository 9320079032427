import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeNotification } from 'src/actions/notificationActions';

function Notifications() {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notification);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    notifications.forEach(({ key, message, type }) => {
      enqueueSnackbar(message, {
        key,
        variant: type,
      });
      dispatch(removeNotification(key));
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
}

export default Notifications;
