/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_CURRENT_MESSAGE,
  SET_FILE_UPLOAD_TO_MESSAGE,
  SET_LOADING,
  SET_CURRENT_NAVIGATION_TAB,
  SET_FILL_DEFAULT_VALUES,
  GET_FOLLOW_UPS,
  GET_FOLLOW_UPS_SUCCESS,
  GET_FOLLOW_UPS_ERROR,
  SET_FOLLOW_UPS_QUERY,
  SET_SELECTED_FOLLOW_UPS,
  SET_BACK_TO_FOLLOW_UP_LIST,
} from 'src/actions/orderSettingsActions';

const initialCurrentNavigationTab = 'general';

const query = {
  search: '',
  status: 'common',
  sort: [['code', 'asc']],
  count: 0,
};

const backToFollowUpList = {
  followUps: [],
  query: {},
  active: false,
  comeBack: false,
};

const initialState = {
  orderSettings: {},
  followUps: [],
  selectedFollowUps: [],
  loading: false,
  currentNavigationTab: initialCurrentNavigationTab,
  fillDefaultValues: false,
  formOrderSettingsTabGeneral: {},
  query,
  backToFollowUpList,
};

const orderSettingsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CURRENT_MESSAGE: {
      return produce(state, (draft) => {
        draft.currentMessage = payload;
      });
    }

    case SET_FILE_UPLOAD_TO_MESSAGE: {
      return produce(state, (draft) => {
        draft.currentMessage = {
          ...state.currentMessage,
          fileUpload: payload,
        };
      });
    }

    case SET_LOADING: {
      return produce(state, (draft) => {
        draft.loading = payload;
      });
    }

    case SET_CURRENT_NAVIGATION_TAB: {
      return produce(state, (draft) => {
        draft.currentNavigationTab = payload || initialCurrentNavigationTab;
      });
    }

    case SET_FILL_DEFAULT_VALUES: {
      return produce(state, (draft) => {
        draft.fillDefaultValues = payload;
      });
    }

    case GET_FOLLOW_UPS: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_FOLLOW_UPS_SUCCESS: {
      return produce(state, (draft) => {
        draft.followUps = payload?.followUps || [];
        draft.query.count = payload?.count || 0;
        draft.loading = false;
        draft.selectedFollowUps = [];
      });
    }

    case GET_FOLLOW_UPS_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_FOLLOW_UPS_QUERY: {
      return produce(state, (draft) => {
        draft.query = payload ? {
          ...state.query,
          ...payload
        } : initialState.query;
      });
    }

    case SET_SELECTED_FOLLOW_UPS: {
      return produce(state, (draft) => {
        draft.selectedFollowUps = payload || [];
      });
    }

    case SET_BACK_TO_FOLLOW_UP_LIST: {
      return produce(state, (draft) => {
        draft.backToFollowUpList = payload ? {
          ...state.backToFollowUpList,
          ...payload,
        } : backToFollowUpList;
      });
    }

    default: {
      return state;
    }
  }
};

export default orderSettingsReducer;
