import { STYLE_OPTIONS } from 'src/constants';

const colorPurple = '#5850EC';
const fontFamily = STYLE_OPTIONS.FONT_FAMILY;

const changeCampaignStyle = {
  form: {},

  cardBox: {
    paddingTop: 10,
  },

  optionValues: {
    color: colorPurple,
    fontFamily,
    fontSize: '14px',
  },

  windowText: {
    paddingTop: 0,
    paddingBottom: 0,
    color: STYLE_OPTIONS.COLOR_TEXT,
    fontFamily: STYLE_OPTIONS.FONT_FAMILY,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },

  ulPurple: {
    color: colorPurple,
    fontSize: '16px',
    lineHeight: '20px',
  },

  line: {
    width: '100%',
    height: 8,
    background: STYLE_OPTIONS.COLOR_BLUE,
    marginTop: 36,
    marginBottom: 24,
  },

  selection: {
    paddingTop: 10,
    paddingBottom: 30,
  },

};

export default changeCampaignStyle;
