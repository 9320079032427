import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import searchLineStyle from 'src/components/View/Header/searchLine.style';

function SearchLine({
  classes,
  children,
}) {
  return children && (
  <div className={classes.root}>
    {children}
  </div>
  );
}

SearchLine.propTypes = {
  children: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
};
SearchLine.defaultProps = {};

export default withStyles(SearchLine, searchLineStyle, { withTheme: true });
