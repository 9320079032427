import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';
import { createFilterOptions } from '@mui/material/Autocomplete';
import AutocompleteFieldFormik from 'src/components/AutocompleteFieldFormik';
import helpers from 'src/helpers';

function CardAdminSignInAs({
  classes,
  formik,
  loading,
  users,
  handleFocusFieldValidate,
}) {
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    setFieldValue
  } = formik;

  const renderOption = (props, option) => (
    // <li key={option} {...props}>
    //   {option?.label || option}
    // </li>
    <div {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
      <b>{`${option.firstName} ${option.lastName} - ${option.email}`}</b>
      <div className={classes.optionValues}>
        {option.primaryCompany}
        {option.userTitleName && (
          <div>
            {/* <b>{option.userTitleName}</b> */}
            <b><i>{option.userTitleName}</i></b>
          </div>
        )}
        {option.city && (
          <div>
            {`${option.city}, ${option.region} ${option.country}`}
          </div>
        )}
      </div>
    </div>
  );

  const options = helpers.transform.toArray(users).map(({
    email,
    firstName,
    lastName,
    city,
    region,
    country,
    primaryCompany,
    userTitleName,
  }) => ({
    // label: [email, firstName, lastName, city, region, country, primaryCompany].filter((item) => !helpers.isEmpty(item)).join(' '),
    label: email,
    value: email,
    email,
    firstName,
    lastName,
    city,
    region,
    country,
    primaryCompany,
    userTitleName,
  }));

  const filterOptions = createFilterOptions({
    stringify: ({
      email, firstName, lastName, city, region, country, primaryCompany, userTitleName
    }) => `${email} ${firstName} ${lastName} ${city} ${region} ${country} ${primaryCompany} ${userTitleName}`
  });

  return (
    <div className={classes.cardBox}>
      <Grid container>
        <Grid xs={12}>
          <AutocompleteFieldFormik
            setFieldValue={setFieldValue}
            touchedValue={touched.email}
            errorValue={errors.email}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleFocus={handleFocusFieldValidate}
            values={values.email}
            name="email"
            label="User"
            disabled={loading}
            options={options}
            filterOptions={filterOptions}
            renderOption={renderOption}
            noOptionsText={null}
            multiple={false}
            disableCloseOnSelect={false}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            freeSolo
            required
          />
        </Grid>
      </Grid>
    </div>
  );
}

CardAdminSignInAs.propTypes = {
  handleFocusFieldValidate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
};

export default CardAdminSignInAs;
