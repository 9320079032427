/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_PARTY_TYPE,
  GET_PARTY_TYPE_SUCCESS,
  GET_PARTY_TYPE_ERROR,
  DELETE_PARTY_TYPE,
  DELETE_PARTY_TYPE_SUCCESS,
  DELETE_PARTY_TYPE_ERROR,
  CLEAR_PARTY_TYPE_WAS_DELETED,
  UPDATE_PARTY_TYPE,
  UPDATE_PARTY_TYPE_SUCCESS,
  UPDATE_PARTY_TYPE_ERROR,
  CREATE_PARTY_TYPE,
  CREATE_PARTY_TYPE_SUCCESS,
  CREATE_PARTY_TYPE_ERROR,
  UPDATE_PARTY_TYPE_GUEST_MESSAGING,
  UPDATE_PARTY_TYPE_GUEST_MESSAGING_SUCCESS,
  UPDATE_PARTY_TYPE_GUEST_MESSAGING_ERROR,
  SET_PARTY_TYPE_DETAILS_TAB,
  SET_FORM_CREATE_UPDATE_PARTY_TYPE,
  SET_FORM_UPDATE_PARTY_TYPE_GUEST_MESSAGING,
  SET_IS_MODAL_VISIBLE,
  SET_IS_MODAL_LOADING,
  SET_MODAL_MESSAGE,
  SET_VALUES_WELCOME_MESSAGE,
  GET_FOLLOW_UP_MESSAGES,
  GET_FOLLOW_UP_MESSAGES_SUCCESS,
  GET_FOLLOW_UP_MESSAGES_ERROR,
  REPLACE_SYSTEM_GEN_MESSAGE,
  REPLACE_SYSTEM_GEN_MESSAGE_SUCCESS,
  REPLACE_SYSTEM_GEN_MESSAGE_ERROR,
  SET_UPDATE_PARTY_JOIN_ACTIONS,
} from 'src/actions/partyTypeDetailsActions';

const modalMessage = {
  isVisible: false,
  modalTitle: null,
  modalBody: '',
};

const welcomeMessage = {
  systemGenMessageReplaced: false,
  attachment: false,
  attachmentContainer: null,
  loading: false,
};

const followUpMessages = {
  followUpMessageList: [],
  loading: false,
};

const initialState = {
  partyType: {},
  partyTypeWasDeleted: false,
  loading: false,
  currentNavigationTab: 'details',
  modalMessage,
  formEditPartyType: {},
  welcomeMessage,
  followUpMessages,
  isModalVisible: false,
  isModalLoading: false,
  updatePartyJoinActions: false,
};

const partyTypeDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PARTY_TYPE: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_PARTY_TYPE_SUCCESS: {
      return produce(state, (draft) => {
        draft.partyType = payload?.partyType || {};
        draft.loading = false;
      });
    }

    case GET_PARTY_TYPE_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case DELETE_PARTY_TYPE: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case DELETE_PARTY_TYPE_SUCCESS: {
      return produce(state, (draft) => {
        draft.partyType = payload ? initialState.partyType : state.partyType;
        draft.loading = false;
        draft.partyTypeWasDeleted = true;
      });
    }

    case DELETE_PARTY_TYPE_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case CLEAR_PARTY_TYPE_WAS_DELETED: {
      return produce(state, (draft) => {
        draft.partyTypeWasDeleted = false;
      });
    }

    case CREATE_PARTY_TYPE:
    case UPDATE_PARTY_TYPE: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case CREATE_PARTY_TYPE_SUCCESS:
    case CREATE_PARTY_TYPE_ERROR:
    case UPDATE_PARTY_TYPE_SUCCESS:
    case UPDATE_PARTY_TYPE_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case UPDATE_PARTY_TYPE_GUEST_MESSAGING: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case UPDATE_PARTY_TYPE_GUEST_MESSAGING_SUCCESS:
    case UPDATE_PARTY_TYPE_GUEST_MESSAGING_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_PARTY_TYPE_DETAILS_TAB: {
      return produce(state, (draft) => {
        draft.currentNavigationTab = payload || initialState.currentNavigationTab;
      });
    }

    case SET_FORM_CREATE_UPDATE_PARTY_TYPE: {
      return produce(state, (draft) => {
        // draft.formCreateUpdateContact = payload ? {
        draft.formEditPartyType = payload ? {
          ...state.formEditPartyType,
          ...payload,
        } : initialState.formEditPartyType;
      });
    }

    case SET_FORM_UPDATE_PARTY_TYPE_GUEST_MESSAGING: {
      return produce(state, (draft) => {
        draft.formPartyTypeGuestMessaging = payload ? {
          ...state.formPartyTypeGuestMessaging,
          ...payload,
        } : initialState.formPartyTypeGuestMessaging;
      });
    }

    case SET_VALUES_WELCOME_MESSAGE: {
      return produce(state, (draft) => {
        draft.welcomeMessage = payload ? {
          ...state.welcomeMessage,
          ...payload,
        } : welcomeMessage;
      });
    }

    case SET_IS_MODAL_LOADING: {
      return produce(state, (draft) => {
        draft.isModalLoading = payload;
      });
    }

    case SET_IS_MODAL_VISIBLE: {
      return produce(state, (draft) => {
        draft.isModalVisible = !state.isModalVisible;
      });
    }

    case SET_MODAL_MESSAGE: {
      return produce(state, (draft) => {
        draft.modalMessage = {
          ...state.modalMessage,
          ...payload,
        };
      });
    }

    case GET_FOLLOW_UP_MESSAGES: {
      return produce(state, (draft) => {
        draft.followUpMessages.loading = true;
      });
    }

    case GET_FOLLOW_UP_MESSAGES_SUCCESS:
    case GET_FOLLOW_UP_MESSAGES_ERROR: {
      return produce(state, (draft) => {
        draft.followUpMessages.loading = false;
        draft.followUpMessages.followUpMessageList = payload ? [
          ...state.followUpMessages.followUpMessageList,
          ...payload,
        ] : followUpMessages.followUpMessageList;
      });
    }

    case REPLACE_SYSTEM_GEN_MESSAGE: {
      return produce(state, (draft) => {
        draft.welcomeMessage.loading = true;
      });
    }

    case REPLACE_SYSTEM_GEN_MESSAGE_SUCCESS:
    case REPLACE_SYSTEM_GEN_MESSAGE_ERROR: {
      return produce(state, (draft) => {
        draft.welcomeMessage.loading = false;
        draft.welcomeMessage.systemGenMessageReplaced = payload || false;
      });
    }

    case SET_UPDATE_PARTY_JOIN_ACTIONS: {
      return produce(state, (draft) => {
        draft.updatePartyJoinActions = payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default partyTypeDetailsReducer;
