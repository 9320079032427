/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_FOLLOW_UP,
  GET_FOLLOW_UP_SUCCESS,
  GET_FOLLOW_UP_ERROR,
  UPDATE_FOLLOW_UP,
  UPDATE_FOLLOW_UP_SUCCESS,
  UPDATE_FOLLOW_UP_ERROR,
  UPDATE_FOLLOW_UP_GUEST_MESSAGING,
  UPDATE_FOLLOW_UP_GUEST_MESSAGING_SUCCESS,
  UPDATE_FOLLOW_UP_GUEST_MESSAGING_ERROR,
  SET_FOLLOW_UP_DETAILS_TAB,
  SET_FORM_FOLLOW_UP_MESSAGE,
  SET_IS_MODAL_VISIBLE,
  SET_IS_MODAL_LOADING,
  SET_MODAL_MESSAGE,
  SET_VALUES_FOLLOW_UP_MESSAGE,
} from 'src/actions/orderFollowUpDetailsActions';

const followUpMessage = {
  attachment: false,
  attachmentContainer: null,
  loading: false,
};

const initialState = {
  followUp: {},
  loading: false,
  currentNavigationTab: 'message',
  formFollowUpMessage: {},
  followUpMessage,
  isModalVisible: false,
  isModalLoading: false,
};

const followUpDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_FOLLOW_UP: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_FOLLOW_UP_SUCCESS: {
      return produce(state, (draft) => {
        draft.followUp = payload?.message || {};
        draft.loading = false;
      });
    }

    case GET_FOLLOW_UP_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case UPDATE_FOLLOW_UP: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case UPDATE_FOLLOW_UP_SUCCESS:
    case UPDATE_FOLLOW_UP_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case UPDATE_FOLLOW_UP_GUEST_MESSAGING: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case UPDATE_FOLLOW_UP_GUEST_MESSAGING_SUCCESS:
    case UPDATE_FOLLOW_UP_GUEST_MESSAGING_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_FOLLOW_UP_DETAILS_TAB: {
      return produce(state, (draft) => {
        draft.currentNavigationTab = payload || initialState.currentNavigationTab;
      });
    }

    case SET_FORM_FOLLOW_UP_MESSAGE: {
      return produce(state, (draft) => {
        draft.formFollowUpMessage = payload ? {
          ...state.formFollowUpMessage,
          ...payload,
        } : initialState.formFollowUpMessage;
      });
    }

    case SET_VALUES_FOLLOW_UP_MESSAGE: {
      return produce(state, (draft) => {
        draft.followUpMessage = payload ? {
          ...state.followUpMessage,
          ...payload,
        } : followUpMessage;
      });
    }

    case SET_IS_MODAL_LOADING: {
      return produce(state, (draft) => {
        draft.isModalLoading = payload;
      });
    }

    case SET_IS_MODAL_VISIBLE: {
      return produce(state, (draft) => {
        draft.isModalVisible = !state.isModalVisible;
      });
    }

    case SET_MODAL_MESSAGE: {
      return produce(state, (draft) => {
        draft.modalMessage = {
          ...state.modalMessage,
          ...payload,
        };
      });
    }

    default: {
      return state;
    }
  }
};

export default followUpDetailsReducer;
