import axios from 'src/utils/axios';
import helpers from 'src/helpers';
import { NOTIFICATION_STATUS } from 'src/constants';
import { addNotification } from 'src/actions/notificationActions';

export const GET_PARTY_TYPE = '@partytypes/get-party-type';
export const GET_PARTY_TYPE_SUCCESS = '@partytypes/get-party-type-success';
export const GET_PARTY_TYPE_ERROR = '@partytypes/get-party-type-error';

export const DELETE_PARTY_TYPE = '@partytypes/del-party-type';
export const DELETE_PARTY_TYPE_SUCCESS = '@partytypes/del-party-type-success';
export const DELETE_PARTY_TYPE_ERROR = '@partytypes/del-party-type-error';
export const CLEAR_PARTY_TYPE_WAS_DELETED = '@partytypes/clear-party-type-was-deleted';

export const UPDATE_PARTY_TYPE = '@partytypes/update-party-type';
export const UPDATE_PARTY_TYPE_SUCCESS = '@partytypes/update-party-type-success';
export const UPDATE_PARTY_TYPE_ERROR = '@partytypes/update-party-type-error';

export const UPDATE_PARTY_TYPE_GUEST_MESSAGING = '@partytypes/update-party-type-guest-messaging';
export const UPDATE_PARTY_TYPE_GUEST_MESSAGING_SUCCESS = '@partytypes/update-party-type-guest-messaging-success';
export const UPDATE_PARTY_TYPE_GUEST_MESSAGING_ERROR = '@partytypes/update-party-type-guest-messaging-error';

export const GET_FOLLOW_UP_MESSAGES = '@partytypes/get-follow-up-messages';
export const GET_FOLLOW_UP_MESSAGES_SUCCESS = '@partytypes/get-follow-up-messages-success';
export const GET_FOLLOW_UP_MESSAGES_ERROR = '@partytypes/get-follow-up-messages-error';

export const REPLACE_SYSTEM_GEN_MESSAGE = '@partytypes/replace-system-gen-message';
export const REPLACE_SYSTEM_GEN_MESSAGE_SUCCESS = '@partytypes/replace-system-gen-message-success';
export const REPLACE_SYSTEM_GEN_MESSAGE_ERROR = '@partytypes/replace-system-gen-message-error';

export const CREATE_PARTY_TYPE = '@partytypes/create-party-type';
export const CREATE_PARTY_TYPE_SUCCESS = '@partytypes/create-party-type-success';
export const CREATE_PARTY_TYPE_ERROR = '@partytypes/create-party-type-error';

export const SET_PARTY_TYPE_DETAILS_TAB = '@partytypes/set-party-type-details-tab';

export const SET_GO_TO_PARTY_TYPE_DETAILS = '@partytypes/set-go-to-party-type-details';
export const CLEAR_GO_TO_PARTY_TYPE_DETAILS = '@partytypes/clear-go-to-party-type-details';

export const SET_FORM_CREATE_UPDATE_PARTY_TYPE = '@partytypes/set-form-create-update-party-type';

export const SET_FORM_UPDATE_PARTY_TYPE_GUEST_MESSAGING = '@partytypes/set-form-update-party-guest-messaging';

export const SET_VALUES_WELCOME_MESSAGE = '@partytypes/set-values-welcome-message';

export const SET_IS_MODAL_LOADING = '@partytypes/set-modal-loading';
export const SET_IS_MODAL_VISIBLE = '@partytypes/set-modal-visible';
export const SET_MODAL_MESSAGE = '@partytypes/set-modal-message';

export const SET_UPDATE_PARTY_JOIN_ACTIONS = '@partytypes/set-update-party-join-actions';

export const setIsModalVisible = () => ({
  type: SET_IS_MODAL_VISIBLE
});

export const setIsModalLoading = (loading) => ({
  type: SET_IS_MODAL_LOADING,
  payload: loading
});

export const setModalMessage = (isVisible, modalTitle, modalBody) => ({
  type: SET_MODAL_MESSAGE,
  payload: {
    isVisible,
    modalTitle,
    modalBody,
  },
});

export const getPartyType = (partyTypeId) => async (dispatch) => {
  dispatch({
    type: GET_PARTY_TYPE,
  });

  try {
    const response = await axios.get(`/api/partysettings/partyTypes/${partyTypeId}`);
    dispatch({
      type: GET_PARTY_TYPE_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_PARTY_TYPE_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PARTY_TYPE_ERROR,
    });
  }
};

export const deletePartyType = (partyTypeId) => async (dispatch) => {
  try {
    dispatch(setIsModalLoading(true));
    dispatch({
      type: DELETE_PARTY_TYPE,
    });
    const response = await axios.delete(`/api/partySettings/partyTypes/${partyTypeId}`);
    const {
      success,
      error,
    } = response?.data || {};

    dispatch({
      type: DELETE_PARTY_TYPE_SUCCESS,
      success,
    });

    if (error) {
      dispatch(addNotification(DELETE_PARTY_TYPE, error, NOTIFICATION_STATUS.ERROR));
      dispatch(setIsModalLoading(false));
      return;
    }

    if (!success) {
      dispatch(getPartyType(partyTypeId));
      dispatch(addNotification(DELETE_PARTY_TYPE_ERROR, `Can not delete party type ${partyTypeId}`, NOTIFICATION_STATUS.ERROR));
      return;
    }
    dispatch(addNotification(DELETE_PARTY_TYPE_SUCCESS, 'Party Type was deleted', NOTIFICATION_STATUS.SUCCESS));
  } catch (e) {
    const {
      deleteOrInactivate,
      modalTitle,
      modalBody,
      error,
      message,
    } = e?.response?.data || {};

    if (modalBody) {
      dispatch(setModalMessage(true, modalTitle, modalBody));
    } else if (deleteOrInactivate === 'Inactivate') {
      dispatch(setModalMessage(true, 'Inactivated Instead of Deleted', ['The type was inactivated instead of deleted, because at least one Closed Party is using this type.', 'If you want to delete this type, go to the parties screen and change the type of those party(s), then return here to actually delete it.']));
    }

    dispatch(addNotification(DELETE_PARTY_TYPE_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch(getPartyType(partyTypeId));
    dispatch({
      type: DELETE_PARTY_TYPE_ERROR,
    });
  }
};

export const clearPartyTypeWasDeleted = () => async (dispatch) => {
  dispatch({
    type: CLEAR_PARTY_TYPE_WAS_DELETED,
  });
};

export const updatePartyType = (partyTypeId, partyTypeChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch(setIsModalLoading(true));
    dispatch({ // orderActions doesn't have this.  Just one immediately above.
      type: UPDATE_PARTY_TYPE,
    });
    const response = await axios.put('/api/partySettings/partyTypes', {
      partyTypeChanges,
    });
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(UPDATE_PARTY_TYPE_ERROR, 'Can not update party type', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: UPDATE_PARTY_TYPE_ERROR,
      });
      return;
    }

    await dispatch(getPartyType(partyTypeId));
    dispatch(addNotification(UPDATE_PARTY_TYPE_SUCCESS, 'The party type has been successfully updated', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: UPDATE_PARTY_TYPE_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      modalTitle,
      modalBody,
      error,
      message,
    } = e?.response?.data || {};

    if (modalBody) {
      dispatch(setModalMessage(true, modalTitle, modalBody));
    } else if (message) {
      dispatch(setModalMessage(true, 'Type Name Already Exists', ['This type name already exists in active in inactive status.']));
    }

    const { message: errorMessage } = error || {};
    dispatch(addNotification(UPDATE_PARTY_TYPE_ERROR, errorMessage || 'Type didn\'t update', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: UPDATE_PARTY_TYPE_ERROR,
    });
    dispatch(setIsModalLoading(false));

    if (doWhenCatch) {
      doWhenCatch(e); // orderActions has no "e"
    }
  }
};

export const createPartyType = (partyTypeChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch(setIsModalLoading(true));
    dispatch({
      type: CREATE_PARTY_TYPE,
    });
    const response = await axios.post('/api/partySettings/partyTypes/create', {
      partyTypeChanges,
    });
    const {
      id,
    } = response?.data || {};

    if (!id) {
      dispatch(addNotification(CREATE_PARTY_TYPE_ERROR, 'Can not create party type', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: CREATE_PARTY_TYPE_ERROR,
      });
      return;
    }

    dispatch(addNotification(CREATE_PARTY_TYPE_SUCCESS, 'Your party type has been successfully created', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: CREATE_PARTY_TYPE_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry(id);
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};

    const { message: errorMessage } = error || {};

    if (message) {
      dispatch(setModalMessage(true, 'Type Name Already Exists', ['This type name already exists in active in inactive status.']));
    }

    dispatch(addNotification(CREATE_PARTY_TYPE_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: CREATE_PARTY_TYPE_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const updatePartyTypeGuestMessaging = (partyTypeId, partyTypeGuestMessagingChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_PARTY_TYPE_GUEST_MESSAGING,
    });
    const {
      updatePartyJoinActions,
    } = getState()?.partyTypeDetails || {};
    const response = await axios.put('/api/partySettings/partyTypeGuestMessaging', {
      partyTypeGuestMessagingChanges,
      updatePartyJoinActions,
    });
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(UPDATE_PARTY_TYPE_GUEST_MESSAGING_ERROR, 'Can not update party type', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: UPDATE_PARTY_TYPE_GUEST_MESSAGING_ERROR,
      });
      return;
    }

    await dispatch(getPartyType(partyTypeId));
    dispatch(addNotification(UPDATE_PARTY_TYPE_GUEST_MESSAGING_SUCCESS, 'Join Actions has been successfully updated', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: UPDATE_PARTY_TYPE_GUEST_MESSAGING_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      modalTitle,
      modalBody,
      error,
      message,
    } = e?.response?.data || {};

    if (modalBody) {
      dispatch(setModalMessage(true, modalTitle, modalBody));
    } else if (message) {
      dispatch(setModalMessage(true, 'Could not update the message, tags or campaigns.'));
    }

    const { message: errorMessage } = error || {};
    dispatch(addNotification(UPDATE_PARTY_TYPE_GUEST_MESSAGING_ERROR, errorMessage || 'Could not update the message,tags or campaigns.', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: UPDATE_PARTY_TYPE_GUEST_MESSAGING_ERROR,
    });
    dispatch(setIsModalLoading(false));

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setPartyTypeDetailsTab = (tabName) => async (dispatch) => {
  dispatch({
    type: SET_PARTY_TYPE_DETAILS_TAB,
    payload: tabName
  });
};

export const setGoToOrderDetails = (partyTypeId, wasReturned = false, query = false) => async (dispatch) => {
  dispatch({
    type: SET_GO_TO_PARTY_TYPE_DETAILS,
    payload: {
      partyTypeId,
      wasReturned,
      ...query ? { query } : {},
    },
  });
};

export const clearGoToOrderDetails = (all = false) => async (dispatch) => {
  dispatch({
    type: CLEAR_GO_TO_PARTY_TYPE_DETAILS,
    payload: all,
  });
};

export const setFormCreateUpdatePartyType = (payload) => ({
  type: SET_FORM_CREATE_UPDATE_PARTY_TYPE,
  payload
});

export const setFormPartyTypeGuestMessaging = (payload) => ({
  type: SET_FORM_UPDATE_PARTY_TYPE_GUEST_MESSAGING,
  payload
});

export const setValuesWelcomeMessage = (values) => ({
  type: SET_VALUES_WELCOME_MESSAGE,
  payload: values
});

export const getFollowUpMessages = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_FOLLOW_UP_MESSAGES,
    });
    const { data } = await axios.post('/api/followupmessages/template-tag') || {};
    const { error } = data || {};

    if (error) {
      dispatch({
        type: GET_FOLLOW_UP_MESSAGES_ERROR,
      });
      return;
    }

    dispatch({
      type: GET_FOLLOW_UP_MESSAGES_SUCCESS,
      payload: data?.messages,
    });
  } catch (e) {
    dispatch({
      type: GET_FOLLOW_UP_MESSAGES_ERROR,
    });
  }
};

export const clearFollowUpMessages = () => ({
  type: GET_FOLLOW_UP_MESSAGES_SUCCESS,
});

export const createDynamicFieldObj = async (calledFrom, selectedCustomers, selectedCustomersParties, selectedParties) => {
  // Check to see if all partyId elements are the same.
  const partyId = () => {
    switch (calledFrom) {
      case 'Party Guest List':
        return selectedCustomersParties.every((v, i, a) => i === 0 || v === a[i - 1]) ? selectedCustomersParties[0] : null;
      case 'Party List':
        return selectedParties.length === 1 ? selectedParties[0] : null;
      default:
        return null;
    }
  };

  switch (calledFrom) {
    case 'Contact List':
      return {
        calledBy: selectedCustomers.length === 1 ? 'fillFields' : 'Client',
        screenCalledFrom: calledFrom,
        distShort: false,
        contactId: selectedCustomers.length === 1 ? selectedCustomers[0] : null,
        // partyId: selectedCustomersParties.length === 1 ? selectedCustomersParties[0] : null,
      };

    case 'Party Guest List':
      return {
        calledBy: partyId() ? 'fillFields' : 'Client',
        screenCalledFrom: calledFrom,
        distShort: false,
        contactId: selectedCustomers.length === 1 ? selectedCustomers[0] : null,
        partyId: partyId(),
      };

    case 'Party List':
      return {
        calledBy: partyId() ? 'fillFields' : 'Client',
        screenCalledFrom: calledFrom,
        distShort: false,
        contactId: null,
        partyId: partyId(),
      };

    default:
      return {};
  }
};

export const replaceSystemGenMessage = (systemGenMessage, dynamicFieldsObj) => async (dispatch) => {
  try {
    dispatch({
      type: REPLACE_SYSTEM_GEN_MESSAGE,
    });
    const { data } = await axios.post('/api/contacts/replace-system-gen-message', {
      systemGenMessage,
      ...dynamicFieldsObj,
    }) || {};

    dispatch({
      type: REPLACE_SYSTEM_GEN_MESSAGE_SUCCESS,
      payload: data?.message,
    });
  } catch (e) {
    dispatch({
      type: REPLACE_SYSTEM_GEN_MESSAGE_ERROR,
    });
  }
};

export const setUpdatePartyJoinActions = (payload) => async (dispatch) => {
  dispatch({
    type: SET_UPDATE_PARTY_JOIN_ACTIONS,
    payload,
  });
};
