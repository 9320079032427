import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Button, IconButton, SvgIcon
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { withStyles } from 'tss-react/mui';
import { MESSAGES } from 'src/constants';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from 'src/components/Tooltip';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import {
  setCurrentMessage,
  setIsModalVisible,
  getUserMessageCode,
} from 'src/actions/messagesActions';
import ModalDialogVideo from 'src/components/ModalDialogVideo/index';

import buttonBoxStyle from 'src/views/Settings/MessageView/ButtonBox/buttonBox.style';

function ButtonBox({
  loading,
  classes,
  currentNavigationTab,
}) {
  const dispatch = useDispatch();

  const handleClickAddTemplate = () => {
    dispatch(setIsModalVisible());
    dispatch(getUserMessageCode());
    dispatch(setCurrentMessage({ addNewTemplate: true }));
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Grid
        className={classes.rootBox}
        container
        spacing={2}
      >
        {currentNavigationTab === MESSAGES.MESSAGE_TAB_USER && (
        <>
          <Grid>
            <Tooltip
              title="Message Templates"
              placement="left-start"
            >
              <IconButton
                className={classes.buttonVideo}
                color="secondary"
                onClick={handleOpen}
                size="large"
              >
                <SvgIcon fontSize="large">
                  <OndemandVideoIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid>
            <Button
              className={classes.buttonEdit}
              variant="contained"
              onClick={handleClickAddTemplate}
              disabled={loading}
            >
              <AddIcon />
              Add Template
            </Button>
          </Grid>
        </>
        )}
      </Grid>
      <ModalDialogVideo
        onClose={handleClose}
        isVisible={open}
        videosrc="https://player.vimeo.com/video/788080622"
        title="Message Templates"
        largeModal
      />
    </>
  );
}

ButtonBox.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  currentNavigationTab: PropTypes.string.isRequired,
};

export default withStyles(ButtonBox, buttonBoxStyle, { withTheme: true });
