import resultsTableStyle from 'src/components/View/Page/ResultsTable/resultsTable.style';

const resultsStyles = {
  ...resultsTableStyle,
  textMessages: {
    minWidth: 280,
    whiteSpace: 'pre-wrap'
  },
  msgNum: {
    minWidth: 100,
    whiteSpace: 'nowrap',
  },
  scenario: {
    minWidth: 200,
    width: 350,
    whiteSpace: 'pre-line',
  },
  image: {
    minWidth: 150,
    '& img': {
      width: 150,
      height: 150,
      objectFit: 'contain',
      border: '1px solid #CCCCCC',
      borderRadius: 5,
      padding: 10,
      boxSizing: 'border-box',
      backgroundColor: '#FFFFFF',
    }
  }
};

export default resultsStyles;
