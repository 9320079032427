import React from 'react';
import PropTypes from 'prop-types';
import helpers from 'src/helpers';

function TextFieldNullOption({
  value,
  values,
  ifNullAddEmpty,
  addNullAnyway,
  emptyText,
  isNullValueDisabled,
}) {
  if (!addNullAnyway && (helpers.transform.toArray(values).find((item) => item === value) || (!ifNullAddEmpty && value === null))) {
    return null;
  }

  const isNullAndAddNull = ifNullAddEmpty && value === null;

  return (
    <option
      value={isNullAndAddNull || addNullAnyway ? null : value}
      disabled={(isNullAndAddNull || addNullAnyway) && isNullValueDisabled}
    >
      {isNullAndAddNull || addNullAnyway ? emptyText : value}
    </option>
  );
}

TextFieldNullOption.propTypes = {
  value: PropTypes.string,
  values: PropTypes.array,
  ifNullAddEmpty: PropTypes.bool,
  emptyText: PropTypes.string,
  addNullAnyway: PropTypes.bool,
  isNullValueDisabled: PropTypes.bool,
};

TextFieldNullOption.defaultProps = {
  value: null,
  values: [],
  ifNullAddEmpty: false,
  addNullAnyway: false,
  emptyText: ' ',
  isNullValueDisabled: false,
};

export default TextFieldNullOption;
