const colorRed = '#FF0000';
const colorPurple = '#5850EC';

const helpCircleWithContainerStyle = {
  root: {
    display: 'flex',

    '&.inlineFlex': {
      display: 'inline-flex',
    },

    '& .circle': {
      lineHeight: '1em',
    },

    '& .circleRed': {
      lineHeight: '1em',
      color: colorRed,
    },

    '& .circlePurple': {
      lineHeight: '1em',
      color: colorPurple,
    },

    '&.right': {
      justifyContent: 'space-between',
      '& .text': {
        width: '100%',
      },
    },

    '&.beforeText': {
      '& .text': {
        order: 2,
      },

      '& .circle': {
        order: 1,
        marginTop: 1,
      },

      '& .helpCircleBox': {
        marginRight: 8,
      },
    },

    '&.afterText': {
      justifyContent: 'flex-left',
      '& .text': {
        width: 'auto',
      }
    },

    '&.center, &.centerTop4Plus': {
      alignItems: 'center',
      '& .circle': {
        marginTop: 2,
      },
      '&.centerTop4Plus .circle': {
        marginTop: 4,
      },
    },

    '&.top17': {
      alignItems: 'start',
      '& .circle': {
        marginTop: 17,
      },
    },
  },
};

export default helpCircleWithContainerStyle;
