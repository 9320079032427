import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TableBody } from '@mui/material';
import TBodyResultsMessage from 'src/views/Settings/MessageView/Results/TBodyResultsMessage';

function TBodyResults({
  handleRowClick,
  messages,
  classes,
  userId,
  azAccountName,
  databaseBeingUsed,
}) {
  const [showFullTextInMessages, setShowFullTextInMessages] = useState([]);
  const isShowMoreTextChecked = (id) => showFullTextInMessages.indexOf(id) !== -1;

  const handleClickShowMoreButton = (event, id) => {
    event.stopPropagation();
    setShowFullTextInMessages(
      !isShowMoreTextChecked(id)
        ? [...showFullTextInMessages, id]
        : showFullTextInMessages.filter((item) => item !== id)
    );
  };

  return (
    <TableBody>
      {messages.map((message) => (
        <TBodyResultsMessage
          key={message.id}
          userId={userId}
          message={message}
          classes={classes}
          handleRowClick={handleRowClick}
          handleClickShowMoreButton={handleClickShowMoreButton}
          isShowMoreTextChecked={isShowMoreTextChecked}
          azAccountName={azAccountName}
          databaseBeingUsed={databaseBeingUsed}
        />
      ))}
    </TableBody>
  );
}

TBodyResults.propTypes = {
  userId: PropTypes.number.isRequired,
  azAccountName: PropTypes.string.isRequired,
  databaseBeingUsed: PropTypes.string.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired
};
TBodyResults.defaultProps = {};

export default TBodyResults;
