import axios from 'src/utils/axios';
import { NOTIFICATION_STATUS } from 'src/constants';
import { addNotification } from 'src/actions/notificationActions';

export const SET_SMART_ACTION_GROUP_QUERY = '@smartactiongroups/set-smart-action-query';
export const CLEAR_SMART_ACTION_GROUPS_QUERY_FILTER = '@smartactiongroups/clear-smart-actions-query-filter';

export const SET_SMART_ACTION_GROUPS = '@smartactiongroups/set-smart-actions';
export const GET_SMART_ACTION_GROUPS = '@smartactiongroups/get-smart-actions';
export const GET_SMART_ACTION_GROUPS_SUCCESS = '@smartactiongroups/get-smart-actions-success';
export const GET_SMART_ACTION_GROUPS_ERROR = '@smartactiongroups/get-smart-actions-error';

export const SET_SMART_ACTION_GROUPS_QUERY = '@smartactiongroups/set-smart-actions-query';

export const SET_SELECTED_SMART_ACTION_GROUPS = '@smartactiongroups/set-selected-smart-actions';

export const SET_BACK_TO_SMART_ACTION_GROUP_LIST = '@smartactiongroups/set-back-to-smart-action-list';

export const SET_SMART_ACTIONS_PARTY_TYPE = '@smartactions/set-smart-actions-party-type';

export const getSmartActionGroups = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_SMART_ACTION_GROUPS,
    });
    const response = await axios.post(`/api/smartActions/partyType/${getState().partyTypeDetails.partyType.id}`, getState().smartActions.query);
    dispatch({
      type: GET_SMART_ACTION_GROUPS_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(GET_SMART_ACTION_GROUPS_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_SMART_ACTION_GROUPS_ERROR,
    });
  }
};

export const setSmartActionGroups = (smartActions) => ({
  type: SET_SMART_ACTION_GROUPS,
  payload: smartActions
});

export const setSmartActionGroupsQuery = (query) => ({
  type: SET_SMART_ACTION_GROUPS_QUERY,
  payload: query
});

export const clearSmartActionGroupsQueryFilter = () => ({
  type: CLEAR_SMART_ACTION_GROUPS_QUERY_FILTER,
});

export const setSelectedSmartActionGroups = (selectedSmartActions) => ({
  type: SET_SELECTED_SMART_ACTION_GROUPS,
  payload: selectedSmartActions
});

export const setBackToSmartActionGroupList = (payload) => ({
  type: SET_BACK_TO_SMART_ACTION_GROUP_LIST,
  payload,
});

export const clearBackToSmartActionGroupList = (clearAll = false) => ({
  type: SET_BACK_TO_SMART_ACTION_GROUP_LIST,
  payload: !clearAll ? {
    smartActions: [],
    query: {},
  } : false,
});

export const setSmartActionGroupsPartyType = (payload) => ({
  type: SET_SMART_ACTIONS_PARTY_TYPE,
  payload,
});
