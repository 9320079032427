import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs as BreadcrumbsDefault, Link, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { Link as RouterLink } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import helpers from 'src/helpers';

import breadcrumbsStyle from 'src/components/View/Header/breadcrumbs.style';

function Breadcrumbs({
  classes,
  breadcrumbs,
}) {
  return !helpers.isEmpty(breadcrumbs) && (
  <BreadcrumbsDefault
    className={classes.breadcrumbs}
    separator={(
      <NavigateNextIcon fontSize="small" />
    )}
    aria-label="breadcrumb"
  >
    {helpers.transform.toArray(breadcrumbs).map((item) => {
      const {
        link,
        title,
        isCurrent,
      } = item;
      return !isCurrent ? (
        <Link
          key={title || ''}
          variant="body1"
          color="inherit"
          to={link || ''}
          component={RouterLink}
          underline="hover"
        >
          {title || ''}
        </Link>
      ) : (
        <Typography
          key={title || ''}
          variant="body1"
          color="textPrimary"
        >
          {title || ''}
        </Typography>
      );
    })}
  </BreadcrumbsDefault>
  );
}

Breadcrumbs.propTypes = {
  classes: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array,
};
Breadcrumbs.defaultProps = {
  breadcrumbs: [],
};

export default withStyles(Breadcrumbs, breadcrumbsStyle);
