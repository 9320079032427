import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Card, Table } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import PerfectScrollbarTable from 'src/components/PerfectScrollbarTable';
import ModalDialog from 'src/components/ModalDialog/index';
import helpers from 'src/helpers';
import EditMessage from 'src/views/Settings/MessageView/EditMessage';
import EditModalFooter from 'src/views/Settings/MessageView/EditMessage/EditModalFooter';
import {
  setCurrentMessage,
  setIsModalVisible,
  deleteMessage,
  getUserMessageCode,
} from 'src/actions/messagesActions';
import THeadResults from 'src/views/Settings/MessageView/Results/THeadResults';
import TBodyResults from 'src/views/Settings/MessageView/Results/TBodyResults';

import resultsStyles from 'src/views/Settings/MessageView/Results/results.style';

function Results({
  classes,
  messages,
  currentNavigationTab,
  userMessageCodes,
}) {
  const dispatch = useDispatch();
  const { currentMessage, isModalVisible, isModalLoading } = useSelector((state) => state.messages);
  const { user, azAccountName, databaseBeingUsed } = useSelector((state) => state.account);
  const { id: userId } = user;
  const {
    addNewTemplate,
    code,
    id: messageId,
  } = currentMessage || {};

  const updateCurrentMessage = (message) => {
    dispatch(setCurrentMessage(message));
  };

  const handleRowClick = (event, message) => {
    if (!window.getSelection().toString()) {
      updateCurrentMessage(message);
      dispatch(getUserMessageCode());
      dispatch(setIsModalVisible());
    }
  };

  const handleCloseModal = () => {
    dispatch(setIsModalVisible());
    updateCurrentMessage({});
  };

  const handleDeleteTemplate = () => {
    dispatch(deleteMessage(messageId));
  };

  return (
    <div className={classes.root}>
      <Card>
        <PerfectScrollbarTable subDefaultHeight={200}>
          <Box minWidth={1150}>
            <Table
              size="small"
              className={classes.table}
            >
              <THeadResults
                classes={classes}
                currentNavigationTab={currentNavigationTab}
              />
              <TBodyResults
                messages={messages}
                classes={classes}
                handleRowClick={handleRowClick}
                userId={userId}
                azAccountName={azAccountName}
                databaseBeingUsed={databaseBeingUsed}
              />
            </Table>
          </Box>
        </PerfectScrollbarTable>
      </Card>
      <ModalDialog
        onClose={handleCloseModal}
        isVisible={isModalVisible}
        content={(
          <EditMessage
            message={currentMessage}
            userMessageCodes={userMessageCodes}
            loading={isModalLoading}
          />
        )}
        footer={(
          <EditModalFooter
            onClose={handleCloseModal}
            loading={isModalLoading}
            {...addNewTemplate ? { okBtnText: 'Create Template' } : {}}
            {...!addNewTemplate && helpers.isItUserMassageTemplate(code) ? { onDelete: handleDeleteTemplate } : {}}
          />
        )}
        title={addNewTemplate ? 'Add Template' : 'Make Changes & Update'}
        largeModal
      />
    </div>
  );
}

Results.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  currentNavigationTab: PropTypes.string.isRequired,
  userMessageCodes: PropTypes.array.isRequired,
};

export default withStyles(Results, resultsStyles);
