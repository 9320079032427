import axios from 'src/utils/axios';
import { addNotification } from 'src/actions/notificationActions';
import { getAccountInfo } from 'src/actions/accountActions';
import { NOTIFICATION_STATUS } from 'src/constants';

export const SET_LOADING = '@partySettings/set-loading';
export const SET_FORM_PARTY_SETTINGS_TAB_GENERAL = '@partySettings/set-formPartySettingsTabGeneral';
export const SET_CURRENT_NAVIGATION_TAB = '@partySettings/set-current-navigation-tab';
export const GET_PARTY_SETTINGS_BY_ID = '@partySettings/get-party-settings-by-id';
export const GET_PARTY_SETTINGS_BY_ID_ERROR = '@partySettings/get-party-settings-by-id-error';
export const UPDATE_PARTY_SETTINGS_GENERAL = '@partySettings/update-party-settings-general';
export const SET_FILL_DEFAULT_VALUES = '@partySettings/set-fill-default-values';

export const GET_TABLE_PARTY_TYPES = '@partySettings/get-table-party-types';
export const GET_TABLE_PARTY_TYPES_SUCCESS = '@partySettings/get-table-party-types-success';
export const GET_TABLE_PARTY_TYPES_ERROR = '@partySettings/get-table-party-types-error';

export const GET_NON_TABLE_PARTY_TYPES = '@partySettings/get-non-table-party-types';
export const GET_NON_TABLE_PARTY_TYPES_SUCCESS = '@partySettings/get-non-table-party-types-success';
export const GET_NON_TABLE_PARTY_TYPES_ERROR = '@partySettings/get-non-table-party-types-error';

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading
});

export const setFormPartySettingsTabGeneral = (formPartySettingsTabGeneral) => ({
  type: SET_FORM_PARTY_SETTINGS_TAB_GENERAL,
  payload: formPartySettingsTabGeneral
});

export const setCurrentNavigationTab = (tabName) => ({
  type: SET_CURRENT_NAVIGATION_TAB,
  payload: tabName
});

export const getPartySettingsById = (salespersonId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await axios.get(`/api/partySettings/${salespersonId}`);
    const error = response?.data?.error;
    if (error) {
      dispatch(addNotification(GET_PARTY_SETTINGS_BY_ID_ERROR, error, NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: GET_PARTY_SETTINGS_BY_ID_ERROR,
      });
      return;
    }
    dispatch({
      type: GET_PARTY_SETTINGS_BY_ID,
      payload: response?.data || {},
    });
  } catch (e) {
    const error = e?.response?.data?.error;
    dispatch(addNotification(GET_PARTY_SETTINGS_BY_ID_ERROR, error || 'Server error', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PARTY_SETTINGS_BY_ID_ERROR,
    });
  }
};

export const updatePartySettingsGeneral = (partySettingsChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await axios.put('/api/partySettings/general', {
      partySettingsChanges: {
        ...partySettingsChanges,
      },
    });
    const error = response?.data?.error;

    if (error) {
      dispatch(addNotification(UPDATE_PARTY_SETTINGS_GENERAL, error, NOTIFICATION_STATUS.ERROR));
      dispatch(getAccountInfo());
      return;
    }

    await dispatch(getAccountInfo());
    dispatch(addNotification(UPDATE_PARTY_SETTINGS_GENERAL, 'Your settings have been successfully updated', NOTIFICATION_STATUS.SUCCESS));

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const error = e?.response?.data?.error;
    dispatch(addNotification(UPDATE_PARTY_SETTINGS_GENERAL, error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch(getAccountInfo());

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const getTablePartyTypes = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_TABLE_PARTY_TYPES,
    });
    const { data } = await axios.post('/api/partySettings/partyTypes', { status: 'Active', table: true });
    const { error } = data || {};

    if (error) {
      dispatch({
        type: GET_TABLE_PARTY_TYPES_ERROR,
      });
      return;
    }

    dispatch({
      type: GET_TABLE_PARTY_TYPES_SUCCESS,
      payload: data?.partyTypes || {},
    });
  } catch (e) {
    dispatch({
      type: GET_TABLE_PARTY_TYPES_ERROR,
    });
  }
};

export const getNonTablePartyTypes = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_NON_TABLE_PARTY_TYPES,
    });
    const { data } = await axios.post('/api/partySettings/partyTypes', { status: 'Active', table: false });
    const { error } = data || {};

    if (error) {
      dispatch({
        type: GET_NON_TABLE_PARTY_TYPES_ERROR,
      });
      return;
    }

    dispatch({
      type: GET_NON_TABLE_PARTY_TYPES_SUCCESS,
      payload: data?.partyTypes || {},
    });
  } catch (e) {
    dispatch({
      type: GET_NON_TABLE_PARTY_TYPES_ERROR,
    });
  }
};

export const clearTablePartyTypes = () => ({
  type: GET_TABLE_PARTY_TYPES_SUCCESS,
});

export const clearNonTablePartyTypes = () => ({
  type: GET_NON_TABLE_PARTY_TYPES_SUCCESS,
});

export const setFillDefaultValues = (fillDefaultValues) => ({
  type: SET_FILL_DEFAULT_VALUES,
  payload: fillDefaultValues
});
