import axios from 'src/utils/axios';
import helpers from 'src/helpers';
import { NOTIFICATION_STATUS } from 'src/constants';
import { addNotification } from 'src/actions/notificationActions';

export const SET_PARTIES = '@parties/set-parties';
export const GET_PARTIES = '@orders/get-parties';
export const GET_PARTIES_SUCCESS = '@orders/get-parties-success';
export const GET_PARTIES_ERROR = '@orders/get-parties-error';

export const GET_FOLLOW_UP_MESSAGES = '@orders/get-follow-up-messages';
export const GET_FOLLOW_UP_MESSAGES_SUCCESS = '@orders/get-follow-up-messages-success';
export const GET_FOLLOW_UP_MESSAGES_ERROR = '@orders/get-follow-up-messages-error';

export const SET_PARTIES_QUERY = '@orders/set-parties-query';
export const CLEAR_PARTIES_QUERY_FILTER = '@orders/clear-parties-query-filter';

export const GET_PURCHASED_PARTY_OPTIONS = '@orders/get-purchased-party-options';
export const GET_PURCHASED_PARTY_OPTIONS_SUCCESS = '@orders/get-purchased-party-options-success';
export const GET_PURCHASED_PARTY_OPTIONS_ERROR = '@orders/get-purchased-party-options-error';

export const SET_SELECTED_PARTIES = '@orders/set-selected-parties';

export const SET_VISIBLE_MODAL_FORM_NUMBER = '@orders/set-visible-modal-form-number';

export const SET_VALUES_FORM_SEND_TEXT = '@orders/set-values-form-send-text';

export const SEND_TEXT_MESSAGE = '@orders/send-text-message';
export const SEND_TEXT_MESSAGE_SUCCESS = '@orders/send-text-message-success';
export const SEND_TEXT_MESSAGE_ERROR = '@orders/send-text-message-error';

export const SEND_ADD_TAG_CAMPAIGN = '@orders/send-add-tag-campaign';
export const SEND_ADD_TAG_CAMPAIGN_SUCCESS = '@orders/send-add-tag-campaign-success';
export const SEND_ADD_TAG_CAMPAIGN_ERROR = '@orders/send-add-tag-campaign-error';

export const SET_VALUES_FORM_ADD_PB_TAG = '@orders/set-values-form-add-pb-tag';

export const REPLACE_SYSTEM_GEN_MESSAGE = '@orders/replace-system-gen-message';
export const REPLACE_SYSTEM_GEN_MESSAGE_SUCCESS = '@orders/replace-system-gen-message-success';
export const REPLACE_SYSTEM_GEN_MESSAGE_ERROR = '@orders/replace-system-gen-message-error';

export const GET_PARTY_TYPES = '@parties/get-party-types';
export const GET_PARTY_TYPES_SUCCESS = '@parties/get-party-types-success';
export const GET_PARTY_TYPES_ERROR = '@parties/get-party-types-error';

export const SET_BACK_TO_PARTY_LIST = '@orders/set-back-to-party-list';

export const SEND_CLOSE_PARTIES = '@parties/send-close-parties';
export const SEND_CLOSE_PARTIES_SUCCESS = '@parties/send-close-parties-success';
export const SEND_CLOSE_PARTIES_ERROR = '@parties/send-close-parties-error';

export const getParties = () => async (dispatch, getState) => {
  dispatch({
    type: GET_PARTIES,
  });
  try {
    const response = await axios.post('/api/parties', getState().parties.query);
    dispatch({
      type: GET_PARTIES_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_PARTIES_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PARTIES_ERROR,
    });
  }
};

export const setParties = (parties) => ({
  type: SET_PARTIES,
  payload: parties
});

export const setPartiesQuery = (query) => ({
  type: SET_PARTIES_QUERY,
  payload: query
});

export const clearPartiesQueryFilter = () => ({
  type: CLEAR_PARTIES_QUERY_FILTER,
});

export const getPurchasedPartyOptions = (type) => async (dispatch) => {
  dispatch({
    type: GET_PURCHASED_PARTY_OPTIONS,
  });
  try {
    const response = await axios.post('/api/parties/party-filter-options', { type });
    dispatch({
      type: GET_PURCHASED_PARTY_OPTIONS_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_PURCHASED_PARTY_OPTIONS_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PURCHASED_PARTY_OPTIONS_ERROR,
    });
  }
};

export const setSelectedParties = (selectedParties) => ({
  type: SET_SELECTED_PARTIES,
  payload: selectedParties
});

export const setVisibleModalFormNumber = (modalNumber) => ({
  type: SET_VISIBLE_MODAL_FORM_NUMBER,
  payload: modalNumber
});

export const setValuesFormSendText = (values) => ({
  type: SET_VALUES_FORM_SEND_TEXT,
  payload: values
});

export const sendTextMessage = (sendMessage, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_TEXT_MESSAGE,
    });
    const response = await axios.put('/api/contacts/sendmessage', { sendMessage });
    const { success } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SEND_TEXT_MESSAGE_ERROR, 'Try again later', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SEND_TEXT_MESSAGE_ERROR,
      });
      return;
    }

    dispatch(addNotification(SEND_TEXT_MESSAGE_SUCCESS, 'Your text has been scheduled to send.', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: SEND_TEXT_MESSAGE_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SEND_TEXT_MESSAGE_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SEND_TEXT_MESSAGE_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setValuesFormAddPBTag = (values) => ({
  type: SET_VALUES_FORM_ADD_PB_TAG,
  payload: values
});

export const sendAddPBTag = (addTagCampaign, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_ADD_TAG_CAMPAIGN,
    });
    const response = await axios.put('/api/contacts/addtagcampaign', { addTagCampaign });
    const { success } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SEND_ADD_TAG_CAMPAIGN_ERROR, 'Try again later', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SEND_ADD_TAG_CAMPAIGN_ERROR,
      });
      return;
    }

    dispatch(addNotification(SEND_ADD_TAG_CAMPAIGN_SUCCESS, 'The tag and/or campaign has been added.', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: SEND_ADD_TAG_CAMPAIGN_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SEND_ADD_TAG_CAMPAIGN_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SEND_ADD_TAG_CAMPAIGN_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const getFollowUpMessages = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_FOLLOW_UP_MESSAGES,
    });
    const { data } = await axios.post('/api/followupmessages/template-tag') || {};
    const { error } = data || {};

    if (error) {
      dispatch({
        type: GET_FOLLOW_UP_MESSAGES_ERROR,
      });
      return;
    }

    dispatch({
      type: GET_FOLLOW_UP_MESSAGES_SUCCESS,
      payload: data?.messages,
    });
  } catch (e) {
    dispatch({
      type: GET_FOLLOW_UP_MESSAGES_ERROR,
    });
  }
};

export const clearFollowUpMessages = () => ({
  type: GET_FOLLOW_UP_MESSAGES_SUCCESS,
});

export const replaceSystemGenMessage = (systemGenMessage, hostFirstName, distShort, boughtFromPartyName, calledBy = 'retrieveMessageToSend', lastJoinedPartyName, partyURL, lastJoinedPartyHostFirstName, lastJoinedPartyHostLastName, lastOrderDateTime, emailAddress) => async (dispatch) => {
  try {
    dispatch({
      type: REPLACE_SYSTEM_GEN_MESSAGE,
    });
    const { data } = await axios.post('/api/contacts/replace-system-gen-message', {
      systemGenMessage,
      hostFirstName,
      distShort,
      boughtFromPartyName,
      calledBy,
      lastJoinedPartyName,
      partyURL,
      lastJoinedPartyHostFirstName,
      lastJoinedPartyHostLastName,
      lastOrderDateTime,
      emailAddress
    }) || {};

    dispatch({
      type: REPLACE_SYSTEM_GEN_MESSAGE_SUCCESS,
      payload: data?.message,
    });
  } catch (e) {
    dispatch({
      type: REPLACE_SYSTEM_GEN_MESSAGE_ERROR,
    });
  }
};

export const setBackToPartyList = (payload) => ({
  type: SET_BACK_TO_PARTY_LIST,
  payload,
});

export const clearBackToPartyList = (clearAll = false) => ({
  type: SET_BACK_TO_PARTY_LIST,
  payload: !clearAll ? {
    parties: [],
    query: {},
  } : false,
});

export const getPartyTypes = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_PARTY_TYPES,
    });
    const { data } = await axios.post('/api/partySettings/partyTypes', { status: 'Active' });
    const { error } = data || {};

    if (error) {
      dispatch({
        type: GET_PARTY_TYPES_ERROR,
      });
      return;
    }

    dispatch({
      type: GET_PARTY_TYPES_SUCCESS,
      payload: data?.partyTypes || {},
    });
  } catch (e) {
    dispatch({
      type: GET_PARTY_TYPES_ERROR,
    });
  }
};

export const clearPartyTypes = () => ({
  type: GET_PARTY_TYPES_SUCCESS,
});

export const closeParties = (selectedParties, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_CLOSE_PARTIES,
    });
    const response = await axios.put('/api/parties/closeParties', { selectedParties });
    const { success } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SEND_CLOSE_PARTIES_ERROR, 'Try again later', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SEND_CLOSE_PARTIES_ERROR,
      });
      return;
    }

    const closeSuccessText = selectedParties.length === 1 ? 'The party has been closed.' : 'The parties have been closed.';
    dispatch(addNotification(SEND_CLOSE_PARTIES_SUCCESS, closeSuccessText, NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: SEND_CLOSE_PARTIES_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SEND_CLOSE_PARTIES_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SEND_CLOSE_PARTIES_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};
