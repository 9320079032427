import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popover, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import popConfirmStyles from 'src/components/PopConfirm/popConfirm.style';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(popConfirmStyles);

function PopConfirm({
  children,
  onConfirm,
  title,
  okText,
  okProps,
  cancelText,
  cancelClass,
  thirdBtnText,
  onThirdBtn,
  thirdBtnProps,
  reverseFooterButtons,
  bigPaddingBottomToFooter,
}) {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickThirdBtn = () => {
    if (onThirdBtn) {
      onThirdBtn();
    }
    handleClose();
  };

  const handleOk = () => {
    onConfirm();
    handleClose();
  };

  const open = Boolean(anchorEl);

  const btnYesClass = classes.btnYes;
  const btnNoClass = `${classes.btnNo} ${cancelClass}`;

  const okButtonProps = {
    className: !reverseFooterButtons ? btnYesClass : btnNoClass,
    onClick: handleOk,
    color: 'primary',
    ...okProps
  };

  return (
    <>
      {React.cloneElement(children, { onClick: handleClick })}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <Typography className={classes.typography}>
          <ErrorOutlineIcon className={classes.errorOutlineIcon} />
          {title}
        </Typography>
        <div className={`${classes.footer} ${bigPaddingBottomToFooter ? 'bigPBFooter' : ''}`}>
          <Button
            className={!reverseFooterButtons ? btnNoClass : btnYesClass}
            onClick={handleClose}
            color="primary"
          >
            {cancelText}
          </Button>
          {onThirdBtn && thirdBtnText && (
            <Button
              className={classes.btnNo}
              onClick={handleClickThirdBtn}
              color="primary"
              {...thirdBtnProps}
            >
              {thirdBtnText}
            </Button>
          )}
          <Button {...okButtonProps}>{okText}</Button>
        </div>
      </Popover>
    </>
  );
}

PopConfirm.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  onThirdBtn: PropTypes.func,
  okText: PropTypes.string,
  okProps: PropTypes.object,
  thirdBtnProps: PropTypes.object,
  cancelText: PropTypes.string,
  cancelClass: PropTypes.oneOf([
    'default',
    'grey',
  ]),
  thirdBtnText: PropTypes.string,
  reverseFooterButtons: PropTypes.bool,
  bigPaddingBottomToFooter: PropTypes.bool,
};

PopConfirm.defaultProps = {
  children: null,
  title: 'Are you sure?',
  okText: 'Yes',
  okProps: {},
  cancelText: 'No',
  cancelClass: 'default',
  onThirdBtn: null,
  thirdBtnText: null,
  thirdBtnProps: {},
  reverseFooterButtons: false,
  bigPaddingBottomToFooter: false,
};

export default PopConfirm;
