import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import TypographyContainer from 'src/components/TypographyContainer';
import text from 'src/views/pages/HelpAndInformation/HelpAndInformationView/ResultNorwex/text';

function ResultNorwex({ classes }) {
  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        lg={6}
        xs={12}
      >
        <Card className={classes.card}>
          <TypographyContainer>
            {text.cardNumber1}
          </TypographyContainer>
        </Card>
        <Card className={classes.card}>
          <TypographyContainer>
            {text.cardNumber2}
          </TypographyContainer>
        </Card>
        <Card className={classes.card}>
          <TypographyContainer>
            {text.cardNumber3}
          </TypographyContainer>
        </Card>
      </Grid>
      <Grid
        lg={6}
        xs={12}
      >
        <Card className={classes.card}>
          <TypographyContainer>
            {text.cardNumber4}
          </TypographyContainer>
        </Card>
        <Card className={classes.card}>
          <TypographyContainer>
            {text.cardNumber5}
          </TypographyContainer>
        </Card>
        <Card className={classes.card}>
          <TypographyContainer>
            {text.cardNumber6}
          </TypographyContainer>
        </Card>
        <Card className={classes.card}>
          <TypographyContainer>
            {text.cardNumber7}
          </TypographyContainer>
        </Card>
      </Grid>
    </Grid>
  );
}

ResultNorwex.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default ResultNorwex;
