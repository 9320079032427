import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import Tooltip from 'src/components/Tooltip/index';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import helpCircleStyle from 'src/components/HelpCircle/helpCircle.style';

function HelpCircle({
  classes,
  showInfo,
  marginLeft,
  sizeType,
}) {
  return showInfo && (
  <div className={`${classes.root} helpCircleBox sizeType-${sizeType} marginLeft-${marginLeft}`}>
    <Tooltip
      title={showInfo}
    >
      <HelpOutlineIcon />
    </Tooltip>
  </div>
  );
}

HelpCircle.propTypes = {
  classes: PropTypes.object.isRequired,
  showInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.bool,
  ]),
  marginLeft: PropTypes.oneOf([
    'default',
    '19',
    '5',
    '0',
  ]),
  sizeType: PropTypes.oneOf([
    'default',
    '15',
  ]),
};

HelpCircle.defaultProps = {
  showInfo: false,
  marginLeft: 'default',
  sizeType: 'default',
};

export default withStyles(HelpCircle, helpCircleStyle);
