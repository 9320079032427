import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import helpers from 'src/helpers';

import googleDocStyle from 'src/components/GoogleDoc/googleDoc.style';

function GoogleDoc({
  classes,
  title,
  width,
  height,
  link,
}) {
  if (helpers.isEmpty(link)) {
    return null;
  }

  return (
    <iframe
      className={classes.iframe}
      title={title}
      src={link}
      width={width}
      height={height}
    />
  );
}

GoogleDoc.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  link: PropTypes.string,
};

GoogleDoc.defaultProps = {
  link: '',
  title: 'Google Doc File',
  width: '100%',
  height: '500',
};

export default withStyles(GoogleDoc, googleDocStyle);
