import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';
import AutocompleteFieldFormik from 'src/components/AutocompleteFieldFormik';
import helpers from 'src/helpers';

function CardChangeCompanyTitle({
  classes,
  formik,
  loading,
  companyTitles,
  handleFocusFieldValidate,
}) {
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    setFieldValue
  } = formik;

  const renderOption = (props, option) => (
    <div {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
      <b>{option.label}</b>
      <div className={classes.optionValues}>
        {option.alternativeName && (
          <div>
            {option.alternativeName}
          </div>
        )}
        {option.description && (
          <div>
            {option.description}
          </div>
        )}
      </div>
    </div>
  );

  const options = helpers.transform.toArray(companyTitles).map(({
    name,
    alternativeName,
    description,
    id,
  }) => ({
    value: name,
    label: name,
    alternativeName,
    description,
    id,
  }));

  return (
    <div className={classes.cardBox}>
      <Grid container>
        <Grid xs={12}>
          <AutocompleteFieldFormik
            setFieldValue={setFieldValue}
            touchedValue={touched.companyTitle}
            errorValue={errors.companyTitle}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleFocus={handleFocusFieldValidate}
            values={values.companyTitle}
            name="companyTitle"
            label="Company Title"
            disabled={loading}
            options={options}
            renderOption={renderOption}
            noOptionsText={null}
            multiple={false}
            disableCloseOnSelect={false}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            disableClearable
            freeSolo
            required
          />
        </Grid>
      </Grid>
    </div>
  );
}

CardChangeCompanyTitle.propTypes = {
  handleFocusFieldValidate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  companyTitles: PropTypes.array.isRequired,
};

export default CardChangeCompanyTitle;
