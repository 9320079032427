import { STYLE_OPTIONS } from 'src/constants';

const messageBoxStyle = (theme) => ({
  root: {
    display: 'inline-block',
    fontFamily: STYLE_OPTIONS.FONT_FAMILY,
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 500,

    '&.fullWidth': {
      display: 'block',
      width: '100%',
      textAlign: 'center',
    },

    '&.pt-10': {
      paddingTop: 10,
    },

    '&.pt-15': {
      paddingTop: 15,
    },

    '&.pt-20': {
      paddingTop: 20,
    },

    '&.pb-10': {
      paddingBottom: 10,
    },

    '&.pb-15': {
      paddingBottom: 15,
    },

    '&.pb-20': {
      paddingBottom: 20,
    },

    '&.type-1': {
      color: '#3B498E',

      '& .inner': {
        backgroundColor: '#FFFFFF',
        borderRadius: 3,
      },
    },

    '&.type-2': {
      color: '#595FD9',

      '& .inner': {
        backgroundColor: '#E8EAF6',
        borderRadius: 3,
        border: '1px solid #595FD9'
      },
    },

    '&.type-3, &.type-3-red, &.type-3-light': {
      color: '#ffffff',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '1.5em',
      '& .inner': {
        width: '100%',
        backgroundColor: '#F79E1B',
        display: 'block',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },

    '&.type-3-red': {
      '& .inner': {
        backgroundColor: `${STYLE_OPTIONS.COLOR_RED} !important`,
      },
    },

    '&.type-3-light': {
      color: '#883100',
      '& .inner': {
        backgroundColor: '#FED4B7 !important',
      },
    },

    '& .inner': {
      display: 'inline-block',
      width: 'auto',
      padding: '6px 8px',
      '& .a-inherit': {
        color: 'inherit',
        '&:hover': {
          color: '#595FD9',
        },
      },
    },

    '& .nbsp': {
      display: 'inline-block',
      width: '0.4em',
    },

    '& .xs-full': {
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        width: '100%',
        height: 0,
      },
    },
  }
});

export default messageBoxStyle;
