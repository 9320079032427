const navBarStyle = (theme) => {
  const transition = theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: '0.1s',
  });

  const showBox = {
    width: '100%',
    transition,
  };

  const hideBox = {
    width: 0,
    maxHeight: 0,
    overflow: 'hidden',
    display: 'none',
  };

  return {
    mobileDrawer: {
      width: 256
    },
    desktopDrawer: {
      width: 256,
      top: 64,
      height: 'calc(100% - 64px)',
      transition,
      '& .navBoxUserName': {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        boxSizing: 'border-box',
        alignItems: 'center',
        marginTop: 0,
      },
      '& .navBoxUserNameIn': showBox,
      '& .navSubHead': showBox,
      '& .navItemText': showBox,
    },
    desktopSmallDrawer: {
      width: 56,
      overflowX: 'hidden',
      '& .navBox': {
        paddingLeft: 5,
        paddingRight: 5,
      },
      '& .navBoxUserNameIn': hideBox,
      '& .navSubHead': hideBox,
      '& .firstLineLink .navSubHead': {
        ...showBox,
        maxHeight: 'none',
        overflow: 'visible',
        display: 'flex',
        '& .firstLine .MuiButton-root': {
          width: '100%',
          marginLeft: 0,
        },
      },
      '& .navItemIcon': {
        marginRight: 0,
      },
      '& .navItemText': hideBox,
      '& .MuiButton-root': {
        minWidth: 0,
        justifyContent: 'center',
      },
    },
    avatar: {
      cursor: 'pointer',
      width: 64,
      height: 64
    },
    navSubHeadFirstItem: {
      width: '100%',
    },
    title: {
      fontSize: '14px',
    },
    itemLeaf: {
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 8,

      '&.firstLine .MuiButton-root': {
        marginLeft: -8,
        width: 'calc(100% + 8px)',
      }
    },
    buttonLeaf: {
      color: theme.palette.text.secondary,
      padding: '10px 8px',
      justifyContent: 'flex-start',
      textTransform: 'none',
      letterSpacing: 0,
      width: '100%',
      fontWeight: theme.typography.fontWeightRegular,
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1),
      width: '20px',
      height: 'auto',
      marginTop: -2,
    },
  };
};

export default navBarStyle;
