const spinnerStyle = {
  root: {
    '&.absolute': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 99999999,
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    '&.z1300': {
      zIndex: 1300,
    },
    '& .circularProgress': {
      color: '#6200EE',
    },
  },

};

export default spinnerStyle;
