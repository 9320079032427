import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { STORAGE } from 'src/constants';
import Grid from '@mui/material/Unstable_Grid2';
import { withStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import { setFileUploadToMessage } from 'src/actions/messagesActions';
import helpers from 'src/helpers';
import UploadImage from 'src/components/UploadImage/UploadImage';

import uploadImageFieldStyle from 'src/views/Settings/MessageView/EditMessage/uploadImageField.style';

function UploadImageField({
  title,
  userId,
  classes,
  fileName,
  fileUpload,
  defaultScreenSpacing,
  loading,
  errorValue,
  validateMessage,
  azAccountName,
  databaseBeingUsed,
  container,
  setFieldValue,
}) {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(false);

  const validateFileInput = (uploadFileData) => {
    if (!helpers.azure.validateFileSize(uploadFileData)) {
      setErrorMessage(validateMessage.validateFileSize);
      return false;
    }
    if (!helpers.azure.validateFileType(uploadFileData)) {
      setErrorMessage(validateMessage.validateFileType);
      return false;
    }
    setErrorMessage(false);
    return true;
  };

  // 2022-12-07 - For now, whether the user deletes the image OR changes it, we will set container to null, as the container has to default to their container, until we implement allowing them to select an image from a CP library.
  const updateFileUploadToMessage = (value) => {
    dispatch(setFileUploadToMessage(value));
    setFieldValue('attachmentContainer', null, false);
  };

  return (
    <>
      <Grid className="formGridGrope" container spacing={defaultScreenSpacing}>
        <Grid xs={12}>
          <div className={classes.formGroupLabel}>
            {title}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        <Grid xs={12}>
          <UploadImage
            title={title}
            fileName={helpers.azure.getImageFileURL(fileName, userId, azAccountName, STORAGE.sas, databaseBeingUsed, container)}
            fileUpload={fileUpload}
            loading={loading}
            errorValue={errorValue}
            validateFileInput={validateFileInput}
            setFileUploadToMessage={updateFileUploadToMessage}
            errorMessage={errorMessage}
          />
        </Grid>
      </Grid>
    </>
  );
}

UploadImageField.propTypes = {
  userId: PropTypes.number.isRequired,
  azAccountName: PropTypes.string.isRequired,
  databaseBeingUsed: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  defaultScreenSpacing: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  validateMessage: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  container: PropTypes.string,
  fileName: PropTypes.string,
  fileUpload: PropTypes.any,
  errorValue: PropTypes.any,
};

UploadImageField.defaultProps = {
  container: null,
  fileName: null,
  fileUpload: false,
  errorValue: false,
};

export default withStyles(UploadImageField, uploadImageFieldStyle, { withTheme: true });
