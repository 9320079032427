import { STYLE_OPTIONS } from 'src/constants';

const colorPurple = '#5850EC';
const fontFamily = STYLE_OPTIONS.FONT_FAMILY;

const adminSignInAsStyle = {
  form: {},
  cardBox: {
    paddingTop: 10,
  },
  optionValues: {
    color: colorPurple,
    fontFamily,
    fontSize: '14px',
  },

};

export default adminSignInAsStyle;
