import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import hTitleTagsStyle from 'src/components/HTitleTags/hTitleTags.style';

function H4Title({
  classes,
  children,
}) {
  return (
    <h4 className={classes.h4Title}>
      <div className="left">
        {children}
      </div>
      <div className="right" />
    </h4>
  );
}

H4Title.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

export default withStyles(H4Title, hTitleTagsStyle);
