/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import ModalDialog from 'src/components/ModalDialog/index';
import ChangeCompanyTitleForm from 'src/containers/ChangeCompanyTitle/ChangeCompanyTitleForm';
import changeCompanyTitleStyle from 'src/containers/ChangeCompanyTitle/changeCompanyTitle.style';

function ChangeCompanyTitle({
  classes,
  logout,
}) {
  const {
    updateUsersTitle,
    companyTitles,
    updateCompanyTitleLoading: loading,
  } = useSelector((state) => state.account) || {};

  const okButtonProps = {
    form: 'formChangeCompanyTitle',
    type: 'primary',
  };

  const handleCloseModal = () => {
    logout();
  };

  return updateUsersTitle && (
    <ModalDialog
      title="Please Update Your Company Title"
      onClose={handleCloseModal}
      onOk={() => { }}
      okButtonProps={okButtonProps}
      content={(
        <ChangeCompanyTitleForm
          classes={classes}
          companyTitles={companyTitles}
          loading={loading}
        />
      )}
      closeText="Log Out"
      okText="Update"
      loading={loading}
      isCloseIconHid
      isVisible
      closeOnClickOnlyTheButton
      largeModal
    />
  );
}

ChangeCompanyTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

ChangeCompanyTitle.defaultProps = {};

export default withStyles(ChangeCompanyTitle, changeCompanyTitleStyle);
