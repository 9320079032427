import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as DefaultTooltip } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import tooltipStyles from 'src/components/Tooltip/tooltip.style';

function Tooltip({ children, ...props }) {
  return (
    <DefaultTooltip
      enterDelay={50}
      enterTouchDelay={50}
      leaveTouchDelay={2500}
      {...props}
    >
      {children}
    </DefaultTooltip>
  );
}

Tooltip.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  children: PropTypes.any,
};

Tooltip.defaultProps = {
  title: '',
  children: null,
};

export default withStyles(Tooltip, tooltipStyles);
