import { STYLE_OPTIONS } from 'src/constants';

const fontFamily = 'Roboto';
const color = '#6200EE';
const colorSecondary = '#BFBDC1';
const colorThird = '#974FFF';
const colorDisabled = '#CCCCCC';

export const copyButtonStyles = () => ({
  root: {
    textTransform: 'none',
    fontFamily,
    '& .center': {
      margin: '0px auto'
    },
    '&.applyBtn': {
      width: '100%',
      maxWidth: '100%',
      height: 40,
      paddingLeft: 5,
      paddingRight: 5,
    },
    '&.copyBtn': {
      height: 40,
    },
  },
  textPrimary: {
    fontWeight: 400,
    color: '#ffffff',
    background: color,
    '&:hover': {
      color,
      boxShadow: `0px 0px 0px 1px ${color}`
    },
    '&.Mui-disabled': {
      background: colorDisabled,
    }
  },
  textSizeSmall: {
    fontSize: '14px',
    lineHeight: '16px',
    padding: '7px 17px'
  }
});

export const modalButtonStyles = () => ({
  root: {
    textTransform: 'none',
    fontFamily,
    fontSize: '16px',
    lineHeight: '16px',
    padding: '18px 24px',
    fontWeight: 400
  },
  textPrimary: {
    color: '#ffffff',
    background: color,
    boxShadow: `0px 0px 0px 1px ${color}`,
    '&:hover': {
      color,
      boxShadow: `0px 0px 0px 1px ${color}`
    },
    '&.Mui-disabled, &.Mui-disabled.primary2': {
      background: colorDisabled,
      boxShadow: `0px 0px 0px 1px ${colorDisabled}`,
      color: '#777777',
    },
    '&.primary2': {
      color,
      boxShadow: `0px 0px 0px 1px ${color}`,
      background: '#ffffff',
    },
    '&.primary2:hover': {
      background: colorThird,
      boxShadow: `0px 0px 0px 1px ${colorThird}`,
      color: '#ffffff',
    }
  },
  textSecondary: {
    color: '#ffffff',
    background: colorSecondary,
    boxShadow: `0px 0px 0px 1px ${colorSecondary}`,
    '&:hover': {
      color: colorSecondary,
      boxShadow: `0px 0px 0px 1px ${colorSecondary}`
    },
    '&.Mui-disabled': {
      background: '#CCCCCC !important',
      color: '#ffffff !important',
      boxShadow: `0px 0px 0px 1px ${colorSecondary} !important`,
    },
    '&.red': {
      backgroundColor: STYLE_OPTIONS.COLOR_RED,
      boxShadow: `0px 0px 0px 1px ${STYLE_OPTIONS.COLOR_RED}`,
      '&:hover': {
        color: STYLE_OPTIONS.COLOR_RED,
        background: '#ffffff',
      },
    },
  }
});
