import React, { forwardRef, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import track from 'src/utils/analytics';

const Page = forwardRef(({
  title,
  description,
  ogTitle,
  ogDescription,
  ogImage,
  ogURL,
  children,
  ...rest
}, ref) => {
  const location = useLocation();

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: location.pathname
    });
  }, [location]);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <div ref={ref} {...rest}>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title || 'Connection Point'} />
        <meta name="description" content={description || 'Connecting Direct Sales Sellers, Customers, & Teams!'} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={ogTitle || 'Connection Point'} />
        <meta property="og:description" content={ogDescription || 'Connecting Direct Sales Sellers, Customers, & Teams!'} />
        <meta property="og:image" content={ogImage || '/static/images/ConnectionPointLogo1200.jpg'} />
        {ogURL && (<meta property="og:url" content={ogURL} />)}
      </Helmet>
      {children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  ogImage: PropTypes.string,
  ogURL: PropTypes.string,
};

Page.defaultProps = {
  description: null,
  ogTitle: null,
  ogDescription: null,
  ogImage: null,
  ogURL: null,
};

export default Page;
