import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow } from '@mui/material';
import { MESSAGES } from 'src/constants';

function THeadResults({
  classes,
  currentNavigationTab,
}) {
  const msgTitle = () => {
    switch (currentNavigationTab) {
      case MESSAGES.MESSAGE_TAB_SYSTEM:
        // return 'Msg #';
        return 'Template #';

      case MESSAGES.MESSAGE_TAB_USER:
        return 'Template #';

      default:
        return '';
    }
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell
          align="left"
          className={classes.msgNum}
        >
          {msgTitle()}
        </TableCell>
        <TableCell
          align="left"
          className={classes.scenario}
        >
          Scenario
        </TableCell>
        <TableCell className={classes.textMessages}>
          Text Message To Send
        </TableCell>
        <TableCell
          align="left"
          className={classes.image}
        >
          Image To Send
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

THeadResults.propTypes = {
  classes: PropTypes.object.isRequired,
  currentNavigationTab: PropTypes.string.isRequired,
};

export default THeadResults;
