import modalDialogFormStyle from 'src/components/View/ModalDialogForm/modalDialogForm.style';

const colorText = '#393939';
const fontFamily = 'Roboto';

const editMessageStyle = (theme) => ({
  ...modalDialogFormStyle(theme),
  updateMessageForm: {
    padding: '25px 72px',
    width: '100%',
    overflow: 'hidden',

    '& .disabledBox .MuiInputBase-root.Mui-disabled': {
      backgroundColor: '#F4F4F4',
    },

    '& .disabledBox .MuiInputBase-input.Mui-disabled': {
      color: '#393939',
    },

    '& .disabledBox .MuiInputBase-root.Mui-disabled fieldset': {
      border: 0,
    },

    [theme.breakpoints.down('lg')]: {
      padding: '15px 15px',
      '& label.MuiFormControlLabel-root': {
        alignItems: 'flex-start',
        '& .MuiCheckbox-root': {
          marginTop: -9
        }
      }
    },

    [theme.breakpoints.down('sm')]: {
      padding: '12px 0px'
    },

    '& .center': {
      textAlign: 'center'
    },

    '& .formGridGrope': {
      paddingTop: '22px'
    },

    '& .formGridGrope.padding31': {
      paddingTop: '31px'
    },

    '& .formGridGrope.padding38': {
      paddingTop: '38px'
    },

    '& .formGridGropePhone': {
      paddingTop: '7px'
    },

    '& .MuiFormLabel-root': {
      color: '#858585'
    },

    '& div.MuiFormLabel-root': {
      color: colorText,
      fontSize: '16px',
      fontWeight: 400,
      fontFamily
    },

    '& .MuiFormLabel-root.Mui-error': {
      color: '#f44336'
    },

    '& .MuiInputLabel-outlined': {
      fontSize: '13px',
      paddingTop: 2
    },

    '& .MuiOutlinedInput-root legend': {
      fontSize: '10px'
    }
  },
  formGroupLabel: {
    color: colorText,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '24px',
    fontFamily
  },
});

export default editMessageStyle;
