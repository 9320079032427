import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import ResultNorwex from 'src/views/pages/HelpAndInformation/HelpAndInformationView/ResultNorwex';
import ResultPamperedChef from 'src/views/pages/HelpAndInformation/HelpAndInformationView/ResultPamperedChef';
import ResultCrunchi from 'src/views/pages/HelpAndInformation/HelpAndInformationView/ResultCrunchi';
import ResultParkLane from 'src/views/pages/HelpAndInformation/HelpAndInformationView/ResultParkLane';
import ResultThirtyOne from 'src/views/pages/HelpAndInformation/HelpAndInformationView/ResultThirtyOne';
import ResultSipology from 'src/views/pages/HelpAndInformation/HelpAndInformationView/ResultSipology';
import ResultColorStreet from 'src/views/pages/HelpAndInformation/HelpAndInformationView/ResultColorStreet';

import helpAndInformationViewStyles from 'src/views/pages/HelpAndInformation/HelpAndInformationView/helpAndInformationView.style';

function HelpAndInformationView({
  primaryCompany,
  classes,
}) {
  return (
    <div className={classes.root}>
      {primaryCompany === 'Norwex' && (
      <ResultNorwex classes={classes} />
      )}
      {primaryCompany === 'Pampered Chef' && (
      <ResultPamperedChef classes={classes} />
      )}
      {primaryCompany === 'Crunchi' && (
      <ResultCrunchi classes={classes} />
      )}
      {primaryCompany === 'Park Lane' && (
      <ResultParkLane classes={classes} />
      )}
      {primaryCompany === 'Thirty-One' && (
      <ResultThirtyOne classes={classes} />
      )}
      {primaryCompany === 'Sipology' && (
      <ResultSipology classes={classes} />
      )}
      {primaryCompany === 'Color Street' && (
      <ResultColorStreet classes={classes} />
      )}
    </div>
  );
}

HelpAndInformationView.propTypes = {
  classes: PropTypes.object.isRequired,
  primaryCompany: PropTypes.string,
};

HelpAndInformationView.defaultProps = {
  primaryCompany: null,
};

export default withStyles(HelpAndInformationView, helpAndInformationViewStyles);
