import moment from 'moment';
import * as Yup from 'yup';
import { isEmpty, isPhone } from 'src/helpers/check';
import { phoneToString, toArray, stringToNull } from 'src/helpers/transform';
// import { yupToFormErrors } from 'formik';

export const getValidatePhone = (value) => isEmpty(value) || isPhone(phoneToString(value));

export const postUSARegExp = /^(0050[1-9])|(005[1-9][0-9])|(00[6-9][0-9][0-9])|(0[1-9][0-9][0-9][0-9])|(999[0-4][0-9])|(99950)|(99[0-8][0-9][0-9])|(9[0-8][0-9][0-9][0-9])|([1-8][0-9][0-9][0-9][0-9])$/;

export const postCanadaRegExp = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z][ -]?[0-9][A-Z][0-9]$/;

export const onlyLettersNumbersSpacesDashesUnderscoresRegExp = /^[0-9a-z _-]*$/i;

export const postValidation = (country, valueName) => (Yup.string()
  .when(country, {
    is: 'United States',
    then: Yup.string()
      .matches(postUSARegExp, `${valueName} is not valid`)
      .min(5, `${valueName} is not valid`)
      .max(5, `${valueName} is not valid`),
  })
  .when(country, {
    is: 'Canada',
    then: Yup.string()
      .matches(postCanadaRegExp, `${valueName} is not valid`)
      .min(3, `${valueName} is not valid`)
      .max(7, `${valueName} is not valid`),
  })
);

export const dateType1Validation = () => (Yup.date('Invalid date')
  .nullable(true)
  .typeError('Invalid date')
  .min(new Date(1900, 1, 1), 'Invalid date')
  .max(new Date(3000, 12, 31), 'Invalid date')
);

export const campaignTextFieldValidation = (valueName) => (Yup.string()
  .matches(onlyLettersNumbersSpacesDashesUnderscoresRegExp, `${valueName} may only contain letters, numbers, spaces, dashes, and underscores`)
  .min(1, 'At least one character')
  .max(200, `${valueName} must be at most 200 characters`)
);

export const mayOnlyLettersNumbersSpacesDashesUnderscores = (values) => (
  isEmpty(values) || !toArray(values).find((value) => !(value?.value || value).match(onlyLettersNumbersSpacesDashesUnderscoresRegExp))
);

export const campaignArrayFieldWithTextValidation = (valueName) => (Yup.array()
  .test('additionalTagsArrValidation', `${valueName} may only contain letters, numbers, spaces, dashes, and underscores`, mayOnlyLettersNumbersSpacesDashesUnderscores)
);

export const validateField = (event, setFieldTouched) => {
  const { target, dataName } = event || {};
  const { name } = target || {};
  if (!isEmpty(name) || !isEmpty(dataName)) {
    setFieldTouched(stringToNull(name) || stringToNull(dataName), true);
  }
};

export const cutMaxStrLen = (str, length) => {
  const newStr = str.slice(0, length);
  // The length of emoji is one or two chars. There is last char 55357 in string if we cut emoji.
  return newStr.charCodeAt(newStr.length - 1) === 55357 ? cutMaxStrLen(newStr, length - 1) : newStr;
};

export const validateDateFromMoment = (year, month, day) => {
  if (year && month && day) {
    return moment([parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10)]).invalidAt();
  }
  return -1;
};

export const validateCurrencyAmount = () => (
  Yup.string().matches(/^\d{0,6}(\.[0-9]{2})?$/, 'Not a valid currency amount')
);
