const vimeoVideoStyle = {
  iframe: {
    maxWidth: '100%',
    margin: '10px 0px',
    display: 'block',

    '&.center': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    '&.left': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
};

export default vimeoVideoStyle;
