import { STYLE_OPTIONS } from 'src/constants';

import selectDynamicFieldDropDownStyle from './selectDynamicFieldDropDown.style';

const color = '#393939';
const color2 = '#5850EC';
const fontFamily = STYLE_OPTIONS.FONT_FAMILY;

const selectDynamicFieldStyle = (theme) => ({
  root: {
    ...(selectDynamicFieldDropDownStyle(theme)?.innerField || {}),

    '& .MuiInputBase-root, & .MuiFormLabel-root': {
      fontSize: '14px',
    },

    cursor: 'pointer',

  },

  groupLabel: {
    color,
    fontFamily,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px',
    padding: '0px 0px 10px 0px'
  },

  optionValue: {
    color: color2,
    fontFamily,
    fontSize: '12px',
  },

  innerFieldDropDown: {
    ...(selectDynamicFieldDropDownStyle(theme)?.innerFieldDropDown || {}),
    cursor: 'default',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      display: 'block',
      top: 35,
      left: -216,
      width: 250,
      height: 'auto',
      padding: '20px 21px',
    },
  },

});

export default selectDynamicFieldStyle;
