const modalDialogStyles = (theme, _params, classes) => ({
  root: {
  },
  title: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    '&.top': {
      alignItems: 'flex-start',
    }
  },
  largeDialogPaper: {
    maxWidth: 2500,
    width: '90%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      [`& .${classes.titleInner}`]: {
        marginTop: 0,
      }
    }
  },

  dialogContent: {
    textAlign: 'center',
  },
});

export default modalDialogStyles;
