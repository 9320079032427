import { STYLE_OPTIONS } from 'src/constants';

const hTitleTagsStyle = {
  h4Title: {
    color: STYLE_OPTIONS.COLOR_BLUE,
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    overflow: 'hidden',

    '& .left': {
      width: 'auto',
      maxWidth: '95%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      float: 'left',
      marginRight: 5,
    },

    '& .right': {
      marginTop: 9,
      width: 'auto',
      height: 1,
      overflow: 'hidden',
      backgroundColor: '#DFDFDF',
    }
  },
};

export default hTitleTagsStyle;
