import axios from 'src/utils/axios';
import { addNotification } from 'src/actions/notificationActions';
import { getAccountInfo } from 'src/actions/accountActions';
import { NOTIFICATION_STATUS } from 'src/constants';

export const SET_CURRENT_MESSAGE = '@message/set-current-message';
export const SET_FILE_UPLOAD_TO_MESSAGE = '@message/set-file-upload-to-message';

export const SET_LOADING = '@orderSettings/set-loading';
export const SET_FORM_ORDER_SETTINGS_TAB_GENERAL = '@orderSettings/set-formOrderSettingsTabGeneral';
export const SET_CURRENT_NAVIGATION_TAB = '@orderSettings/set-current-navigation-tab';
export const GET_ORDER_SETTINGS_BY_ID = '@orderSettings/get-order-settings-by-id';
export const GET_ORDER_SETTINGS_BY_ID_ERROR = '@orderSettings/get-order-settings-by-id-error';
export const UPDATE_ORDER_SETTINGS_GENERAL = '@orderSettings/update-order-settings-general';
export const SET_FILL_DEFAULT_VALUES = '@orderSettings/set-fill-default-values';

export const SET_FOLLOW_UP_QUERY = '@orders/set-follow-up-query';
export const CLEAR_FOLLOW_UPS_QUERY_FILTER = '@orders/clear-follow-ups-query-filter';

export const SET_FOLLOW_UPS = '@followUps/set-followUps';
export const GET_FOLLOW_UPS = '@orders/get-followUps';
export const GET_FOLLOW_UPS_SUCCESS = '@orders/get-followUps-success';
export const GET_FOLLOW_UPS_ERROR = '@orders/get-followUps-error';

export const UPDATE_FOLLOW_UP = '@orders/update-follow-up';

export const SET_FOLLOW_UPS_QUERY = '@orders/set-followUps-query';

export const SET_SELECTED_FOLLOW_UPS = '@orders/set-selected-followUps';

export const SET_BACK_TO_FOLLOW_UP_LIST = '@orders/set-back-to-follow-up-list';

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading
});

export const setFormOrderSettingsTabGeneral = (formOrderSettingsTabGeneral) => ({
  type: SET_FORM_ORDER_SETTINGS_TAB_GENERAL,
  payload: formOrderSettingsTabGeneral
});

export const setCurrentNavigationTab = (tabName) => ({
  type: SET_CURRENT_NAVIGATION_TAB,
  payload: tabName
});

export const updateOrderSettingsTabGeneral = (orderSettingsChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await axios.put('/api/accountsettings/orderfollowupgeneraltab', { orderSettingsChanges });
    const error = response?.data?.error;

    if (error) {
      dispatch(addNotification(UPDATE_ORDER_SETTINGS_GENERAL, error, NOTIFICATION_STATUS.ERROR));
      dispatch(getAccountInfo());
      return;
    }

    await dispatch(getAccountInfo());
    dispatch(addNotification(UPDATE_ORDER_SETTINGS_GENERAL, 'Successfully updated.  Orders may be updating behind the scenes.', NOTIFICATION_STATUS.SUCCESS));

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const error = e?.response?.data?.error;
    dispatch(addNotification(UPDATE_ORDER_SETTINGS_GENERAL, error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch(getAccountInfo());

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setFillDefaultValues = (fillDefaultValues) => ({
  type: SET_FILL_DEFAULT_VALUES,
  payload: fillDefaultValues
});

export const getFollowUps = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_FOLLOW_UPS,
    });
    const response = await axios.post('/api/followupmessages/orders', getState().orderSettings.query);
    dispatch({
      type: GET_FOLLOW_UPS_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(GET_FOLLOW_UPS_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_FOLLOW_UPS_ERROR,
    });
  }
};

export const updateFollowUp = (messageChanges, doWhenTry = () => {}, doWhenCatch = () => {}) => async (dispatch) => {
  try {
    const response = await axios.put('/api/followupmessages', { messageChanges });
    dispatch({
      type: UPDATE_FOLLOW_UP,
      payload: response?.data?.message,
    });
    dispatch(addNotification(UPDATE_FOLLOW_UP, 'Message updated', NOTIFICATION_STATUS.SUCCESS));
    dispatch(getFollowUps());
    doWhenTry();
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(UPDATE_FOLLOW_UP, message || error?.message || 'Try again later', NOTIFICATION_STATUS.ERROR));
    doWhenCatch(error, message);
  }
};

export const setFollowUps = (followUps) => ({
  type: SET_FOLLOW_UPS,
  payload: followUps
});

export const setFollowUpsQuery = (query) => ({
  type: SET_FOLLOW_UPS_QUERY,
  payload: query
});

export const clearFollowUpsQueryFilter = () => ({
  type: CLEAR_FOLLOW_UPS_QUERY_FILTER,
});

export const setSelectedFollowUps = (selectedFollowUps) => ({
  type: SET_SELECTED_FOLLOW_UPS,
  payload: selectedFollowUps
});

export const setBackToFollowUpList = (payload) => ({
  type: SET_BACK_TO_FOLLOW_UP_LIST,
  payload,
});

export const clearBackToFollowUpList = (clearAll = false) => ({
  type: SET_BACK_TO_FOLLOW_UP_LIST,
  payload: !clearAll ? {
    followUps: [],
    query: {},
  } : false,
});

export const setCurrentMessage = (message) => ({
  type: SET_CURRENT_MESSAGE,
  payload: message
});

export const setFileUploadToMessage = (file) => ({
  type: SET_FILE_UPLOAD_TO_MESSAGE,
  payload: file
});
