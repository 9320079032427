import axios from 'src/utils/axios';
import { addNotification } from 'src/actions/notificationActions';
import { NOTIFICATION_STATUS } from 'src/constants';
import helpers from 'src/helpers';

export const SET_PARTY_VENUES = '@partyvenues/set-party-venues';
export const GET_PARTY_VENUES = '@partyvenues/get-party-venues';
export const GET_PARTY_VENUES_SUCCESS = '@partyvenues/get-party-venues-success';
export const GET_PARTY_VENUES_ERROR = '@partyvenues/get-party-venues-error';

export const SET_PARTY_VENUES_QUERY = '@partyvenues/set-party-venues-query';
export const SET_BACK_TO_PARTY_VENUES_LIST = '@partyvenues/set-back-to-party-venues-list';

export const getPartyVenues = () => async (dispatch, getState) => {
  dispatch({
    type: GET_PARTY_VENUES,
  });
  try {
    const response = await axios.post('/api/partySettings/partyVenues', getState().partyVenues.query);
    dispatch({
      type: GET_PARTY_VENUES_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_PARTY_VENUES_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PARTY_VENUES_ERROR,
    });
  }
};

export const setPartyVenues = (partyVenues) => ({
  type: SET_PARTY_VENUES,
  payload: partyVenues
});

export const setPartyVenuesQuery = (query) => ({
  type: SET_PARTY_VENUES_QUERY,
  payload: query
});

export const setBackToPartyVenuesList = (payload) => ({
  type: SET_BACK_TO_PARTY_VENUES_LIST,
  payload,
});

export const clearBackToPartyVenuesList = (clearAll = false) => ({
  type: SET_BACK_TO_PARTY_VENUES_LIST,
  payload: !clearAll ? {
    partyVenues: [],
    query: {},
  } : false,
});
