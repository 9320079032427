import axios from 'src/utils/axios';
import { NOTIFICATION_STATUS } from 'src/constants';
import { addNotification } from 'src/actions/notificationActions';

export const SET_CHECKED_SUBSCRIPTION = '@orders/set-checked-subscription';
export const SET_EDIT_SUBSCRIPTION_CARD = '@orders/set-edit-subscription-card';
export const CLEAR_FORM_REFERRAL_CODE_SEND_DATA = '@orders/clear-form-referral-code-send-data';
export const SET_VALUE_FORM_REFERRAL_CODE_SEND_DATA = '@orders/set-value-form-referral-code-send-data';

export const GET_SUBSCRIPTION_LIST = '@orders/get-subscription-list';
export const GET_SUBSCRIPTION_LIST_SUCCESS = '@orders/get-subscription-list-success';
export const GET_SUBSCRIPTION_LIST_ERROR = '@orders/get-subscription-list-error';

export const setCheckedSubscription = (subscription) => ({
  type: SET_CHECKED_SUBSCRIPTION,
  payload: subscription
});

export const setEditSubscriptionCard = (value) => ({
  type: SET_EDIT_SUBSCRIPTION_CARD,
  payload: value
});

export const clearFormReferralCodeSendData = () => ({
  type: CLEAR_FORM_REFERRAL_CODE_SEND_DATA,
});

export const setValueToFormReferralCodeSendData = (payload) => ({
  type: SET_VALUE_FORM_REFERRAL_CODE_SEND_DATA,
  payload,
});

export const getSubscriptionList = () => async (dispatch) => {
  dispatch({
    type: GET_SUBSCRIPTION_LIST,
  });
  try {
    const response = await axios.get('/api/subscription/list');

    dispatch({
      type: GET_SUBSCRIPTION_LIST_SUCCESS,
      payload: response?.data || []
    });
  } catch (e) {
    const { message } = e?.response?.data || {};
    dispatch({
      type: GET_SUBSCRIPTION_LIST_ERROR,
      payload: []
    });
    dispatch(addNotification(GET_SUBSCRIPTION_LIST_ERROR, message || 'Server error', NOTIFICATION_STATUS.ERROR));
  }
};
