/* eslint-disable no-param-reassign */
import produce from 'immer';
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from 'src/actions/notificationActions';

const initialState = {
  notifications: [],
};

const notificationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_NOTIFICATION: {
      return produce(state, (draft) => {
        draft.notifications = [
          ...state.notifications,
          payload
        ];
      });
    }

    case REMOVE_NOTIFICATION: {
      return produce(state, (draft) => {
        draft.notifications = state.notifications.filter((item) => item.key !== payload);
      });
    }

    default: {
      return state;
    }
  }
};

export default notificationReducer;
