import { STYLE_OPTIONS } from 'src/constants';

const color = STYLE_OPTIONS.COLOR_TEXT;
const fontFamily = STYLE_OPTIONS.FONT_FAMILY;

const modalDialogFormStyle = (theme) => ({
  form: {
    padding: '25px 72px',
    width: '100%',
    overflow: 'hidden',

    '& .disabledBox .MuiInputBase-root.Mui-disabled': {
      backgroundColor: '#F4F4F4',
    },

    '& .disabledBox .MuiInputBase-input.Mui-disabled': {
      color,
    },

    '& .disabledBox .MuiInputBase-root.Mui-disabled fieldset': {
      border: 0,
    },

    [theme.breakpoints.down('lg')]: {
      padding: '15px 15px',
      '& label.MuiFormControlLabel-root': {
        alignItems: 'flex-start',
        '& .MuiCheckbox-root': {
          marginTop: -9
        }
      }
    },

    [theme.breakpoints.down('sm')]: {
      padding: '12px 0px'
    },

    '& .center': {
      textAlign: 'center'
    },

    '& .formGridGrope': {
      paddingTop: '22px'
    },

    '& .formGridGrope.padding31': {
      paddingTop: '31px'
    },

    '& .formGridGrope.padding38': {
      paddingTop: '38px'
    },

    '& .formGridGropePhone': {
      paddingTop: '7px'
    },

    '& .MuiFormLabel-root': {
      color: '#858585'
    },

    '& div.MuiFormLabel-root': {
      color,
      fontSize: '16px',
      fontWeight: 400,
      fontFamily
    },

    '& .MuiFormLabel-root.Mui-error': {
      color: '#f44336'
    },

    '& .MuiInputLabel-outlined': {
      fontSize: '13px',
      paddingTop: 2
    },

    '& .MuiOutlinedInput-root legend': {
      fontSize: '10px'
    }
  },
  formGroupLabel: {
    color,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '24px',
    fontFamily
  },

  formGroupLabelPaddingTop: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '24px',
    fontFamily,
    paddingTop: 20,
  },

  formGroupLabelPaddingBottom: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '24px',
    fontFamily,
    paddingBottom: 20,
  },

  formGroupLabelPaddingTopBottom: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '24px',
    fontFamily,
    paddingTop: 20,
    paddingBottom: 20,
  },

  textMessageLabel: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '32px',
    paddingTop: 14,
    fontFamily,
    '&.flex': {
      display: 'flex',
      justifyContent: 'space-between'
    },
    '& .text': {
      width: '100%'
    },
    '& .rightWrapper': {
      marginLeft: 10,
      lineHeight: '1px'
    },
    '& .svgIcon': {
      width: '32px',
      height: '32px',
      cursor: 'pointer'
    }
  },

  textMessageAfter: {
    fontFamily,
    fontWeight: 400,
    color,
    fontSize: '12px',
    lineHeight: '14px',
    paddingTop: 5,
    width: '100%',

    '&.flex': {
      display: 'flex',
      justifyContent: 'space-between'
    },

    '& .icon': {
      fontSize: '14px',
      marginRight: 10,
      color: '#858585',

      '& .MuiSvgIcon-root': {
        fontSize: 'inherit'
      }
    },

    '& .middleText': {
      width: '100%',
      color: '#858585'
    },

    '& .rightText': {
      marginLeft: 10
    }
  },
});

export default modalDialogFormStyle;
