import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import {
  changeCampaign,
} from 'src/actions/accountActions';
import helpers from 'src/helpers';
import CardChangeCampaigns from 'src/containers/ChangeCampaigns/CardChangeCampaigns';
import * as Yup from 'yup';

function ChangeCampaignsForm({
  // classes,
  campaignNamesToChange,
  currentCampaigns,
  loading,
}) {
  const dispatch = useDispatch();

  const initialValues = {
  };

  for (let i = 0; i <= campaignNamesToChange.length - 1; i++) {
    initialValues[`campaign${[i]}`] = '';
  }

  const validationSchemaArray2 = [];
  for (let i = 0; i <= campaignNamesToChange.length - 1; i++) {
    validationSchemaArray2.push({
      fieldName: `campaign${i}`,
      validation: Yup.string()
        .typeError('Choose from drop-down list')
        .min(1, 'Choose from drop-down list')
        .required('Campaign is required'),
    });
  }

  // eslint-disable-next-line prefer-spread
  const validationSchemaArray = [].concat.apply([], validationSchemaArray2);

  const validationSchema = Yup.lazy(() => Yup.object().shape(
    validationSchemaArray.reduce((acc, validationSchemaElement) => ({
      ...acc,
      [validationSchemaElement.fieldName]: validationSchemaElement.validation
    }), {})
  ));

  const onSubmit = async (values, {
    resetForm,
    setErrors,
    setStatus,
    setSubmitting
  }) => {
    const doWhenTry = () => {
      resetForm();
      setStatus({ success: true });
      setSubmitting(false);
    };
    const doWhenCatch = (error) => {
      setStatus({ success: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    };

    const sendData = {
      campaignsChangedTo: values,
      campaignNamesToChange,
      currentCampaigns,
    };

    dispatch(changeCampaign(sendData, doWhenTry, doWhenCatch));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const {
    handleSubmit,
    setFieldTouched,
  } = formik;

  const handleFocusFieldValidate = (event) => {
    helpers.formValidation.validateField(event, setFieldTouched);
  };

  return (
    <form
      id="formChangeCampaigns"
      // className={classes.form}
      onSubmit={handleSubmit}
    >
      <CardChangeCampaigns
        formik={formik}
        // classes={classes}
        loading={loading}
        campaignNamesToChange={campaignNamesToChange}
        currentCampaigns={currentCampaigns}
        handleFocusFieldValidate={handleFocusFieldValidate}
      />
    </form>
  );
}

ChangeCampaignsForm.propTypes = {
  // classes: PropTypes.object.isRequired,
  campaignNamesToChange: PropTypes.array,
  currentCampaigns: PropTypes.array,
  loading: PropTypes.bool,
};

ChangeCampaignsForm.defaultProps = {
  currentCampaigns: [],
  campaignNamesToChange: [],
  loading: false,
};

export default ChangeCampaignsForm;
