/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_SMART_ACTION,
  GET_SMART_ACTION_SUCCESS,
  GET_SMART_ACTION_ERROR,
  DELETE_SMART_ACTION,
  DELETE_SMART_ACTION_SUCCESS,
  DELETE_SMART_ACTION_ERROR,
  CLEAR_SMART_ACTION_WAS_DELETED,
  UPDATE_SMART_ACTION,
  UPDATE_SMART_ACTION_SUCCESS,
  UPDATE_SMART_ACTION_ERROR,
  CREATE_SMART_ACTION,
  CREATE_SMART_ACTION_SUCCESS,
  CREATE_SMART_ACTION_ERROR,
  SET_SMART_ACTION_DETAILS_TAB,
  SET_FORM_SMART_ACTION,
  SET_VALUES_SMART_ACTION_MESSAGE,
  GET_FOLLOW_UP_MESSAGES,
  GET_FOLLOW_UP_MESSAGES_SUCCESS,
  GET_FOLLOW_UP_MESSAGES_ERROR,
  REPLACE_SYSTEM_GEN_MESSAGE,
  REPLACE_SYSTEM_GEN_MESSAGE_SUCCESS,
  REPLACE_SYSTEM_GEN_MESSAGE_ERROR,
  SET_SMART_ACTION_GROUP_ID,
  SET_UPDATE_PARTY_SMART_ACTIONS,
} from 'src/actions/smartActionDetailsActions';

const smartActionMessage = {
  attachment: false,
  attachmentContainer: null,
  loading: false,
};

const followUpMessages = {
  followUpMessageList: [],
  loading: false,
};

const initialState = {
  smartActionGroupId: null,
  smartAction: {},
  smartActionWasDeleted: false,
  loading: false,
  currentNavigationTab: 'smartAction',
  formSmartAction: {},
  smartActionMessage,
  followUpMessages,
  updatePartySmartActions: false,
};

const smartActionDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SMART_ACTION: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_SMART_ACTION_SUCCESS: {
      return produce(state, (draft) => {
        draft.smartAction = payload?.smartAction || {};
        draft.loading = false;
      });
    }

    case GET_SMART_ACTION_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case DELETE_SMART_ACTION: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case DELETE_SMART_ACTION_SUCCESS: {
      return produce(state, (draft) => {
        draft.smartAction = payload ? initialState.smartAction : state.smartAction;
        draft.loading = false;
        draft.smartActionWasDeleted = true;
      });
    }

    case DELETE_SMART_ACTION_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case CLEAR_SMART_ACTION_WAS_DELETED: {
      return produce(state, (draft) => {
        draft.smartActionWasDeleted = false;
      });
    }

    case CREATE_SMART_ACTION:
    case UPDATE_SMART_ACTION: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case CREATE_SMART_ACTION_SUCCESS:
    case CREATE_SMART_ACTION_ERROR:
    case UPDATE_SMART_ACTION_SUCCESS:
    case UPDATE_SMART_ACTION_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_SMART_ACTION_DETAILS_TAB: {
      return produce(state, (draft) => {
        draft.currentNavigationTab = payload || initialState.currentNavigationTab;
      });
    }

    case SET_FORM_SMART_ACTION: {
      return produce(state, (draft) => {
        draft.formSmartAction = payload ? {
          ...state.formSmartAction,
          ...payload,
        } : initialState.formSmartAction;
      });
    }

    case SET_VALUES_SMART_ACTION_MESSAGE: {
      return produce(state, (draft) => {
        draft.smartActionMessage = payload ? {
          ...state.smartActionMessage,
          ...payload,
        } : smartActionMessage;
      });
    }

    case GET_FOLLOW_UP_MESSAGES: {
      return produce(state, (draft) => {
        draft.followUpMessages.loading = true;
      });
    }

    case GET_FOLLOW_UP_MESSAGES_SUCCESS:
    case GET_FOLLOW_UP_MESSAGES_ERROR: {
      return produce(state, (draft) => {
        draft.followUpMessages.loading = false;
        draft.followUpMessages.followUpMessageList = payload ? [
          ...state.followUpMessages.followUpMessageList,
          ...payload,
        ] : followUpMessages.followUpMessageList;
      });
    }

    case REPLACE_SYSTEM_GEN_MESSAGE: {
      return produce(state, (draft) => {
        draft.smartActionMessage.loading = true;
      });
    }

    case REPLACE_SYSTEM_GEN_MESSAGE_SUCCESS:
    case REPLACE_SYSTEM_GEN_MESSAGE_ERROR: {
      return produce(state, (draft) => {
        draft.smartActionMessage.loading = false;
        draft.smartActionMessage.systemGenMessageReplaced = payload || false;
      });
    }

    case SET_SMART_ACTION_GROUP_ID: {
      return produce(state, (draft) => {
        draft.smartActionGroupId = payload || null;
      });
    }

    case SET_UPDATE_PARTY_SMART_ACTIONS: {
      return produce(state, (draft) => {
        draft.updatePartySmartActions = payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default smartActionDetailsReducer;
