// eslint-disable-next-line import/prefer-default-export
export const PARTY_TYPE_VALUES = [
  'Online',
  'In-Home',
  'Event',
];

export const PARTY_BOOKED_VALUES = [
  // 'Booked a Party',
  'Booked an Event',
  'Has Not Booked',
  'Maybe Booking',
  'Said No to Booking'
];

export const JOINED_VALUES = [
  'Joined as Consultant',
  'Has Not Joined',
  'Maybe Joining',
  'Said No to Joining'
];
