const fontFamily = 'Roboto';

const tosAndPrivacyStatementTextStyle = {
  text: {
    fontFamily,
    fontSize: '14px',
    lineHeight: '1.5em',
    fontWeight: 500,
    '& a': {
      color: '#6200EE',
      textDecoration: 'underline',
    },
    '& a:hover': {
      color: '#555555',
    }
  },
};

export default tosAndPrivacyStatementTextStyle;
