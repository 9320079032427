import { STYLE_OPTIONS } from 'src/constants';

const fontWeight = 400;

const buttonBoxStyle = () => ({
  rootBox: {},

  buttonEdit: {
    ...STYLE_OPTIONS.BUTTON_PRIMARY,
    fontWeight,

    '& .MuiSvgIcon-root': {
      marginRight: 8,
    },
  },

  buttonVideo: {
    paddingTop: 0,
  }
});

export default buttonBoxStyle;
