import { STYLE_OPTIONS } from 'src/constants';
import pageViewStyle from 'src/components/View/Page/pageView.style';

const helpAndInformationStyles = (theme) => ({
  ...pageViewStyle(theme),
  messageForMessageBox: {
    fontFamily: STYLE_OPTIONS.FONT_FAMILY,

    '& .inner-message': {
      display: 'inline-block',
      marginRight: 25,
      paddingTop: 2,
      paddingBottom: 2,
      verticalAlign: 'top',
      color: STYLE_OPTIONS.COLOR_TEXT,

      '&:last-child': {
        marginRight: 0,
      },

      '& a': STYLE_OPTIONS.A_PRIMARY,
    },

    '& .support-facebook': {
      display: 'inline-block',
      marginRight: 25,
      paddingTop: 2,
      paddingBottom: 2,
      verticalAlign: 'top',
      color: STYLE_OPTIONS.COLOR_TEXT,
      fontSize: '12px',
      lineHeight: '20px',
    },

  },
});

export default helpAndInformationStyles;
