import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@mui/material';
import helpers from 'src/helpers';

function TextShowMore({
  text, maxTextLength, orderId, checked, setChecked
}) {
  const { firstStr = '', secondStr = '' } = helpers.splitStringIntoTwoParts(text, maxTextLength) || {};

  const getButton = (
    <Link
      component="button"
      variant="body2"
      onClick={(event) => {
        setChecked(event, orderId);
      }}
      underline="hover"
    >
      {checked ? 'show less' : 'show more'}
    </Link>
  );

  return (
    <>
      {checked ? text : firstStr}
      {secondStr !== ''
        && (checked ? (
          <div>{getButton}</div>
        ) : (
          <>
            ...
            {getButton}
          </>
        ))}
    </>
  );
}

TextShowMore.propTypes = {
  setChecked: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  maxTextLength: PropTypes.number.isRequired,
  orderId: PropTypes.number.isRequired,
  checked: PropTypes.bool.isRequired
};

export default TextShowMore;
