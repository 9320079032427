const popoverEmojiStyle = {
  popover: {
    '& .emoji-mart-bar .emoji-mart-preview': {
      display: 'none',
      opacity: 0,
    },
  },

  svgButton: {
    padding: 0,
    width: 'auto',
    minWidth: 0,
  },
};

export default popoverEmojiStyle;
