import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Grid from '@mui/material/Unstable_Grid2';
import { withStyles } from 'tss-react/mui';
import TextFieldFormik from 'src/components/TextFieldFormik';
import {
  SCREEN,
  NOTIFICATION_STATUS,
  STORAGE,
  MESSAGES,
} from 'src/constants';
import * as Yup from 'yup';
import {
  setFileUploadToMessage,
  createMessage,
  updateMessage,
} from 'src/actions/messagesActions';
import { addNotification } from 'src/actions/notificationActions';
import { useSnackbar } from 'notistack';
import TextFieldEmojiMaxLength from 'src/views/Settings/MessageView/EditMessage/TextFieldEmojiMaxLength';
import UploadImageField from 'src/views/Settings/MessageView/EditMessage/UploadImageField';
import helpers from 'src/helpers';

import editMessageStyle from 'src/views/Settings/MessageView/EditMessage/editMessage.style';

const MAX_TEXT_MESSAGE_LENGTH = 1024;
const MIN_TEXT_MESSAGE_LENGTH = 2;

const VALIDATE_MESSAGE = {
  validateFileSize: 'File size must be less than 1.4Mb',
  validateFileType: 'Must be a jpg, jpeg, png, or gif file',
};

function EditMessage({
  message,
  classes,
  loading,
  userMessageCodes,
}) {
  const {
    id,
    code,
    description,
    systemGenMessage,
    attachment,
    attachmentContainer,
    fileUpload = false,
    addNewTemplate = false,
  } = message || {};

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user, azAccountName, databaseBeingUsed } = useSelector((state) => state.account);

  const [windowWidth, setWindowWidth] = useState(0);

  const isItUserTemplate = helpers.isItUserMassageTemplate(code) || addNewTemplate;

  const getValuesFromCode = (value, msg = false) => {
    if (msg) {
      return value ? value.replace(/\d/g, '') : MESSAGES.MESSAGE_USER_CODE_TYPE;
    }
    return value ? value.replace(/\D/g, '') : '';
  };

  const getNextCodeValue = () => {
    if (addNewTemplate) {
      const newUserMessageCodes = helpers.transform.toArray(userMessageCodes);
      for (let i = 1; i <= 999; i++) {
        const newCode = helpers.transform.toMessageCode(MESSAGES.MESSAGE_USER_CODE_TYPE, i);
        if (!newUserMessageCodes.find((item) => item === newCode)) {
          return newCode;
        }
      }
    }

    return '';
  };

  const hasCodeAlreadyBeenUsed = (value) => {
    const newCode = helpers.transform.toMessageCode(MESSAGES.MESSAGE_USER_CODE_TYPE, value);
    if (newCode === code) {
      return true;
    }

    return !helpers.transform.toArray(userMessageCodes).find((item) => item === newCode);
  };

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };
    updateWindowWidth();
    window.addEventListener('resize', updateWindowWidth);
    return () => window.removeEventListener('resize', updateWindowWidth);
  }, []);

  const defaultScreenSpacing = windowWidth < SCREEN.MD ? 2 : 5;

  const initialValues = {
    message: isItUserTemplate ? getValuesFromCode(code, true) : code || '',
    message2: getValuesFromCode(isItUserTemplate && !addNewTemplate ? code : getNextCodeValue()),
    scenario: description || '',
    systemGenMessage: systemGenMessage || '',
    attachment: attachment || '',
    attachmentContainer: attachmentContainer || null,
  };

  const validationSchema = Yup.object().shape({
    ...(isItUserTemplate ? {
      message2: Yup.number()
        .moreThan(0, 'Must be greater than 0')
        .lessThan(1000, 'Must be less than 1000')
        .test('hasCodeAlreadyBeenUsed', 'This template number has already been used.', hasCodeAlreadyBeenUsed)
        .required('This is required field'),
      scenario: Yup.string().required('This is required field'),
    } : {}),
    systemGenMessage: Yup.string()
      .min(MIN_TEXT_MESSAGE_LENGTH, `Text Message to Send must be at least ${MIN_TEXT_MESSAGE_LENGTH} characters`)
      .max(MAX_TEXT_MESSAGE_LENGTH, `Text Message to Send must be at most ${MAX_TEXT_MESSAGE_LENGTH} characters`),
    attachment: Yup.string()
      .test('invalidAttachmentSize', VALIDATE_MESSAGE.validateFileSize, () => helpers.azure.validateFileSize(fileUpload))
      .test('invalidAttachmentType', VALIDATE_MESSAGE.validateFileType, () => helpers.azure.validateFileType(fileUpload)),
  });

  const fileWasNotUpload = () => {
    dispatch(addNotification('file upload', 'Your file wasn\'t uploaded', NOTIFICATION_STATUS.ERROR));
  };

  const fileWasNotDelete = () => {
    dispatch(addNotification('file delete', 'Your file wasn\'t deleted', NOTIFICATION_STATUS.ERROR));
  };

  const onSubmit = async (values, {
    resetForm, setErrors, setStatus, setSubmitting
  }) => {
    const doWhenTry = () => {
      dispatch(setFileUploadToMessage(false));
      resetForm();
      setStatus({ success: true });
      setSubmitting(false);
    };
    const doWhenCatch = (error, errorMessage) => {
      setStatus({ success: false });
      setErrors({ submit: errorMessage || error?.message || 'Try again later' });
      setSubmitting(false);
    };
    // 2022-12-08 - Eventually instead of passing "false" as the last parameter on this next call (allowDeleteFile), we'll want to call an endpoint on the server for most places that allow delete of an image (but not order edit custom message, parties, etc. that are "endpoints" themselves where we never copy that image to another place).  So, mainly templates & partyTypes as of now we need to check, but eventually campaigns that can be copied or shared, etc.
    const newValuesAttachment = await helpers.azure.loadFileToAzureAndGetStatusCode(fileUpload, values.attachment, user.id, azAccountName, STORAGE.sas, databaseBeingUsed, values.attachmentContainer, fileWasNotUpload, fileWasNotDelete, false, false, false);
    if (newValuesAttachment === null) {
      enqueueSnackbar('There are some problems with Azure service.', {
        variant: 'error'
      });
      return;
    }
    if (helpers.isEmpty(values.systemGenMessage) && (!newValuesAttachment || helpers.isEmpty(newValuesAttachment))) {
      dispatch(addNotification('sendFile', 'You must enter either a text message or select an image.', NOTIFICATION_STATUS.ERROR));
      return;
    }
    const messageChanges = {
      id: helpers.transform.stringToNull(id),
      code: isItUserTemplate ? helpers.transform.toMessageCode(values.message, values.message2) : values.message,
      description: values.scenario,
      systemGenMessage: values.systemGenMessage,
      attachment: newValuesAttachment,
      attachmentContainer: values.attachmentContainer,
    };
    dispatch(addNewTemplate && isItUserTemplate ? createMessage(messageChanges, doWhenTry, doWhenCatch) : updateMessage(messageChanges, doWhenTry, doWhenCatch));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    setFieldValue,
    setFieldTouched
  } = formik;

  const handleFocusFieldValidate = (event) => {
    helpers.formValidation.validateField(event, setFieldTouched);
  };

  return (
    <form
      id="updateMessage"
      className={classes.updateMessageForm}
      onSubmit={handleSubmit}
    >
      <Grid
        container
        spacing={defaultScreenSpacing}
      >
        <Grid xs={12}>
          <div className={classes.formGroupLabel}>
            {isItUserTemplate ? 'Template #' : 'Message #'}
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
      >
        <Grid
          xs={isItUserTemplate ? 6 : 12}
        >
          <TextFieldFormik
            touchedValue={touched.message}
            errorValue={errors.message}
            name="message"
            handleChange={handleChange}
            handleBlur={handleBlur}
            onFocus={handleFocusFieldValidate}
            value={values.message}
            disabled
            disabledBox
            required
          />
        </Grid>
        {isItUserTemplate && (
          <Grid xs={isItUserTemplate ? 6 : 12}>
            <TextFieldFormik
              touchedValue={touched.message2}
              errorValue={errors.message2}
              name="message2"
              handleChange={handleChange}
              handleBlur={handleBlur}
              onFocus={handleFocusFieldValidate}
              value={values.message2}
              disabled={loading}
              fieldType="numberFormat"
              format="###"
              required
            />
          </Grid>
        )}
      </Grid>
      <Grid
        className="formGridGrope"
        container
        spacing={defaultScreenSpacing}
      >
        <Grid xs={12}>
          <div className={classes.formGroupLabel}>Scenario</div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={8}
      >
        <Grid xs={12}>
          <TextFieldFormik
            touchedValue={touched.scenario}
            errorValue={errors.scenario}
            name="scenario"
            handleChange={handleChange}
            handleBlur={handleBlur}
            onFocus={handleFocusFieldValidate}
            value={values.scenario}
            disabled={!isItUserTemplate || loading}
            disabledBox={!isItUserTemplate}
            {...isItUserTemplate ? { required: true } : {}}
            rows={5}
            multiline
          />
        </Grid>
      </Grid>
      <TextFieldEmojiMaxLength
        title="Text Message To Send"
        classes={classes}
        loading={loading}
        valueName="systemGenMessage"
        currentValue={values.systemGenMessage}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        maxTextMessageLength={MAX_TEXT_MESSAGE_LENGTH}
        windowWidth={windowWidth}
        errorValue={errors.systemGenMessage}
        touchedValue={touched.systemGenMessage}
        defaultScreenSpacing={defaultScreenSpacing}
        screenName={isItUserTemplate ? 'Message Templates Edit' : 'Order Edit'}
      />
      <UploadImageField
        title="Upload Image To Send"
        userId={user?.id}
        fileName={values.attachment}
        fileUpload={fileUpload}
        loading={loading}
        defaultScreenSpacing={defaultScreenSpacing}
        errorValue={errors.attachment}
        validateMessage={VALIDATE_MESSAGE}
        azAccountName={azAccountName}
        databaseBeingUsed={databaseBeingUsed}
        container={values.attachmentContainer}
        setFieldValue={setFieldValue}
      />
    </form>
  );
}

EditMessage.propTypes = {
  message: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  userMessageCodes: PropTypes.array.isRequired,
};

export default withStyles(EditMessage, editMessageStyle, { withTheme: true });
