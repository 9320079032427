const copyTextStyle = {
  root: {
    display: 'inline-block',
    color: '#5850EC',
    textTransform: 'none',
    padding: 0,
    margin: 0,
    background: 'none',
    verticalAlign: 'middle',
    minWidth: 0,
    minHeight: 0,
    lineHeight: '1em',
    fontSize: 'inherit',

    '&:hover': {
      background: 'none',
    },

    '& .svgIcon': {
      verticalAlign: 'middle',
      width: 16,
      height: 16,
      marginLeft: 5,
      marginTop: -2,
    },

  },
};

export default copyTextStyle;
