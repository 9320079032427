const headerStyle = (theme) => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  },
  buttonBox: {},
  leftTitleButton: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: 10,
  },
});

export default headerStyle;
