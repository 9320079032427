/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_CURRENT_MESSAGE,
  SET_FILE_UPLOAD_TO_MESSAGE,
  SET_IS_MODAL_VISIBLE,
  SET_IS_MODAL_LOADING,
  GET_MESSAGES,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_ERROR,
  CREATE_MESSAGE,
  UPDATE_MESSAGE,
  DELETE_MESSAGE,
  SET_MESSAGE_NAVIGATION_TAB,
  GET_USER_MESSAGE_CODES,
} from 'src/actions/messagesActions';

const initialState = {
  messages: [],
  userMessageCodes: [],
  currentMessage: {},
  loading: false,
  currentNavigationTab: 'user',
  isModalVisible: false,
  isModalLoading: false,
};

const messagesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CURRENT_MESSAGE: {
      return produce(state, (draft) => {
        draft.currentMessage = payload;
      });
    }

    case SET_FILE_UPLOAD_TO_MESSAGE: {
      return produce(state, (draft) => {
        draft.currentMessage = {
          ...state.currentMessage,
          fileUpload: payload,
        };
      });
    }

    case SET_IS_MODAL_VISIBLE: {
      return produce(state, (draft) => {
        draft.isModalVisible = !state.isModalVisible;
      });
    }

    case SET_IS_MODAL_LOADING: {
      return produce(state, (draft) => {
        draft.isModalLoading = payload;
      });
    }

    case GET_MESSAGES: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_MESSAGES_SUCCESS: {
      return produce(state, (draft) => {
        draft.messages = payload?.messages || [];
        draft.loading = false;
      });
    }

    case GET_MESSAGES_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case CREATE_MESSAGE:
    case UPDATE_MESSAGE: {
      return produce(state, (draft) => {
        draft.currentMessage = payload || {};
        draft.isModalLoading = false;
      });
    }

    case SET_MESSAGE_NAVIGATION_TAB: {
      return produce(state, (draft) => {
        draft.currentNavigationTab = payload || initialState.currentNavigationTab;
      });
    }

    case DELETE_MESSAGE: {
      return produce(state, (draft) => {
        draft.currentMessage = {};
        draft.isModalVisible = false;
        draft.isModalLoading = false;
      });
    }

    case GET_USER_MESSAGE_CODES: {
      return produce(state, (draft) => {
        draft.userMessageCodes = payload || initialState.userMessageCodes;
        draft.isModalLoading = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default messagesReducer;
