import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Hidden, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { withStyles } from 'tss-react/mui';
import PopoverEmoji from 'src/components/PopoverEmoji/PopoverEmoji';
import SelectDynamicField from 'src/components/SelectDynamicField/SelectDynamicField';
import HelpCircleWithContainer from 'src/components/HelpCircle/HelpCircleWithContainer';

import InfoIcon from '@mui/icons-material/Info';
import textFieldEmojiStyle from 'src/components/TextFieldFormik/textFieldEmoji.style';

function TextFieldEmoji({
  title,
  classes,
  loading,
  valueName,
  currentValue,
  touchedValue,
  errorValue,
  maxTextMessageLength,
  handleBlur,
  handleChange,
  setFieldValue,
  setFieldTouched,
  defaultScreenSpacing,
  helpTextForTitle,
  helpTextForBlanksError,
  className,
  classNameTextField,
  showAddEmoji,
  showAddDynamicField,
  screenName,
}) {
  const [messagePosition, setMessagePosition] = useState(false);

  const getMaxStrLen = (str, length) => {
    const newStr = str.slice(0, length);
    // The length of emoji is one or two chars. There is last char 55357 in string if we cut emoji.
    return newStr.charCodeAt(newStr.length - 1) === 55357 ? getMaxStrLen(newStr, length - 1) : newStr;
  };

  const cutMaxTextMessageLength = (value) => {
    if (value.length >= maxTextMessageLength) {
      const newValue = getMaxStrLen(value, maxTextMessageLength);
      setFieldValue(valueName, newValue, false);
    }
  };

  const saveMessageCursorPosition = (event) => {
    setMessagePosition(event?.target?.selectionStart);
  };

  const handleKeyUpCustomTextMessage = (event) => {
    const value = event?.target?.value || '';
    setFieldTouched(valueName, true);
    saveMessageCursorPosition(event);
    cutMaxTextMessageLength(value);
  };

  const handleAddEmoji = (e, currentText) => {
    const emoji = e.native;
    // true when messagePosition isn't number maybe false or null
    const isNullCustomMessagePosition = !messagePosition && messagePosition !== 0;
    const text = isNullCustomMessagePosition
      ? `${currentText}${emoji}`
      : `${currentText.substring(0, messagePosition)}${emoji}${currentText.substring(messagePosition)}`;
    const length = !isNullCustomMessagePosition ? messagePosition + emoji.length : text.length;
    setFieldTouched(valueName, true);
    setFieldValue(valueName, text, true);
    setMessagePosition(length);
    // We show emoji and hide if it is needed after 100ms.
    setTimeout(() => {
      cutMaxTextMessageLength(text);
    }, 100);
  };

  const handleAddDynamicField = (field, currentText) => {
    const isNullCustomMessagePosition = !messagePosition && messagePosition !== 0;
    const text = isNullCustomMessagePosition
      ? `${currentText}${field}`
      : `${currentText.substring(0, messagePosition)}${field}${currentText.substring(messagePosition)}`;
    const length = !isNullCustomMessagePosition ? messagePosition + field.length : text.length;
    setFieldTouched(valueName, true);
    setFieldValue(valueName, text, true);
    setMessagePosition(length);
  };

  const textFieldVariant = currentValue && currentValue.includes('__________') ? 'filled' : 'outlined';

  return (
    <>
      <Grid
        className={`${classes.root} ${className}`}
        container
        spacing={defaultScreenSpacing}
      >
        <Grid xs={12}>
          <div className={`${classes.textMessageLabel} flex`}>
            <HelpCircleWithContainer
              positionX="afterText"
              positionY="center"
              showInfo={helpTextForTitle}
            >
              <div className="text">
                {title}
              </div>
            </HelpCircleWithContainer>
            <div className="rightWrapper">
              <Grid spacing={2} container>
                {showAddDynamicField && (
                <Grid xs={6}>
                  <SelectDynamicField
                  // classes={classes}
                    addField={handleAddDynamicField}
                    currentValue={currentValue}
                    screenName={screenName}
                  />
                </Grid>
                )}
                {showAddEmoji && (
                <Grid xs={6}>
                  {!loading && (
                  <Hidden mdDown>
                    <PopoverEmoji
                      addEmoji={handleAddEmoji}
                      currentValue={currentValue}
                    />
                  </Hidden>
                  )}
                </Grid>
                )}
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={8}
      >
        <Grid
          xs={12}
        >
          {currentValue && currentValue.includes('__________') && (
          <div
            className={`${classes.warningTextBefore} flex`}
          >
            {/* <div className="icon">
            <InfoIcon />
          </div> */}
            <HelpCircleWithContainer
              positionX="afterText"
              positionY="center"
              showInfo={helpTextForBlanksError}
              color="red"
            >
              <div className="middleText">
                Please review!&nbsp;&nbsp;&nbsp;Includes blanks!
              </div>
            </HelpCircleWithContainer>
          </div>
          )}
          <TextField
            error={Boolean(touchedValue && errorValue)}
            helperText={touchedValue && errorValue}
            label={title}
            name={valueName}
            onBlur={handleBlur}
            onChange={handleChange}
            value={currentValue}
            variant={textFieldVariant}
            color="primary"
            onClick={saveMessageCursorPosition}
            onKeyUp={(event) => {
              handleKeyUpCustomTextMessage(event);
            }}
            disabled={loading}
            rows={9}
            className={classNameTextField}
            fullWidth
            multiline
          />
          <div
            className={`${classes.textMessageAfter} flex`}
          >
            <div className="icon">
              <InfoIcon />
            </div>
            <div className="middleText">
              {`Maximum of ${maxTextMessageLength} characters`}
            </div>
            <div className="rightText">
              <div className="txt">
                Current characters:
              </div>
              {(currentValue || '').length}
            </div>
          </div>
          {/* <div
        className={`${classes.textMessageAfter} flex`}
      >
        <div className="middleText">
          <b>
            <a
              target="_blank"
              href="https://docs.google.com/document/d/1RRcCviRRLuo1EuW9Jo8tFT4X4rU0Hs3T7Uf6-X9lQ0A"
              rel="noopener noreferrer"
            >
              List of Dynamic Fields
            </a>
          </b>
        </div>
      </div> */}
        </Grid>
      </Grid>
    </>
  );
}

TextFieldEmoji.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  valueName: PropTypes.string.isRequired,
  currentValue: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  maxTextMessageLength: PropTypes.number.isRequired,
  defaultScreenSpacing: PropTypes.number.isRequired,
  errorValue: PropTypes.any,
  touchedValue: PropTypes.any,
  helpTextForTitle: PropTypes.node,
  helpTextForBlanksError: PropTypes.node,
  className: PropTypes.string,
  classNameTextField: PropTypes.string,
  showAddEmoji: PropTypes.bool,
  showAddDynamicField: PropTypes.bool,
  screenName: PropTypes.string
};

TextFieldEmoji.defaultProps = {
  errorValue: null,
  touchedValue: null,
  helpTextForTitle: null,
  helpTextForBlanksError: null,
  className: '',
  classNameTextField: 'textFieldRed',
  showAddEmoji: true,
  showAddDynamicField: true,
  screenName: null,
};

export default withStyles(TextFieldEmoji, textFieldEmojiStyle, { withTheme: true });
