import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover as PopoverDefault } from '@mui/material';

import { withStyles } from 'tss-react/mui';

import popoverStyle from 'src/components/Popover/popover.style';

function Popover({
  children,
  classes,
  className,
  text,
  useHover,
  useClick,
  anchorOrigin,
  transformOrigin,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isButtonEnter, setIsButtonEnter] = useState(false);
  const [isButtonClick, setIsButtonClick] = useState(false);
  const [isPopoverEnter, setIsPopoverEnter] = useState(false);

  const handleOnButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsButtonClick(true);
  };

  const handleOnButtonMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
    setIsButtonEnter(true);
  };

  const handleOnButtonMouseLeave = () => {
    setTimeout(() => {
      setIsButtonEnter(false);
    }, 1000);
  };

  const handleOnPopoverMouseEnter = () => {
    setIsPopoverEnter(true);
  };

  const handleOnPopoverMouseLeave = () => {
    setIsPopoverEnter(false);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setIsPopoverEnter(false);
    setIsButtonEnter(false);
    setIsButtonClick(false);
  };

  return (
    <>
      {React.cloneElement(children, {
        ...(useClick ? {
          onClick: handleOnButtonClick,
        } : {}),
        ...(useHover ? {
          onMouseEnter: handleOnButtonMouseEnter,
          onMouseLeave: handleOnButtonMouseLeave,
        } : {})
      })}
      <PopoverDefault
        className={`${classes.popover} ${className}`}
        classes={{
          paper: classes.paper,
        }}
        open={isButtonEnter || isPopoverEnter || isButtonClick}
        anchorEl={anchorEl}
        {...!useHover ? {
          anchorOrigin: { anchorOrigin }
        } : {}}
        transformOrigin={transformOrigin}
        onClose={handlePopoverClose}
        slotProps={{
          paper: {
            onMouseEnter: handleOnPopoverMouseEnter,
            onMouseLeave: handleOnPopoverMouseLeave
          }
        }}
      >
        {text}
      </PopoverDefault>
    </>
  );
}

Popover.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]).isRequired,
  className: PropTypes.string,
  useHover: PropTypes.bool,
  useClick: PropTypes.bool,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
};

Popover.defaultProps = {
  className: '',
  useHover: true,
  useClick: true,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  }
};

export default withStyles(Popover, popoverStyle, { withTheme: true });
