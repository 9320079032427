/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { SvgIcon, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { withStyles } from 'tss-react/mui';
import { createFilterOptions } from '@mui/material/Autocomplete';
import AutocompleteFieldFormik from 'src/components/AutocompleteFieldFormik';
import Tooltip from 'src/components/Tooltip';
import { TextRotateVertical } from '@mui/icons-material';
import { useFormik } from 'formik';

import helpers from 'src/helpers';

import selectDynamicFieldStyle from 'src/components/SelectDynamicField/selectDynamicField.style';

function SelectDynamicField({
  classes,
  addField,
  currentValue,
  screenName,
}) {
  const [openSelect, setOpenSelect] = useState(false);
  const elRef = useRef(null);

  const { user } = useSelector((state) => state.account);
  const { dynamicFields } = user;

  const cpDynamicFields = dynamicFields.filter((dynamicField) => (dynamicField?.screens?.includes('All') || dynamicField?.screens?.includes(screenName)) && dynamicField?.softwareName === 'Connection Point')
    .map((dynamicField) => (
      {
        label: dynamicField.description,
        value: dynamicField.value,
      }
    ));

  const pbDynamicFields = dynamicFields.filter((dynamicField) => (dynamicField?.screens?.includes('All') || dynamicField?.screens?.includes(screenName)) && dynamicField?.softwareName === 'Project Broadcast')
    .map((dynamicField) => (
      {
        label: dynamicField.description,
        value: dynamicField.value,
      }
    ));

  const initialValues = {
    cpDynamicField: null,
    pbDynamicField: null,
  };

  const filterOptions = createFilterOptions({
    stringify: ({
      label, value
    }) => `${label} ${value}`
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
  });

  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    setFieldTouched,
    setFieldValue,
    resetForm,
  } = formik;

  const shouldFieldBeClose = (e) => !helpers.hasParentClass(e.target, '.MuiAutocomplete-popper') && !helpers.hasParentClass(e.target, '.MuiChip-deleteIcon');

  const handleClickAnywhere = (e) => {
    if (elRef?.current && !elRef.current.contains(e.target) && shouldFieldBeClose(e)) {
      setOpenSelect(false);
      document.removeEventListener('click', handleClickAnywhere);
    }
  };

  const isOptionEqualToValue = (option, value) => value?.value && value?.value === option.value;

  const renderOptionAutocomplete = (props, option) => (
    <div {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
      <b>{option.label}</b>
      <div className={classes.optionValue}>
        {option.value}
      </div>
    </div>
  );

  const handleFocusFieldValidate = (event) => {
    helpers.formValidation.validateField(event, setFieldTouched);
  };

  const handleOnClickApply = () => {
    const dynamicField = values.cpDynamicField?.value || values.pbDynamicField?.value || null;
    if (dynamicField) {
      addField(dynamicField, currentValue);
    }
    setOpenSelect(false);
    resetForm();
  };

  const handleOnClickCancel = () => {
    resetForm();
  };

  const handleClick = () => {
    setOpenSelect(!openSelect);
    if (!openSelect) {
      document.addEventListener('click', handleClickAnywhere);
      return;
    }
    document.removeEventListener('click', handleClickAnywhere);
  };

  const handleClickApply = () => {
    handleOnClickApply();
  };

  const handleClickCancel = () => {
    handleOnClickCancel();
    setOpenSelect(false);
  };

  useEffect(() => () => document.removeEventListener('click', handleClickAnywhere), []);

  const cpListDisabled = !!values?.pbDynamicField?.value;

  const pbListDisabled = !!values?.cpDynamicField?.value;

  return (
    <div
      className={`${classes.root} withBtn inner`}
      ref={elRef}
    >
      <Tooltip
        title="Insert CP or PB Dynamic Field"
        placement="left-end"
      >
        <div
          className="divBtn"
          onClick={handleClick}
        >
          <div className="icon">
            <SvgIcon
              className="svgIcon"
              component={TextRotateVertical}
              viewBox="0 0 24 24"
            />
          </div>
        </div>
      </Tooltip>
      {openSelect && (
        <div className={classes.innerFieldDropDown}>
          <div className="buttonLine">
            <Button
              className="cancel"
              onClick={handleClickCancel}
            >
              Cancel
            </Button>
            <Button
              onClick={handleClickApply}
            >
              Insert
            </Button>
          </div>
          <div className="formBox">
            <Grid>
              <div className={classes.groupLabel}>
                Select Either a CP or PB Dynamic Field & Insert
              </div>
            </Grid>
            <Grid
              spacing={2}
              container
            >
              <Grid
                xs={12}
              >
                <AutocompleteFieldFormik
                  touchedValue={touched.cpDynamicField}
                  errorValue={errors.cpDynamicField}
                  name="cpDynamicField"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleFocus={handleFocusFieldValidate}
                  values={values.cpDynamicField}
                  filterOptions={filterOptions}
                  label="CP Dynamic Fields"
                  setFieldValue={setFieldValue}
                  options={cpDynamicFields || []}
                  renderOption={renderOptionAutocomplete}
                  isOptionEqualToValue={isOptionEqualToValue}
                  multiple={false}
                  disableCloseOnSelect={false}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  disabled={cpListDisabled}
                />
              </Grid>
              <Grid
                xs={12}
              >
                <AutocompleteFieldFormik
                  touchedValue={touched.pbDynamicField}
                  errorValue={errors.pbDynamicField}
                  name="pbDynamicField"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleFocus={handleFocusFieldValidate}
                  values={values.pbDynamicField}
                  filterOptions={filterOptions}
                  label="PB Dynamic Fields"
                  setFieldValue={setFieldValue}
                  options={pbDynamicFields || []}
                  renderOption={renderOptionAutocomplete}
                  isOptionEqualToValue={isOptionEqualToValue}
                  multiple={false}
                  disableCloseOnSelect={false}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  disabled={pbListDisabled}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
}

SelectDynamicField.propTypes = {
  classes: PropTypes.object.isRequired,
  addField: PropTypes.func.isRequired,
  currentValue: PropTypes.string.isRequired,
  screenName: PropTypes.string
};

SelectDynamicField.defaultProps = {
  screenName: 'Message Templates Edit',
};

export default withStyles(SelectDynamicField, selectDynamicFieldStyle, { withTheme: true });
