const fontFamily = 'Roboto';
const color = '#6200EE';

const popConfirmStyles = () => ({
  typography: {
    padding: '20px 20px 10px 20px',
    textAlign: 'center'
  },
  errorOutlineIcon: {
    verticalAlign: 'middle',
    width: 24,
    height: 24,
    marginTop: -1,
    marginRight: 10,
    color: 'red'
  },
  footer: {
    display: 'block',
    textAlign: 'center',
    padding: '5px 20px',

    '& button': {
      fontFamily,
      paddingTop: 4,
      paddingBottom: 4,
      margin: '0px 7px'
    },
    '&.bigPBFooter': {
      paddingBottom: 20,
    },
  },
  btnYes: {
    backgroundColor: color,
    color: 'white',
    '&:hover': {
      color,
      boxShadow: `0px 0px 0px 1px ${color}`
    }
  },
  btnNo: {
    boxShadow: '0px 0px 0px 1px #cccccc',

    '&.grey': {
      backgroundColor: '#BFBDC1',
      color: '#ffffff',
      '&:hover': {
        color,
        backgroundColor: '#ffffff',
        boxShadow: `0px 0px 0px 1px ${color}`
      }
    },
  }
});

export default popConfirmStyles;
