const pageViewStyle = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },

  relativeBox: {
    position: 'relative',
    minHeight: 150,
  },

});

export default pageViewStyle;
