import moment from 'moment';

export const ADD_NOTIFICATION = '@orders/add-notification';
export const REMOVE_NOTIFICATION = '@orders/remove-notification';

export const addNotification = (key, message, type) => ({
  type: ADD_NOTIFICATION,
  payload: {
    key: `${key}${moment().format('mm:ss')}`,
    message,
    type,
  }
});

export const removeNotification = (key) => ({
  type: REMOVE_NOTIFICATION,
  payload: key,
});
