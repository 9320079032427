import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import CircularProgress from '@mui/material/CircularProgress';

import spinnerStyle from 'src/components/Spinner/spinner.style';

function Spinner({
  classes,
  loading,
  positionType = 'default',
  zIndex = 'default',
  ...props
}) {
  return loading && (
  <div className={`${classes.root} ${positionType} ${zIndex}`}>
    <CircularProgress
      size={50}
      className="circularProgress"
      {...props}
    />
  </div>
  );
}

Spinner.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  positionType: PropTypes.oneOf([
    'absolute',
    'default',
  ]),
  zIndex: PropTypes.oneOf([
    'z1300',
    'default',
  ]),
};

export default withStyles(Spinner, spinnerStyle);
