import { STYLE_OPTIONS } from 'src/constants';

const fontFamily = 'Roboto';

const uploadImageStyle = {
  inputUpload: {
    display: 'block',
    opacity: 0,
    width: 0,
    height: 0,
    zIndex: -1000,
    overflow: 'hidden',
  },

  buttonUpload: {
    width: 208,
    height: 152,
    background: '#FFFFFF',
    border: '1px dashed #393939',
    boxSizing: 'border-box',
    borderRadius: 4,
    fontFamily,
    textTransform: 'none',

    '&.highlightsType1': {
      backgroundColor: STYLE_OPTIONS.COLOR_ACCOUNT_SETUP_FIELD_HIGHLIGHTS,
    },

    '& .MuiButton-label': {
      flexWrap: 'wrap',
      width: '100%',
      height: '100%',
      alignContent: 'center',
    },

    '& .buttonName': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '18px',
      color: '#393939',
      padding: '7px 10px',
      width: '100%',
      textAlign: 'center',
    },

    '& .buttonSmallText': {
      fontSize: 10,
      lineHeight: '14px',
      color: '#7A7A7A',
      padding: '0px 10px',
      width: '100%',
      textAlign: 'center',
    },

    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  buttonRemove: {
    display: 'inline-block',
    verticalAlign: 'top',
    padding: 0,
    margin: '0px 0px 0px 15px',
    minWidth: 0,
    minHeight: 0,
    color: '#f44336',
  },
  error: {
    fontFamily,
    fontSize: 12,
    color: '#f44336',
    paddingTop: 8,
  },
  disabledImageBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'url(/static/images/disabled-image.svg) 50% 50% / 100% 100% no-repeat rgba(0, 0, 0, 0.1)',
    opacity: '0.3',
  },
};

export default uploadImageStyle;
