import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import PopConfirm from 'src/components/PopConfirm';
import { makeStyles } from 'tss-react/mui';

import editModalFooter from 'src/views/management/OrderListView/EditOrder/editModalFooter.style';
import { modalButtonStyles } from 'src/views/management/OrderListView/EditOrder/buttons.style';

const useStyles = makeStyles()(modalButtonStyles);
const useModalStyles = makeStyles()(editModalFooter);

function EditModalFooter({
  onClose,
  onDelete,
  loading,
  okBtnText,
}) {
  const { classes } = useModalStyles();
  const { classes: classesButton } = useStyles();

  return (
    <div className={classes.footerWrapper}>
      {onDelete && (
        <PopConfirm
          onConfirm={onDelete}
        >
          <Button
            color="secondary"
            classes={classesButton}
            className="red"
            disabled={loading}
          >
            Delete
          </Button>
        </PopConfirm>
      )}
      <Button
        onClick={onClose}
        color="secondary"
        classes={classesButton}
      >
        Close
      </Button>
      <Button
        form="updateMessage"
        color="primary"
        type="primary"
        classes={classesButton}
        disabled={loading}
      >
        {okBtnText}
      </Button>
    </div>
  );
}

EditModalFooter.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  loading: PropTypes.bool,
  okBtnText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

EditModalFooter.defaultProps = {
  loading: false,
  okBtnText: 'Save Changes',
  onDelete: null,
};

export default EditModalFooter;
