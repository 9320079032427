import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { withStyles } from 'tss-react/mui';
import MessageBox from 'src/components/MessageBox';
import Page from 'src/components/Page';
import Header from 'src/components/View/Header';
import HelpAndInformationView from 'src/views/pages/HelpAndInformation/HelpAndInformationView';

import helpAndInformationStyles from 'src/views/pages/HelpAndInformation/helpAndInformation.style';

const BREADCRUMBS = [
  {
    link: '/',
    title: 'Settings'
  },
  {
    isCurrent: true,
    title: 'Help & Information'
  },
];

function HelpAndInformation({
  classes,
}) {
  const { primaryCompany } = useSelector((state) => state.account?.user || {});

  const messageForMessageBox = (
    <div className={classes.messageForMessageBox}>
      <Grid
        spacing={2}
        container
      >
        <Grid xs={12}>
          <div className="inner-message">
            Connection Point&#8482; Version 2.45
          </div>
          <div className="inner-message">
            © Connection Point, LLC
          </div>
          <div className="inner-message">
            <a
              target="_blank"
              href="https://drive.google.com/file/d/1-4JKQNnyGQrAT_J_8G5Nbuups4rVqyOR"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </div>
          <div className="inner-message">
            <a
              target="_blank"
              href="https://drive.google.com/file/d/101Y30SYGunh3-Q2_itNKhnSzMqeHuj52"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
          </div>
        </Grid>
        <Grid xs={12}>
          <div className="support-facebook">
            <b style={{ fontSize: 14 }}>Support Texting Number:</b>
            <b style={{ fontSize: 14 }}>&nbsp;&nbsp;&nbsp;317-680-8044</b>
            <br />
            <b>Facebook Group:&nbsp;&nbsp;&nbsp;</b>
            <a
              target="_blank"
              href="https://www.facebook.com/groups/ConnectionPoint"
              rel="noopener noreferrer"
            >
              www.facebook.com/groups/ConnectionPoint
            </a>
            <br />
            <br />
            <b style={{ fontSize: 14 }}>Live Interactive Zoom Class Schedule & Signup</b>
            :&nbsp;&nbsp;&nbsp;
            <br />
            Introduction Class:&nbsp;&nbsp;&nbsp;
            <a
              target="_blank"
              href="https://forms.gle/1MUvofSgT8TXRb758"
              rel="noopener noreferrer"
            >
              https://forms.gle/1MUvofSgT8TXRb758
            </a>
            <br />
            Party & Events Class:&nbsp;&nbsp;&nbsp;
            <a
              target="_blank"
              href="https://forms.gle/BXQnqVFzfL58xdDg8"
              rel="noopener noreferrer"
            >
              https://forms.gle/BXQnqVFzfL58xdDg8
            </a>
          </div>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Page
      className={classes.root}
      title="Help & Information"
    >
      <Container maxWidth={false}>
        <Header
          title="Help & Information"
          breadcrumbs={BREADCRUMBS}
          message={(
            <MessageBox
              message={messageForMessageBox}
              type="type-1"
              paddingTop="20"
              paddingBottom="0"
              visible
            />
          )}
        />
        {/* <Box mt={3}>
          <div>
            <p>
              <font color="#ff3333">
                <i>
                  <b>Note:</b>
                  {' '}
                  You must be signed in through your web browser to Google with the Gmail address
                  that you you have provided to Connection Point in order to be able to see the videos and documents below.
                  <br />
                  ALSO, if the videos aren't showing up, check to see if you are using an "anti-tracker" or "blocking" software, plug-in, or extension.
                  If so, you will need to add DSAMSoftware.com to your exemption list, so that it doesn't block Connection Point's videos & docs.
                  <br />
                  <br />
                  Try signing in through Chrome browser if Safari, Edge, Firefox, or other browser doesn't show the videos & guides below.
                </i>
              </font>
            </p>
          </div>
        </Box> */}
        <Box mt={3}>
          <HelpAndInformationView
            primaryCompany={primaryCompany}
          />
        </Box>
      </Container>
    </Page>
  );
}

HelpAndInformation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(HelpAndInformation, helpAndInformationStyles, { withTheme: true });
