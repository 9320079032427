import axios from 'src/utils/axios';
import helpers from 'src/helpers';
import { NOTIFICATION_STATUS } from 'src/constants';
import { addNotification } from 'src/actions/notificationActions';

export const GET_FOLLOW_UP = '@followups/get-follow-up';
export const GET_FOLLOW_UP_SUCCESS = '@followups/get-follow-up-success';
export const GET_FOLLOW_UP_ERROR = '@followups/get-follow-up-error';

export const UPDATE_FOLLOW_UP = '@followups/update-follow-up';
export const UPDATE_FOLLOW_UP_SUCCESS = '@followups/update-follow-up-success';
export const UPDATE_FOLLOW_UP_ERROR = '@followups/update-follow-up-error';

export const UPDATE_FOLLOW_UP_GUEST_MESSAGING = '@followups/update-follow-up-guest-messaging';
export const UPDATE_FOLLOW_UP_GUEST_MESSAGING_SUCCESS = '@followups/update-follow-up-guest-messaging-success';
export const UPDATE_FOLLOW_UP_GUEST_MESSAGING_ERROR = '@followups/update-follow-up-guest-messaging-error';

export const SET_FOLLOW_UP_DETAILS_TAB = '@followups/set-follow-up-details-tab';

export const SET_GO_TO_FOLLOW_UP_DETAILS = '@followups/set-go-to-follow-up-details';
export const CLEAR_GO_TO_FOLLOW_UP_DETAILS = '@followups/clear-go-to-follow-up-details';

export const SET_FORM_FOLLOW_UP_MESSAGE = '@followups/set-form-followup-message';

export const SET_VALUES_FOLLOW_UP_MESSAGE = '@followups/set-values-follow-up-message';

export const SET_IS_MODAL_LOADING = '@followups/set-modal-loading';
export const SET_IS_MODAL_VISIBLE = '@followups/set-modal-visible';
export const SET_MODAL_MESSAGE = '@followups/set-modal-message';

export const setIsModalVisible = () => ({
  type: SET_IS_MODAL_VISIBLE
});

export const setIsModalLoading = (loading) => ({
  type: SET_IS_MODAL_LOADING,
  payload: loading
});

export const setModalMessage = (isVisible, modalTitle, modalBody) => ({
  type: SET_MODAL_MESSAGE,
  payload: {
    isVisible,
    modalTitle,
    modalBody,
  },
});

export const getFollowUp = (followUpId) => async (dispatch) => {
  dispatch({
    type: GET_FOLLOW_UP,
  });

  try {
    const response = await axios.get(`/api/followUpMessages/${followUpId}`);
    dispatch({
      type: GET_FOLLOW_UP_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_FOLLOW_UP_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_FOLLOW_UP_ERROR,
    });
  }
};

export const updateFollowUp = (followUpId, messageChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch(setIsModalLoading(true));
    dispatch({
      type: UPDATE_FOLLOW_UP,
    });
    const response = await axios.put('/api/followUpMessages', {
      messageChanges,
    });
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(UPDATE_FOLLOW_UP_ERROR, 'Can not update follow-up', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: UPDATE_FOLLOW_UP_ERROR,
      });
      return;
    }

    await dispatch(getFollowUp(followUpId));
    dispatch(addNotification(UPDATE_FOLLOW_UP_SUCCESS, 'The follow-up has been successfully updated', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: UPDATE_FOLLOW_UP_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};

    const { message: errorMessage } = error || {};
    dispatch(addNotification(UPDATE_FOLLOW_UP_ERROR, message || errorMessage || 'Message didn\'t update', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: UPDATE_FOLLOW_UP_ERROR,
    });
    dispatch(setIsModalLoading(false));

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setFollowUpDetailsTab = (tabName) => async (dispatch) => {
  dispatch({
    type: SET_FOLLOW_UP_DETAILS_TAB,
    payload: tabName
  });
};

export const setFormFollowUpMessage = (payload) => ({
  type: SET_FORM_FOLLOW_UP_MESSAGE,
  payload
});

export const setValuesFollowUpMessage = (values) => ({
  type: SET_VALUES_FOLLOW_UP_MESSAGE,
  payload: values
});
