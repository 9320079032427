import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import helpers from 'src/helpers';

import googleVideoStyle from 'src/components/GoogleVideo/googleVideo.style';

function GoogleVideo({
  classes,
  title,
  width,
  height,
  allowFullScreen,
  link,
  positionsY,
}) {
  if (helpers.isEmpty(link)) {
    return null;
  }

  const getSrc = link.replace(/\/$/gui, '').replace(/preview$/gui, '').replace(/\/$/gui, '');

  return (
    <iframe
      className={`${classes.iframe} ${positionsY}`}
      title={title}
      src={`${getSrc}/preview`}
      width={width}
      height={height}
      allowFullScreen={allowFullScreen}
    />
  );
}

GoogleVideo.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  link: PropTypes.string,
  positionsY: PropTypes.oneOf([
    'center',
    'left',
  ]),
  allowFullScreen: PropTypes.bool,
};

GoogleVideo.defaultProps = {
  link: '',
  title: 'Video file',
  width: '400',
  height: '300',
  positionsY: 'center',
  allowFullScreen: true,
};

export default withStyles(GoogleVideo, googleVideoStyle);
