/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
// import { withStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import ModalDialog from 'src/components/ModalDialog/index';
import ChangeCampaignsForm from 'src/containers/ChangeCampaigns/ChangeCampaignsForm';
// import changeCampaignsStyle from 'src/containers/ChangeCampaigns/changeCampaigns.style';

function ChangeCampaigns({
  // classes,
  logout,
}) {
  const {
    campaignNamesToChange,
    newUpdatedCampaignsList,
    updateCampaignLoading: loading,
  } = useSelector((state) => state.account) || {};

  const okButtonProps = {
    form: 'formChangeCampaigns',
    type: 'primary',
  };

  const handleCloseModal = () => {
    logout();
  };

  return campaignNamesToChange && campaignNamesToChange.length > 0 && (
    <ModalDialog
      title="Please Update Your Campaign Settings"
      onClose={handleCloseModal}
      onOk={() => { }}
      okButtonProps={okButtonProps}
      content={(
        <ChangeCampaignsForm
          // classes={classes}
          campaignNamesToChange={campaignNamesToChange}
          currentCampaigns={newUpdatedCampaignsList}
          loading={loading}
        />
      )}
      closeText="Log Out"
      okText="Update"
      loading={loading}
      isCloseIconHid
      isVisible
      closeOnClickOnlyTheButton
      largeModal
    />
  );
}

ChangeCampaigns.propTypes = {
  // classes: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

ChangeCampaigns.defaultProps = {};

// export default withStyles(ChangeCampaigns, changeCampaignsStyle);
export default ChangeCampaigns;
