const editModalFooter = (theme) => ({
  footerWrapper: {
    width: '100%',
    padding: '34px 0px 40px 0px',
    textAlign: 'center',

    '& button': {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: 15,
      marginLeft: 15
    },

    [theme.breakpoints.down('md')]: {
      padding: '30px 0px 20px 0px',
      '& button': {
        marginBottom: 20
      }
    },

    [theme.breakpoints.down('sm')]: {
      padding: '30px 17px 20px 17px',
      '& button': {
        marginRight: 0,
        marginLeft: 0,
        width: '100%',
      }
    }
  }
});

export default editModalFooter;
