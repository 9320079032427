// import axios from 'src/utils/axios';
// import { addNotification } from 'src/actions/notificationActions';
// import { getAccountInfo } from 'src/actions/accountActions';
// import { NOTIFICATION_STATUS } from 'src/constants';

export const SET_LOADING = '@followUp/set-loading';
export const SET_CURRENT_NAVIGATION_TAB = '@followUp/set-current-navigation-tab';

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading
});

// export const setFormPartySettingsTabGeneral = (formPartySettingsTabGeneral) => ({
//   type: SET_FORM_PARTY_SETTINGS_TAB_GENERAL,
//   payload: formPartySettingsTabGeneral
// });

export const setCurrentNavigationTab = (tabName) => ({
  type: SET_CURRENT_NAVIGATION_TAB,
  payload: tabName
});
