import React from 'react';
import Fade from '@mui/material/Fade';
import { SnackbarProvider } from 'notistack';
import Notifications from 'src/containers/Notification/Notifications';

function Notification() {
  return (
    <SnackbarProvider
      maxSnack={4}
      autoHideDuration={4000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      TransitionComponent={Fade}
    >
      <Notifications />
    </SnackbarProvider>
  );
}

export default Notification;
