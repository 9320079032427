import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
// Avatar,
  Box, ButtonBase, Hidden, Menu, MenuItem, Typography
} from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

function Account({ logout }) {
  const { classes } = useStyles();
  const ref = useRef(null);
  const { user } = useSelector((state) => state.account);
  const [isOpen, setOpen] = useState(false);
  // const isPageBlocked = helpers.isPageBlocked(user);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    logout(handleClose);
  };

  return (
    <>
      <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleOpen} ref={ref}>
        {/* <Avatar
      alt="User"
      className={classes.avatar}
      src={user.avatar}
    /> */}
        <Hidden mdDown>
          <Typography variant="h6" color="inherit">
            {`${user?.firstName || ''} ${user?.lastName || ''}`}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        anchorEl={ref.current}
        open={isOpen}
      >
        {/* <MenuItem
      component={RouterLink}
      to="/app/social/profile"
    >
      Profile
    </MenuItem> */}
        <MenuItem
          component={RouterLink}
          to="/app/account"
          onClick={handleClose}
        >
          Account
        </MenuItem>
        <MenuItem
          onClick={handleLogout}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

Account.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default Account;
