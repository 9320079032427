/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import ModalDialog from 'src/components/ModalDialog/index';
import AdminSignInAsForm from 'src/containers/AdminSignInAs/AdminSignInAsForm';
import { getUsersList } from 'src/actions/accountActions';

import adminSignInAsStyle from 'src/containers/AdminSignInAs/adminSignInAs.style';
import helpers from 'src/helpers';

function AdminSignInAs({
  classes,
  logout,
}) {
  const dispatch = useDispatch();
  const { user, usersList } = useSelector((state) => state.account);
  const isAdmin = helpers.isUserAdmin(user);

  const okButtonProps = {
    form: 'formAdminSignInAs',
    type: 'primary',
  };

  const handleCloseModal = () => {
    logout();
  };

  useEffect(() => {
    if (isAdmin) {
      dispatch(getUsersList());
    }
  }, [dispatch]);

  return isAdmin && (
    <ModalDialog
      title="Sign-In As"
      onClose={handleCloseModal}
      onOk={() => {}}
      okButtonProps={okButtonProps}
      content={(
        <AdminSignInAsForm
          classes={classes}
          usersList={usersList}
        />
      )}
      closeText="Log Out"
      okText="Sign-In As"
      loading={usersList?.loading}
      isVisible
      closeOnClickOnlyTheButton
      largeModal
    />
  );
}

AdminSignInAs.propTypes = {
  classes: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

AdminSignInAs.defaultProps = {};

export default withStyles(AdminSignInAs, adminSignInAsStyle);
