import React from 'react';
import PropTypes from 'prop-types';
import TextFieldNullOption from 'src/components/TextFieldFormik/TextFieldNullOption';
import helpers from 'src/helpers';

function DefaultSelectOptions({
  values,
  value,
  ifNullAddEmpty,
  emptyText,
  addNullAnyway,
  isNullValueDisabled,
}) {
  return (
    <>
      <TextFieldNullOption
        value={value}
        values={values}
        emptyText={emptyText}
        addNullAnyway={addNullAnyway}
        ifNullAddEmpty={ifNullAddEmpty}
        isNullValueDisabled={isNullValueDisabled}
      />
      {helpers.transform.toArray(values).map((item) => (
        <option
          key={item}
          value={item}
        >
          {item}
        </option>
      ))}
    </>
  );
}

DefaultSelectOptions.propTypes = {
  value: PropTypes.string,
  values: PropTypes.array,
  ifNullAddEmpty: PropTypes.bool,
  emptyText: PropTypes.string,
  addNullAnyway: PropTypes.bool,
  isNullValueDisabled: PropTypes.bool,
};

TextFieldNullOption.defaultProps = {
  value: null,
  values: [],
  ifNullAddEmpty: true,
  addNullAnyway: true,
  emptyText: ' ',
  isNullValueDisabled: false,
};

export default DefaultSelectOptions;
