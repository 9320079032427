import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import helpers from 'src/helpers';

import vimeoVideoStyle from './vimeoVideo.style';

function VimeoVideo({
  classes,
  title,
  width,
  height,
  allowFullScreen,
  link,
  positionsY,
}) {
  if (helpers.isEmpty(link)) {
    return null;
  }

  return (
    <iframe
      className={`${classes.iframe} ${positionsY}`}
      title={title}
      // src={`${getSrc}/preview`}
      src={link}
      width={width}
      height={height}
      allowFullScreen={allowFullScreen}
    />
  );
}

VimeoVideo.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  link: PropTypes.string,
  positionsY: PropTypes.oneOf([
    'center',
    'left',
  ]),
  allowFullScreen: PropTypes.bool,
};

VimeoVideo.defaultProps = {
  link: '',
  title: 'Video file',
  // width: '400',
  // height: '300',
  width: '750',
  height: '400',
  positionsY: 'center',
  allowFullScreen: true,
};

export default withStyles(VimeoVideo, vimeoVideoStyle);
