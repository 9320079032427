import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import HelpCircle from 'src/components/HelpCircle/index';

import helpCircleWithContainerStyle from 'src/components/HelpCircle/helpCircleWithContainer.style';

function HelpCircleWithContainer({
  children,
  classes,
  positionX,
  positionY,
  showInfo,
  marginLeft,
  sizeType,
  inlineFlex,
  color,
}) {
  return (
    <div className={`${classes.root} ${positionX} ${positionY} ${inlineFlex ? 'inlineFlex' : ''}`}>
      <div className="text">
        {children}
      </div>
      {showInfo && (
      <div className={color === 'red' ? 'circleRed' : color === 'purple' ? 'circlePurple' : 'circle'}>
        <HelpCircle
          showInfo={showInfo}
          marginLeft={marginLeft}
          sizeType={sizeType}
        />
      </div>
      )}
    </div>
  );
}

HelpCircleWithContainer.propTypes = {
  children: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  positionX: PropTypes.oneOf([
    'right',
    'beforeText',
    'afterText',
  ]),
  positionY: PropTypes.oneOf([
    'center',
    'centerTop4Plus',
    'top17',
  ]),
  showInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.bool,
  ]),
  marginLeft: PropTypes.oneOf([
    'default',
    '19',
    '5',
    '0',
  ]),
  sizeType: PropTypes.oneOf([
    'default',
    '15',
  ]),
  inlineFlex: PropTypes.bool,
  color: PropTypes.string,
};

HelpCircleWithContainer.defaultProps = {
  showInfo: false,
  positionX: 'afterText',
  positionY: 'center',
  marginLeft: 'default',
  sizeType: 'default',
  inlineFlex: false,
  color: null,
};

export default withStyles(HelpCircleWithContainer, helpCircleWithContainerStyle);
