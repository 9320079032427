import {
  NAV_LABEL_OPTIONS,
  ROUTES
} from 'src/constants';
import {
  Folder as FolderIcon,
  Users as UsersIcon
} from 'react-feather';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';

const navConfig = (logout) => ([
  {
    subheader: 'View & Manage',
    items: [
      {
        title: 'Order Follow-Up',
        icon: FolderIcon,
        href: '/app/management/followUp'
      },
      {
        title: 'Parties & Events',
        icon: EventAvailableOutlinedIcon,
        href: '/app/management/parties',
        // betaOnly: true,
      },
      {
        title: 'Contacts',
        icon: UsersIcon,
        href: '/app/management/customers'
      }
    ]
  },
  {
    subheader: 'Settings',
    href: '/app/pages',
    items: [
      {
        title: 'Order Follow-Up Settings',
        href: '/app/order-settings',
        icon: FolderIcon,
        isShownWhenOtherHidden: true,
      },
      {
        title: 'Parties & Events Settings',
        href: '/app/party-settings',
        icon: SettingsApplicationsOutlinedIcon,
        isShownWhenOtherHidden: true,
        // betaOnly: true,
      },
      {
        title: 'Message Templates',
        href: '/app/settings/messages',
        icon: MessageOutlinedIcon,
      },
      {
        title: 'Account & General Settings',
        href: '/app/account',
        icon: SettingsIcon,
        isShownWhenOtherHidden: true,
      },
    ]
  },
  {
    subheader: 'Help & Information',
    isLink: true,
    href: ROUTES.APP_HELP_AND_INFORMATION,
    icon: HelpOutlineIcon,
    isShownWhenOtherHidden: true,
    items: []
  },
  {
    subheader: NAV_LABEL_OPTIONS.LOGOUT,
    isLink: true,
    href: '/',
    click: logout,
    icon: ExitToAppIcon,
    isShownWhenOtherHidden: true,
    items: []
  },
]);

export default navConfig;
