import React from 'react';

const helpTextForFields = {
  systemGenMessage: (
    <>
      <b>Change this message to whatever you'd like your customer to receive when you apply this template inside Connection Point.</b>
      <br />
      <br />
      {'You can use PB dynamic fields with curly braces, such as {{firstName}}'}
      <br />
      <br />
      You can also use Connection Point Dynamnic Fields using square brackets [ ]
      <br />
      <br />
      Click on the insert Dynamic Fields button to the right.
    </>
  ),
  headerTitleMessages: (
    <>
      These are your templates to create and modify however you want for use on various Connection Point screens when sending a text.
    </>
  ),
};

export default helpTextForFields;
