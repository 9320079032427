/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_SMART_ACTION_GROUPS,
  GET_SMART_ACTION_GROUPS_SUCCESS,
  GET_SMART_ACTION_GROUPS_ERROR,
  SET_SMART_ACTION_GROUPS_QUERY,
  SET_SELECTED_SMART_ACTION_GROUPS,
  SET_BACK_TO_SMART_ACTION_GROUP_LIST,
  SET_SMART_ACTIONS_PARTY_TYPE,
} from 'src/actions/smartActionGroupsActions';

const query = {
  search: '',
  status: 'all',
  sort: [['code', 'asc']],
  count: 0,
};

const backToSmartActionGroupList = {
  smartActionGroups: [],
  query: {},
  active: false,
  comeBack: false,
};

const initialState = {
  partyTypeId: null,
  smartActionGroups: [],
  selectedSmartActionGroups: [],
  loading: false,
  query,
  backToSmartActionGroupList,
};

const smartActionGroupsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SMART_ACTION_GROUPS: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_SMART_ACTION_GROUPS_SUCCESS: {
      return produce(state, (draft) => {
        draft.smartActionGroups = payload?.smartActionGroups || [];
        draft.query.count = payload?.count || 0;
        draft.loading = false;
        draft.selectedSmartActionGroups = [];
      });
    }

    case GET_SMART_ACTION_GROUPS_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_SMART_ACTION_GROUPS_QUERY: {
      return produce(state, (draft) => {
        draft.query = payload ? {
          ...state.query,
          ...payload
        } : initialState.query;
      });
    }

    case SET_SELECTED_SMART_ACTION_GROUPS: {
      return produce(state, (draft) => {
        draft.selectedSmartActionGroups = payload || [];
      });
    }

    case SET_BACK_TO_SMART_ACTION_GROUP_LIST: {
      return produce(state, (draft) => {
        draft.backToSmartActionGroupList = payload ? {
          ...state.backToSmartActionGroupList,
          ...payload,
        } : backToSmartActionGroupList;
      });
    }

    case SET_SMART_ACTIONS_PARTY_TYPE: {
      return produce(state, (draft) => {
        draft.partyTypeId = payload || [];
      });
    }

    default: {
      return state;
    }
  }
};

export default smartActionGroupsReducer;
