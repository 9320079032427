/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_LOADING,
  SET_CURRENT_NAVIGATION_TAB,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  GET_ORDER_ERROR,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_ERROR, CLEAR_ORDER_WAS_DELETED,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_ERROR,
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_ERROR,
  SET_ORDER_DETAILS_TAB,
  SET_FORM_CREATE_UPDATE_ORDER,
  SET_ORDER_ITEMS,
  GET_ORDER_ITEMS,
  GET_ORDER_ITEMS_SUCCESS,
  GET_ORDER_ITEMS_ERROR,
  SET_ORDER_ITEMS_QUERY,
  SET_SELECTED_ORDER_ITEMS,
  SET_VISIBLE_MODAL_FORM_NUMBER,
  SET_VALUES_FORM_ADD_ORDER_ITEM,
  SEND_ADD_ORDER_ITEM,
  SEND_ADD_ORDER_ITEM_ERROR,
  SEND_ADD_ORDER_ITEM_SUCCESS,
  SEND_REMOVE_ORDER_ITEMS,
  SEND_REMOVE_ORDER_ITEMS_ERROR,
  SEND_REMOVE_ORDER_ITEMS_SUCCESS,
  GET_ITEMS_LIST,
  GET_ITEMS_LIST_SUCCESS,
  GET_ITEMS_LIST_ERROR,
} from 'src/actions/orderDetailsActions';

const initialNavigationTab = 'details';

const goToOrderDetails = {
  orderId: null,
  wasReturned: false,
  query: {},
};

const formAddOrderItem = {
  loading: false,
  guest: '',
};

const formRemoveOrderItems = {
  loading: false,
};

const initialState = {
  order: {},
  selectedOrderItems: [],
  // selectedItems: [],
  query: {
    sort: {
      table2: 'contact',
      column1: 'lastName',
      column2: 'firstName',
      direction1: 'asc',
      direction2: 'asc',
    }
  },
  orderItemsQuery: {
    sort: {
      table2: 'orderDetail',
      column1: 'skuDescription',
      column2: null,
      direction1: 'asc',
      direction2: null,
    }
  },
  orderWasDeleted: false,
  loading: false,
  currentNavigationTab: initialNavigationTab,
  goToOrderDetails,
  formEditOrder: {},
  orderItems: [],
  orderItemsCount: 0,
  visibleModalFormNumber: null,
  formAddOrderItem,
  formRemoveOrderItems,
  // currentDateTime: new Date(),
};

const orderDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING: {
      return produce(state, (draft) => {
        draft.loading = payload;
      });
    }

    case SET_CURRENT_NAVIGATION_TAB: {
      return produce(state, (draft) => {
        draft.currentNavigationTab = payload || initialNavigationTab;
      });
    }

    case GET_ORDER: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_ORDER_SUCCESS: {
      return produce(state, (draft) => {
        draft.order = payload?.order || {};
        draft.loading = false;
      });
    }

    case GET_ORDER_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case DELETE_ORDER: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case DELETE_ORDER_SUCCESS: {
      return produce(state, (draft) => {
        draft.order = payload ? initialState.order : state.order;
        draft.loading = false;
        draft.orderWasDeleted = true;
      });
    }

    case DELETE_ORDER_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case CLEAR_ORDER_WAS_DELETED: {
      return produce(state, (draft) => {
        draft.orderWasDeleted = false;
      });
    }

    case CREATE_ORDER:
    case UPDATE_ORDER: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case CREATE_ORDER_SUCCESS:
    case CREATE_ORDER_ERROR:
    case UPDATE_ORDER_SUCCESS:
    case UPDATE_ORDER_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_ORDER_DETAILS_TAB: {
      return produce(state, (draft) => {
        draft.currentNavigationTab = payload || initialState.currentNavigationTab;
      });
    }

    case SET_FORM_CREATE_UPDATE_ORDER: {
      return produce(state, (draft) => {
        draft.formEditOrder = payload ? {
          ...state.formEditOrder,
          ...payload,
        } : initialState.formEditOrder;
      });
    }

    case SET_ORDER_ITEMS: {
      return produce(state, (draft) => {
        draft.orderItems = payload || [];
        draft.selectedOrderItems = [];
        // draft.selectedContacts = [];
        // draft.selectedOrders = [];
      });
    }

    case GET_ORDER_ITEMS: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_ORDER_ITEMS_SUCCESS: {
      return produce(state, (draft) => {
        draft.orderItems = payload?.orderItems || {};
        draft.orderItemsCount = payload?.count;
        draft.loading = false;
      });
    }

    case GET_ORDER_ITEMS_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_ORDER_ITEMS_QUERY: {
      return produce(state, (draft) => {
        draft.orderItemsQuery = payload ? {
          ...state.orderItemsQuery,
          ...payload
        } : initialState.orderItemsQuery;
      });
    }

    case SET_SELECTED_ORDER_ITEMS: {
      return produce(state, (draft) => {
        draft.selectedOrderItems = payload || [];
      });
    }

    case SET_VISIBLE_MODAL_FORM_NUMBER: {
      return produce(state, (draft) => {
        draft.visibleModalFormNumber = payload || null;
      });
    }

    case SET_VALUES_FORM_ADD_ORDER_ITEM: {
      return produce(state, (draft) => {
        draft.formAddOrderItem = payload ? {
          ...state.formAddOrderItem,
          ...payload,
        } : formAddOrderItem;
      });
    }

    case SEND_ADD_ORDER_ITEM: {
      return produce(state, (draft) => {
        draft.formAddOrderItem.loading = true;
      });
    }

    case SEND_ADD_ORDER_ITEM_SUCCESS:
    case SEND_ADD_ORDER_ITEM_ERROR: {
      return produce(state, (draft) => {
        draft.formAddOrderItem.loading = false;
      });
    }

    case SEND_REMOVE_ORDER_ITEMS: {
      return produce(state, (draft) => {
        draft.formRemoveOrderItems.loading = true;
      });
    }

    case SEND_REMOVE_ORDER_ITEMS_SUCCESS:
    case SEND_REMOVE_ORDER_ITEMS_ERROR: {
      return produce(state, (draft) => {
        draft.formRemoveOrderItems.loading = false;
      });
    }

    case GET_ITEMS_LIST: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_ITEMS_LIST_SUCCESS: {
      return produce(state, (draft) => {
        draft.orders = payload || {};
        draft.loading = false;
      });
    }

    case GET_ITEMS_LIST_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default orderDetailsReducer;
