import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import {
  changeCompanyTitle,
} from 'src/actions/accountActions';
import helpers from 'src/helpers';
import CardChangeCompanyTitle from 'src/containers/ChangeCompanyTitle/CardChangeCompanyTitle';
import * as Yup from 'yup';

function ChangeCompanyTitleForm({
  classes,
  companyTitles,
  loading,
}) {
  const dispatch = useDispatch();

  const initialValues = {
    companyTitle: '',
  };

  const validationSchema = Yup.object().shape({
    companyTitle: Yup.string()
      .typeError('Choose from drop-down list')
      .min(1, 'Choose from drop-down list')
      .required('Company Title is required'),
  });

  const onSubmit = async (values, {
    resetForm,
    setErrors,
    setStatus,
    setSubmitting
  }) => {
    const doWhenTry = () => {
      resetForm();
      setStatus({ success: true });
      setSubmitting(false);
    };
    const doWhenCatch = (error) => {
      setStatus({ success: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    };

    const sendData = {
      companyTitle: helpers.transform.stringToNull(helpers.transform.stringFromObjectLabelValue(values.companyTitle)),
    };

    dispatch(changeCompanyTitle(sendData, doWhenTry, doWhenCatch));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const {
    handleSubmit,
    setFieldTouched,
  } = formik;

  const handleFocusFieldValidate = (event) => {
    helpers.formValidation.validateField(event, setFieldTouched);
  };

  return (
    <form
      id="formChangeCompanyTitle"
      className={classes.form}
      onSubmit={handleSubmit}
    >
      <CardChangeCompanyTitle
        formik={formik}
        classes={classes}
        loading={loading}
        companyTitles={companyTitles}
        handleFocusFieldValidate={handleFocusFieldValidate}
      />
    </form>
  );
}

ChangeCompanyTitleForm.propTypes = {
  classes: PropTypes.object.isRequired,
  companyTitles: PropTypes.array,
  loading: PropTypes.bool,
};

ChangeCompanyTitleForm.defaultProps = {
  companyTitles: [],
  loading: false,
};

export default ChangeCompanyTitleForm;
