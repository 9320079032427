import axios from 'src/utils/axios';
import helpers from 'src/helpers';
import { NOTIFICATION_STATUS } from 'src/constants';
import { addNotification } from 'src/actions/notificationActions';

export const SET_LOADING = '@orders/set-loading';
export const SET_CURRENT_NAVIGATION_TAB = '@orderDetail/set-current-navigation-tab';

export const GET_ORDER = '@orders/get-order';
export const GET_ORDER_SUCCESS = '@orders/get-order-success';
export const GET_ORDER_ERROR = '@orders/get-order-error';

export const DELETE_ORDER = '@orders/del-order';
export const DELETE_ORDER_SUCCESS = '@orders/del-order-success';
export const DELETE_ORDER_ERROR = '@orders/del-order-error';
export const CLEAR_ORDER_WAS_DELETED = '@orders/clear-order-was-deleted';

export const UPDATE_ORDER = '@orders/update-order';
export const UPDATE_ORDER_SUCCESS = '@orders/update-order-success';
export const UPDATE_ORDER_ERROR = '@orders/update-order-error';

export const CREATE_ORDER = '@orders/create-order';
export const CREATE_ORDER_SUCCESS = '@orders/create-order-success';
export const CREATE_ORDER_ERROR = '@orders/create-order-error';

export const SET_ORDER_DETAILS_TAB = '@orders/set-order-details-tab';

export const SET_GO_TO_ORDER_DETAILS = '@orders/set-go-to-order-details';
export const CLEAR_GO_TO_ORDER_DETAILS = '@orders/clear-go-to-order-details';

export const SET_FORM_CREATE_UPDATE_ORDER = '@orders/set-form-create-update-order';

export const SEND_ADD_ORDER_ITEM = '@orders/send-add-order-item';
export const SEND_ADD_ORDER_ITEM_SUCCESS = '@orders/send-add-order-item-success';
export const SEND_ADD_ORDER_ITEM_ERROR = '@orders/send-add-order-item-error';

export const SEND_REMOVE_ORDER_ITEMS = '@orders/send-remove-order-item';
export const SEND_REMOVE_ORDER_ITEMS_SUCCESS = '@orders/send-remove-order-item-success';
export const SEND_REMOVE_ORDER_ITEMS_ERROR = '@orders/send-remove-order-item-error';

export const GET_ITEMS_LIST = '@account/get-contacts-list';
export const GET_ITEMS_LIST_SUCCESS = '@account/get-contacts-list-success';
export const GET_ITEMS_LIST_ERROR = '@account/get-contacts-list-error';

export const GET_ORDERS_LIST = '@account/get-orders-list';
export const GET_ORDERS_LIST_SUCCESS = '@account/get-orderss-list-success';
export const GET_ORDERS_LIST_ERROR = '@account/get-orders-list-error';

export const SET_ORDER_GUESTS = '@orders/set-order-guests';
export const GET_ORDER_GUESTS = '@orders/get-order-guests';
export const GET_ORDER_GUESTS_SUCCESS = '@orders/get-order-guests-success';
export const GET_ORDER_GUESTS_ERROR = '@orders/get-order-guests-error';

export const SET_ORDER_GUESTS_QUERY = '@orders/set-order-guests-query';

export const SET_ORDER_ITEMS = '@orders/set-order-items';
export const GET_ORDER_ITEMS = '@orders/get-order-items';
export const GET_ORDER_ITEMS_SUCCESS = '@orders/get-order-items-success';
export const GET_ORDER_ITEMS_ERROR = '@orders/get-order-items-error';

export const SET_ORDER_ITEMS_QUERY = '@orders/set-order-items-query';

export const SET_VALUES_FORM_ADD_ORDER_ITEM = '@orders/set-values-form-add-order-item';

export const SET_SELECTED_ORDER_ITEMS = '@orders/set-selected-order-items';
export const SET_SELECTED_ORDERS = '@orders/set-selected-orders';

export const SET_VISIBLE_MODAL_FORM_NUMBER = '@orders/set-visible-modal-form-number';

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading
});

export const setCurrentNavigationTab = (tabName) => ({
  type: SET_CURRENT_NAVIGATION_TAB,
  payload: tabName
});

export const getOrder = (orderId) => async (dispatch) => {
  dispatch({
    type: GET_ORDER,
  });
  try {
    const response = await axios.get(`/api/orders/${orderId}`);
    dispatch({
      type: GET_ORDER_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_ORDER_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_ORDER_ERROR,
    });
  }
};

export const deleteOrder = (orderId) => async (dispatch) => {
  dispatch({
    type: DELETE_ORDER,
  });
  try {
    const response = await axios.delete(`/api/orders/${orderId}`);
    const payload = response?.data?.success;
    dispatch({
      type: DELETE_ORDER_SUCCESS,
      payload,
    });
    if (!payload) {
      dispatch(getOrder(orderId));
      dispatch(addNotification(DELETE_ORDER_ERROR, `Cannot delete order ${orderId}`, NOTIFICATION_STATUS.ERROR));
      return;
    }
    dispatch(addNotification(DELETE_ORDER_SUCCESS, 'Order was deleted', NOTIFICATION_STATUS.SUCCESS));
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(DELETE_ORDER_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch(getOrder(orderId));
    dispatch({
      type: DELETE_ORDER_ERROR,
    });
  }
};

export const clearOrderWasDeleted = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ORDER_WAS_DELETED,
  });
};

export const updateOrder = (orderId, orderChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_ORDER,
    });
    const response = await axios.post('/api/orders/updateOrder', {
      orderChanges,
    });
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(UPDATE_ORDER_ERROR, 'Cannot update order', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: UPDATE_ORDER_ERROR,
      });
      return;
    }

    await dispatch(getOrder(orderId));
    dispatch(addNotification(UPDATE_ORDER_SUCCESS, 'The order has been successfully updated', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: UPDATE_ORDER_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(UPDATE_ORDER_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: UPDATE_ORDER_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const createOrder = (orderChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_ORDER,
    });
    const response = await axios.post('/api/orders/create', {
      orderChanges,
    });
    const {
      id,
    } = response?.data || {};

    if (!id) {
      dispatch(addNotification(CREATE_ORDER_ERROR, 'Cannot create order', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: CREATE_ORDER_ERROR,
      });
      return;
    }

    dispatch(addNotification(CREATE_ORDER_SUCCESS, 'Your order has been successfully created', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: CREATE_ORDER_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry(id);
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(CREATE_ORDER_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: CREATE_ORDER_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setOrderDetailsTab = (tabName) => async (dispatch) => {
  dispatch({
    type: SET_ORDER_DETAILS_TAB,
    payload: tabName
  });
};

export const setGoToOrderDetails = (orderId, wasReturned = false, query = false) => async (dispatch) => {
  dispatch({
    type: SET_GO_TO_ORDER_DETAILS,
    payload: {
      orderId,
      wasReturned,
      ...query ? { query } : {},
    },
  });
};

export const clearGoToOrderDetails = (all = false) => async (dispatch) => {
  dispatch({
    type: CLEAR_GO_TO_ORDER_DETAILS,
    payload: all,
  });
};

export const setFormCreateUpdateOrder = (payload) => ({
  type: SET_FORM_CREATE_UPDATE_ORDER,
  payload
});

export const getOrderItems = () => async (dispatch, getState) => {
  dispatch({
    type: GET_ORDER_ITEMS,
  });

  const params = {
    sort: getState().orderDetails.orderItemsQuery.sort,
    screen: 'order',
  };

  try {
    const response = await axios.post(`/api/orders/${getState().orderDetails.order.id}/items`, { params });
    dispatch({
      type: GET_ORDER_ITEMS_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_ORDER_ITEMS_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_ORDER_ITEMS_ERROR,
    });
  }
};

export const setOrderItems = (OrderItems) => ({
  type: SET_ORDER_ITEMS,
  payload: OrderItems,
});

export const setOrderItemsQuery = (query) => ({
  type: SET_ORDER_ITEMS_QUERY,
  payload: query
});

export const setSelectedOrderItems = (selectedOrderItems) => ({
  type: SET_SELECTED_ORDER_ITEMS,
  payload: selectedOrderItems
});

export const setSelectedOrders = (selectedOrders) => ({
  type: SET_SELECTED_ORDERS,
  payload: selectedOrders
});

export const setVisibleModalFormNumber = (modalNumber) => ({
  type: SET_VISIBLE_MODAL_FORM_NUMBER,
  payload: modalNumber
});

export const setValuesFormAddOrderItem = (values) => ({
  type: SET_VALUES_FORM_ADD_ORDER_ITEM,
  payload: values
});

export const sendAddOrderItem = (addOrderItems, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_ADD_ORDER_ITEM,
    });
    const response = await axios.put('/api/orders/addorderitems', { addOrderItems });
    const { success } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SEND_ADD_ORDER_ITEM_ERROR, 'Try again later', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SEND_ADD_ORDER_ITEM_ERROR,
      });
      return;
    }

    dispatch(addNotification(SEND_ADD_ORDER_ITEM_SUCCESS, 'The item has been added.', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: SEND_ADD_ORDER_ITEM_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SEND_ADD_ORDER_ITEM_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SEND_ADD_ORDER_ITEM_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const sendRemoveOrderItems = (selectedOrderItems, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_REMOVE_ORDER_ITEMS,
    });
    const response = await axios.put('/api/orders/removeorderitems', { selectedOrderItems });
    const { success } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SEND_REMOVE_ORDER_ITEMS_ERROR, 'Try again later', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SEND_REMOVE_ORDER_ITEMS_ERROR,
      });
      return;
    }

    const removeSuccessMessage = selectedOrderItems.length > 1 ? 'The items have been removed' : 'The item has been removed';

    dispatch(addNotification(SEND_REMOVE_ORDER_ITEMS_SUCCESS, removeSuccessMessage, NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: SEND_REMOVE_ORDER_ITEMS_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SEND_REMOVE_ORDER_ITEMS_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SEND_REMOVE_ORDER_ITEMS_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const getItemsList = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ITEMS_LIST,
    });
    const response = await axios.get('/api/orders/itemslist');
    const { error, message } = response?.data || {};
    if (error || message) {
      dispatch(addNotification(GET_ITEMS_LIST_ERROR, error || message, NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: GET_ITEMS_LIST_ERROR,
      });
      return;
    }
    dispatch({
      type: GET_ITEMS_LIST_SUCCESS,
      payload: response?.data?.contacts || [],
    });
  } catch (e) {
    const { error, message } = e?.response?.data || {};
    dispatch(addNotification(GET_ITEMS_LIST_ERROR, error || message || 'Server error', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_ITEMS_LIST_ERROR,
    });
  }
};

export const getUserOrders = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ORDERS_LIST,
    });
    const response = await axios.get('/api/orders/orderslist');
    const { error, message } = response?.data || {};
    if (error || message) {
      dispatch(addNotification(GET_ORDERS_LIST_ERROR, error || message, NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: GET_ORDERS_LIST_ERROR,
      });
      return;
    }
    dispatch({
      type: GET_ORDERS_LIST_SUCCESS,
      payload: response?.data?.orders || [],
    });
  } catch (e) {
    const { error, message } = e?.response?.data || {};
    dispatch(addNotification(GET_ORDERS_LIST_ERROR, error || message || 'Server error', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_ORDERS_LIST_ERROR,
    });
  }
};
