import axios from 'src/utils/axios';
import helpers from 'src/helpers';
import { NOTIFICATION_STATUS } from 'src/constants';
import { addNotification } from 'src/actions/notificationActions';

export const GET_PARTY_VENUE = '@orders/get-party-venue';
export const GET_PARTY_VENUE_SUCCESS = '@orders/get-party-venue-success';
export const GET_PARTY_VENUE_ERROR = '@orders/get-party-venue-error';

export const DELETE_PARTY_VENUE = '@orders/del-party-venue';
export const DELETE_PARTY_VENUE_SUCCESS = '@orders/del-party-venue-success';
export const DELETE_PARTY_VENUE_ERROR = '@orders/del-party-venue-error';
export const CLEAR_PARTY_VENUE_WAS_DELETED = '@orders/clear-party-venue-was-deleted';

export const UPDATE_PARTY_VENUE = '@orders/update-party-venue';
export const UPDATE_PARTY_VENUE_SUCCESS = '@orders/update-party-venue-success';
export const UPDATE_PARTY_VENUE_ERROR = '@orders/update-party-venue-error';

export const CREATE_PARTY_VENUE = '@orders/create-party-venue';
export const CREATE_PARTY_VENUE_SUCCESS = '@orders/create-party-venue-success';
export const CREATE_PARTY_VENUE_ERROR = '@orders/create-party-venue-error';

export const SET_PARTY_VENUE_DETAILS_TAB = '@orders/set-party-venue-details-tab';

export const SET_GO_TO_PARTY_VENUE_DETAILS = '@orders/set-go-to-party-venue-details';
export const CLEAR_GO_TO_PARTY_VENUE_DETAILS = '@orders/clear-go-to-party-venue-details';

export const SET_FORM_CREATE_UPDATE_PARTY_VENUE = '@orders/set-form-create-update-party-venue';

export const SET_IS_MODAL_LOADING = '@orders/set-modal-loading';
export const SET_IS_MODAL_VISIBLE = '@orders/set-modal-visible';
export const SET_MODAL_MESSAGE = '@orders/set-modal-message';

export const setIsModalVisible = () => ({
  type: SET_IS_MODAL_VISIBLE
});

export const setIsModalLoading = (loading) => ({
  type: SET_IS_MODAL_LOADING,
  payload: loading
});

export const setModalMessage = (isVisible, modalTitle, modalBody) => ({
  type: SET_MODAL_MESSAGE,
  payload: {
    isVisible,
    modalTitle,
    modalBody,
  },
});

export const getPartyVenue = (partyVenueId) => async (dispatch) => {
  dispatch({
    type: GET_PARTY_VENUE,
  });

  try {
    const response = await axios.get(`/api/partysettings/partyvenues/${partyVenueId}`);
    dispatch({
      type: GET_PARTY_VENUE_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_PARTY_VENUE_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PARTY_VENUE_ERROR,
    });
  }
};

export const deletePartyVenue = (partyVenueId) => async (dispatch) => {
  try {
    dispatch(setIsModalLoading(true));
    dispatch({
      type: DELETE_PARTY_VENUE,
    });
    const response = await axios.delete(`/api/partySettings/partyVenues/${partyVenueId}`);
    const {
      success,
      error,
    } = response?.data || {};

    dispatch({
      type: DELETE_PARTY_VENUE_SUCCESS,
      success,
    });

    if (error) {
      dispatch(addNotification(DELETE_PARTY_VENUE, error, NOTIFICATION_STATUS.ERROR));
      dispatch(setIsModalLoading(false));
      return;
    }

    if (!success) {
      dispatch(getPartyVenue(partyVenueId));
      dispatch(addNotification(DELETE_PARTY_VENUE_ERROR, `Can not delete party venue ${partyVenueId}`, NOTIFICATION_STATUS.ERROR));
      return;
    }
    dispatch(addNotification(DELETE_PARTY_VENUE_SUCCESS, 'Party Venue was deleted', NOTIFICATION_STATUS.SUCCESS));
  } catch (e) {
    const {
      deleteOrInactivate,
      modalTitle,
      modalBody,
      error,
      message,
    } = e?.response?.data || {};

    if (modalBody) {
      dispatch(setModalMessage(true, modalTitle, modalBody));
    } else if (deleteOrInactivate === 'Inactivate') {
      dispatch(setModalMessage(true, 'Inactivated Instead of Deleted', ['The venue was inactivated instead of deleted, because at least one Closed Party is using this venue.', 'If you want to delete this venue, go to the parties screen and change the venue of those party(s), then return here to actually delete it.']));
    }

    dispatch(addNotification(DELETE_PARTY_VENUE_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch(getPartyVenue(partyVenueId));
    dispatch({
      type: DELETE_PARTY_VENUE_ERROR,
    });
  }
};

export const clearPartyVenueWasDeleted = () => async (dispatch) => {
  dispatch({
    type: CLEAR_PARTY_VENUE_WAS_DELETED,
  });
};

export const updatePartyVenue = (partyVenueId, partyVenueChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch(setIsModalLoading(true));
    dispatch({ // orderActions doesn't have this.  Just one immediately above.
      type: UPDATE_PARTY_VENUE,
    });
    const response = await axios.put('/api/partySettings/partyVenues', {
      partyVenueChanges,
    });
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(UPDATE_PARTY_VENUE_ERROR, 'Can not update party venue', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: UPDATE_PARTY_VENUE_ERROR,
      });
      return;
    }

    await dispatch(getPartyVenue(partyVenueId));
    dispatch(addNotification(UPDATE_PARTY_VENUE_SUCCESS, 'The party venue has been successfully updated', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: UPDATE_PARTY_VENUE_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      modalTitle,
      modalBody,
      error,
      message,
    } = e?.response?.data || {};

    if (modalBody) {
      dispatch(setModalMessage(true, modalTitle, modalBody));
    } else if (message) {
      dispatch(setModalMessage(true, 'Venue Name Already Exists', ['This venue name already exists in active in inactive status.']));
    }

    const { message: errorMessage } = error || {};
    dispatch(addNotification(UPDATE_PARTY_VENUE_ERROR, errorMessage || 'Venue didn\'t update', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: UPDATE_PARTY_VENUE_ERROR,
    });
    dispatch(setIsModalLoading(false));

    if (doWhenCatch) {
      doWhenCatch(e); // orderActions has no "e"
    }
  }
};

export const createPartyVenue = (partyVenueChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch(setIsModalLoading(true));
    dispatch({
      type: CREATE_PARTY_VENUE,
    });
    const response = await axios.post('/api/partySettings/partyVenues/create', {
      partyVenueChanges,
    });
    const {
      id,
    } = response?.data || {};

    if (!id) {
      dispatch(addNotification(CREATE_PARTY_VENUE_ERROR, 'Can not create party venue', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: CREATE_PARTY_VENUE_ERROR,
      });
      return;
    }

    dispatch(addNotification(CREATE_PARTY_VENUE_SUCCESS, 'Your party venue has been successfully created', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: CREATE_PARTY_VENUE_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry(id);
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};

    const { message: errorMessage } = error || {};

    if (message) {
      dispatch(setModalMessage(true, 'Venue Name Already Exists', ['This venue name already exists in active in inactive status.']));
    }

    dispatch(addNotification(CREATE_PARTY_VENUE_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: CREATE_PARTY_VENUE_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setPartyVenueDetailsTab = (tabName) => async (dispatch) => {
  dispatch({
    type: SET_PARTY_VENUE_DETAILS_TAB,
    payload: tabName
  });
};

export const setGoToOrderDetails = (partyVenueId, wasReturned = false, query = false) => async (dispatch) => {
  dispatch({
    type: SET_GO_TO_PARTY_VENUE_DETAILS,
    payload: {
      partyVenueId,
      wasReturned,
      ...query ? { query } : {},
    },
  });
};

export const clearGoToOrderDetails = (all = false) => async (dispatch) => {
  dispatch({
    type: CLEAR_GO_TO_PARTY_VENUE_DETAILS,
    payload: all,
  });
};

export const setFormCreateUpdatePartyVenue = (payload) => ({
  type: SET_FORM_CREATE_UPDATE_PARTY_VENUE,
  payload
});
