import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import { withStyles } from 'tss-react/mui';

import typographyContainerStyle from 'src/components/TypographyContainer/typographyContainer.style';

function TypographyContainer({
  classes,
  children,
}) {
  return (
    <Typography
      component="div"
    // component="pre"
      className={classes.typography}
    >
      {children}
    </Typography>
  );
}

TypographyContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

TypographyContainer.defaultProps = {};

export default withStyles(TypographyContainer, typographyContainerStyle);
