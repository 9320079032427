import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Container, Divider, Tab, Tabs
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import Page from 'src/components/Page';
import Spinner from 'src/components/Spinner/Spinner';
import Header from 'src/components/View/Header';
import Results from 'src/views/Settings/MessageView/Results';
import ButtonBox from 'src/views/Settings/MessageView/ButtonBox';
import HelpCircleWithContainer from 'src/components/HelpCircle/HelpCircleWithContainer';
import helpTextForFields from 'src/views/Settings/MessageView/EditMessage/options';
import MessageBox from 'src/components/MessageBox';
import { MESSAGES } from 'src/constants';
import {
  getMessages,
  setMessageNavigationTab,
} from 'src/actions/messagesActions';

import pageViewStyle from 'src/components/View/Page/pageView.style';

function MessageView({
  classes
}) {
  const dispatch = useDispatch();
  const {
    messages,
    loading,
    currentNavigationTab,
    userMessageCodes,
  } = useSelector((state) => state.messages);

  const tabs = [
    // {
    //   value: MESSAGES.MESSAGE_TAB_SYSTEM,
    //   label: 'System'
    // },
    {
      value: MESSAGES.MESSAGE_TAB_USER,
      label: 'User'
    },
  ];

  useEffect(() => {
    dispatch(getMessages());
  }, [dispatch]);

  const handleTabsChange = (event, value) => {
    dispatch(setMessageNavigationTab(value));
    dispatch(getMessages());
  };

  return (
    <Page
      className={classes.root}
      // title="Messages  List"
      title="Message Templates"
    >
      <Container maxWidth={false}>
        <Header
          title={(
            <HelpCircleWithContainer
              positionX="afterText"
              positionY="centerTop4Plus"
              showInfo={helpTextForFields.headerTitleMessages}
            >
              Message Templates
            </HelpCircleWithContainer>
          )}
          message={(
            <MessageBox
              message="NOTE: Order Follow-Up Templates have moved to the new Order Follow-Up Settings screen Follow-Up Templates tab.  This can be accessed from the left sidebar."
              type="type-3-light"
              paddingTop="15"
              paddingBottom="0"
              visible
            />
          )}
          buttonBox={(
            <ButtonBox
              loading={loading}
              currentNavigationTab={currentNavigationTab}
            />
          )}
        />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentNavigationTab}
            variant="scrollable"
            textColor="secondary"
            indicatorColor="secondary"
            className={classes.tabs}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                disabled={loading}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box
          className={classes.relativeBox}
          mt={3}
        >
          <Spinner
            loading={loading}
            positionType="absolute"
            zIndex="z1300"
          />
          <Results
            messages={messages}
            currentNavigationTab={currentNavigationTab}
            userMessageCodes={userMessageCodes}
          />
        </Box>
      </Container>
    </Page>
  );
}

MessageView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(MessageView, pageViewStyle, { withTheme: true });
