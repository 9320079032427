import React from 'react';
import PropTypes from 'prop-types';
import { STORAGE } from 'src/constants';
import { TableCell, TableRow } from '@mui/material';
import TextShowMore from 'src/components/TextShowMore';
import helpers from 'src/helpers';

function TBodyResultsMessage({
  handleRowClick,
  handleClickShowMoreButton,
  isShowMoreTextChecked,
  message,
  classes,
  userId,
  azAccountName,
  databaseBeingUsed,
}) {
  const {
    id,
    code,
    description,
    systemGenMessage,
    attachment,
    attachmentContainer,
  } = message;

  return (
    <TableRow
      key={id}
      onClick={(event) => {
        handleRowClick(event, message);
      }}
      className={classes.tableRow}
      hover
    >
      {/* Msg # */}
      <TableCell
        align="left"
        className={classes.msgNum}
      >
        {code}
      </TableCell>

      {/* Scenario */}
      <TableCell
        align="left"
        className={classes.scenario}
      >
        {description}
      </TableCell>

      {/* Text Message To Send */}
      <TableCell
        align="left"
        className={classes.textMessages}
      >
        <TextShowMore
          text={systemGenMessage || ''}
          maxTextLength={350}
          checked={isShowMoreTextChecked(id)}
          setChecked={handleClickShowMoreButton}
          orderId={id}
        />
      </TableCell>

      {/* Image */}
      <TableCell
        align="left"
        className={classes.image}
      >
        {attachment ? (
          <img
            // src={helpers.azure.getImageFilePath(attachment, userId, azAccountName, STORAGE.sas, databaseBeingUsed, true)}
            src={helpers.azure.getImageFileURL(attachment, userId, azAccountName, STORAGE.sas, databaseBeingUsed, attachmentContainer, true)}
            alt={attachment}
          />
        ) : ''}
      </TableCell>
    </TableRow>
  );
}

TBodyResultsMessage.propTypes = {
  userId: PropTypes.number.isRequired,
  azAccountName: PropTypes.string.isRequired,
  databaseBeingUsed: PropTypes.string.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  handleClickShowMoreButton: PropTypes.func.isRequired,
  isShowMoreTextChecked: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default TBodyResultsMessage;
