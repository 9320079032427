const helpCircleStyle = {
  root: {
    width: 20,
    marginLeft: 14,

    '&.sizeType-15': {
      width: 15,
      '& .MuiSvgIcon-root': {
        width: 15,
        height: 15,
      }
    },

    '&.marginLeft-19': {
      marginLeft: 19,
    },

    '&.marginLeft-5': {
      marginLeft: 5,
    },

    '&.marginLeft-0': {
      marginLeft: 0,
    },

    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
      color: 'inherit'
    }
  },
};

export default helpCircleStyle;
