import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@mui/material/styles';
import { withStyles } from 'tss-react/mui';
import modalDialogVideoStyles from './modalDialogVideo.style';

function ModalDialogVideo({
  videosrc,
  title,
  onClose,
  isVisible,
  titleClassOptions,
  largeModal,
  classes,
  className,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };
    updateWindowWidth();
    window.addEventListener('resize', updateWindowWidth);
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  const classesForDialog = {
    root: classes.root,
    ...(largeModal ? { paper: classes.largeDialogPaper } : {}),
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isVisible}
      aria-labelledby="responsive-dialog-title"
      scroll="body"
      classes={classesForDialog}
      className={className}
    >
      <DialogTitle>
        <div className={`${classes.title} ${titleClassOptions}`}>
          <IconButton
            className={classes.close}
            aria-label="close"
            onClick={onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <iframe
          src={videosrc}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          title={title}
          width={windowWidth * 0.8}
          height={windowWidth * 0.5}
        />
      </DialogContent>
    </Dialog>
  );
}

ModalDialogVideo.propTypes = {
  onClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  videosrc: PropTypes.string,
  title: PropTypes.string,
  titleClassOptions: PropTypes.string,
  largeModal: PropTypes.bool,
  className: PropTypes.string,
};

ModalDialogVideo.defaultProps = {
  videosrc: '',
  title: null,
  titleClassOptions: '',
  largeModal: true,
  className: '',
};

export default withStyles(ModalDialogVideo, modalDialogVideoStyles, { withTheme: true });
