import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar, Box, Hidden, IconButton, Toolbar, SvgIcon
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import {
  THEMES,
  ROUTES,
} from 'src/constants';
import helpers from 'src/helpers';
import Account from './Account';
// import Contacts from './Contacts';
// import Notifications from './Notifications';
// import Search from './Search';
// import Settings from './Settings';

const useStyles = makeStyles()((theme) => ({
  root: {
    ...(theme.name === THEMES.LIGHT
      ? {
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main
      }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
        backgroundColor: theme.palette.background.default
      }
      : {})
  },
  toolbar: {
    minHeight: 64
  }
}));

function TopBar({
  className, logout, onMobileNavOpen, ...rest
}) {
  const { classes, cx } = useStyles();
  const { user } = useSelector((state) => state.account);

  return (
    <AppBar className={cx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
            size="large"
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden lgDown>
          {/* <RouterLink to={helpers.isPageBlocked(user) || !helpers.doesUserHaveSubscriptionOrTrial(user) ? ROUTES.APP_ACCOUNT : '/'}> */}
          <RouterLink to={helpers.isPageBlocked(user) || !helpers.doesUserHaveSubscription(user) ? ROUTES.APP_ACCOUNT : '/'}>
            <Logo />
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        {/* <Search />
        <Contacts />
        <Notifications />
        <Settings /> */}
        <Box ml={2}>
          <Account logout={logout} />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  logout: PropTypes.func.isRequired,
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
