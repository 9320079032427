/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_PARTIES,
  GET_PARTIES,
  GET_PARTIES_SUCCESS,
  GET_PARTIES_ERROR,
  SET_PARTIES_QUERY,
  CLEAR_PARTIES_QUERY_FILTER,
  SET_SELECTED_PARTIES,
  SET_VISIBLE_MODAL_FORM_NUMBER,
  SET_VALUES_FORM_SEND_TEXT,
  SEND_TEXT_MESSAGE,
  SEND_TEXT_MESSAGE_ERROR,
  SEND_TEXT_MESSAGE_SUCCESS,
  SET_VALUES_FORM_ADD_PB_TAG,
  SEND_ADD_TAG_CAMPAIGN,
  SEND_ADD_TAG_CAMPAIGN_ERROR,
  SEND_ADD_TAG_CAMPAIGN_SUCCESS,
  GET_FOLLOW_UP_MESSAGES,
  GET_FOLLOW_UP_MESSAGES_SUCCESS,
  GET_FOLLOW_UP_MESSAGES_ERROR,
  SET_BACK_TO_PARTY_LIST,
  GET_PARTY_TYPES,
  GET_PARTY_TYPES_SUCCESS,
  GET_PARTY_TYPES_ERROR,
  SEND_CLOSE_PARTIES,
  SEND_CLOSE_PARTIES_ERROR,
  SEND_CLOSE_PARTIES_SUCCESS,
} from 'src/actions/partiesActions';

const initialFilters = {
  partyVenue: null,
  partyType: null,
  partyStartDateFrom: null,
  partyStartDateTo: null,
  partyEndDateFrom: null,
  partyEndDateTo: null,
};

const formSendText = {
  systemGenMessageReplaced: false,
  attachment: false,
  attachmentContainer: null,
  loading: false,
};

const formAddPBTag = {
  loading: false,
};

const followUpMessages = {
  followUpMessageList: [],
  loading: false,
};

const backToPartyList = {
  parties: [],
  query: {},
  active: false,
  comeBack: false,
};

const partyTypes = {
  partyTypeList: [],
  loading: false,
};

const initialState = {
  parties: [],
  selectedParties: [],
  query: {
    search: '',
    filters: initialFilters,
    status: 'open',
    sort: [],
    page: 1,
    pageSize: 50,
    count: 0,
  },
  visibleModalFormNumber: null,
  formSendText,
  formAddPBTag,
  followUpMessages,
  backToPartyList,
  loading: false,
  partyTypes,
};

const partiesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PARTIES: {
      return produce(state, (draft) => {
        draft.parties = payload || [];
        draft.selectedParties = [];
      });
    }

    case GET_PARTIES: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_PARTIES_SUCCESS: {
      return produce(state, (draft) => {
        draft.parties = payload?.parties || [];
        draft.query.count = payload?.count || 0;
        draft.loading = false;
        draft.selectedParties = [];
      });
    }

    case GET_PARTIES_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_PARTIES_QUERY: {
      return produce(state, (draft) => {
        draft.query = payload ? {
          ...state.query,
          ...payload
        } : initialState.query;
      });
    }

    case CLEAR_PARTIES_QUERY_FILTER: {
      return produce(state, (draft) => {
        draft.query.filters = initialFilters;
        draft.query.page = initialState.query.page;
      });
    }

    case SET_SELECTED_PARTIES: {
      return produce(state, (draft) => {
        draft.selectedParties = payload || [];
      });
    }

    case SET_VISIBLE_MODAL_FORM_NUMBER: {
      return produce(state, (draft) => {
        draft.visibleModalFormNumber = payload || null;
      });
    }

    case SET_VALUES_FORM_SEND_TEXT: {
      return produce(state, (draft) => {
        draft.formSendText = payload ? {
          ...state.formSendText,
          ...payload,
        } : formSendText;
      });
    }

    case SEND_TEXT_MESSAGE: {
      return produce(state, (draft) => {
        draft.formSendText.loading = true;
      });
    }

    case SEND_TEXT_MESSAGE_SUCCESS:
    case SEND_TEXT_MESSAGE_ERROR: {
      return produce(state, (draft) => {
        draft.formSendText.loading = false;
      });
    }

    case SET_VALUES_FORM_ADD_PB_TAG: {
      return produce(state, (draft) => {
        draft.formAddPBTag = payload ? {
          ...state.formAddPBTag,
          ...payload,
        } : formAddPBTag;
      });
    }

    case SEND_ADD_TAG_CAMPAIGN: {
      return produce(state, (draft) => {
        draft.formAddPBTag.loading = true;
      });
    }

    case SEND_ADD_TAG_CAMPAIGN_SUCCESS:
    case SEND_ADD_TAG_CAMPAIGN_ERROR: {
      return produce(state, (draft) => {
        draft.formAddPBTag.loading = false;
      });
    }

    case GET_FOLLOW_UP_MESSAGES: {
      return produce(state, (draft) => {
        draft.followUpMessages.loading = true;
      });
    }

    case GET_FOLLOW_UP_MESSAGES_SUCCESS:
    case GET_FOLLOW_UP_MESSAGES_ERROR: {
      return produce(state, (draft) => {
        draft.followUpMessages.loading = false;
        draft.followUpMessages.followUpMessageList = payload ? [
          ...state.followUpMessages.followUpMessageList,
          ...payload,
        ] : followUpMessages.followUpMessageList;
      });
    }

    case SET_BACK_TO_PARTY_LIST: {
      return produce(state, (draft) => {
        draft.backToPartyList = payload ? {
          ...state.backToPartyList,
          ...payload,
        } : backToPartyList;
      });
    }

    case GET_PARTY_TYPES: {
      return produce(state, (draft) => {
        draft.partyTypes.loading = true;
      });
    }

    case GET_PARTY_TYPES_SUCCESS:
    case GET_PARTY_TYPES_ERROR: {
      return produce(state, (draft) => {
        draft.partyTypes.loading = false;
        draft.partyTypes.partyTypeList = payload ? [
          ...state.partyTypes.partyTypeList,
          ...payload,
        ] : partyTypes.partyTypeList;
      });
    }

    case SEND_CLOSE_PARTIES: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case SEND_CLOSE_PARTIES_SUCCESS:
    case SEND_CLOSE_PARTIES_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default partiesReducer;
