import { isEmpty, isString } from 'src/helpers/check';

// eslint-disable-next-line import/prefer-default-export
export const splitStringIntoTwoParts = (str, maxLength) => {
  if (isEmpty(str) || !isString(str)) {
    return null;
  }
  const firstStr = str.length > maxLength
    ? str
      .slice(0, maxLength)
      .replace(/[^\s\n\r]+$/g, '')
      .replace(/[\s\n\r]$/, '')
    : str;
  const secondStr = str.replace(firstStr, '');
  return {
    firstStr,
    secondStr
  };
};

export const removeHttpFromUrl = (url) => (url || '').replace(/^((http:\/\/)|(https:\/\/)|(\/\/))www\./gui, 'www.');

export const removeSpace = (value) => (value || '').replace(/\s/gui, '');

// export const removeExtraSpaces = (value) => (value || '').replace(/\s+/g, ' ').trim();
export const removeExtraSpaces = (value) => (!value ? null : value.replace(/\s+/g, ' ').trim());

export const addZeroBeforeString = (value, number) => `${'0'.repeat(number)}${value}`.slice(-1 * number);
