import { STYLE_OPTIONS } from 'src/constants';
import resultsTableStyle from 'src/components/View/Page/ResultsTable/resultsTable.style';

const helpAndInformationViewStyles = {
  ...resultsTableStyle,
  card: {
    padding: 15,
    marginBottom: 20,
    fontFamily: STYLE_OPTIONS.FONT_FAMILY,
  },
};

export default helpAndInformationViewStyles;
