/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_PARTY_VENUES,
  GET_PARTY_VENUES,
  GET_PARTY_VENUES_SUCCESS,
  GET_PARTY_VENUES_ERROR,
  SET_PARTY_VENUES_QUERY,
  SET_BACK_TO_PARTY_VENUES_LIST,
} from 'src/actions/partyVenuesActions';

const backToPartyVenuesList = {
  partyVenues: [],
  query: {},
  active: false,
  comeBack: false,
};

const initialState = {
  partyVenues: [],
  query: {
    status: 'Active',
  },
  visibleModalFormNumber: null,
  backToPartyVenuesList,
  loading: false,
};

const partyVenuesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PARTY_VENUES: {
      return produce(state, (draft) => {
        draft.partyVenues = payload || [];
      });
    }

    case GET_PARTY_VENUES: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_PARTY_VENUES_SUCCESS: {
      return produce(state, (draft) => {
        draft.partyVenues = payload?.partyVenues || [];
        draft.query.count = payload?.count || 0;
        draft.loading = false;
      });
    }

    case GET_PARTY_VENUES_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_PARTY_VENUES_QUERY: {
      return produce(state, (draft) => {
        draft.query = payload ? {
          ...state.query,
          ...payload
        } : initialState.query;
      });
    }

    case SET_BACK_TO_PARTY_VENUES_LIST: {
      return produce(state, (draft) => {
        draft.backToPartyVenuesList = payload ? {
          ...state.backToPartyVenuesList,
          ...payload,
        } : backToPartyVenuesList;
      });
    }

    default: {
      return state;
    }
  }
};

export default partyVenuesReducer;
