export const FONT_FAMILY = 'Roboto';

export const COLOR_BLACK = '#000000';

export const COLOR_TEXT = '#393939';

export const COLOR_LIGHT_GREY = '#C8CCCE';

export const COLOR_LIGHT_GREY_2 = '#C7C7C7';

export const COLOR_LIGHT_GREY_DISABLED = '#CCCCCC';

export const COLOR_LIGHT_YELLOW = '#FFDD2D';

export const COLOR_LIGHT_GREEN = '#388E3C';

export const COLOR_RED = '#ED3F3F';

export const COLOR_BLUE = '#5850EC';

export const COLOR_ACCOUNT_SETUP_FIELD_HIGHLIGHTS = 'rgba(255,255,51,0.5)';

export const BUTTON_PRIMARY = {
  color: '#ffffff',
  background: COLOR_BLUE,
  boxShadow: `0px 0px 0px 1px ${COLOR_BLUE}`,
  fontSize: '14px',
  textTransform: 'none',
  fontWeight: 400,
  padding: '5px 20px',
  '&:hover': {
    color: COLOR_BLUE,
    boxShadow: `0px 0px 0px 1px ${COLOR_BLUE}`,
    backgroundColor: 'rgba(38, 50, 56, 0.04)',
  },
  '&.Mui-disabled': {
    background: COLOR_LIGHT_GREY_DISABLED,
    boxShadow: `0px 0px 0px 1px ${COLOR_LIGHT_GREY_DISABLED}`,
    color: '#777777',
  }
};

export const BUTTON_PRIMARY_REVERSED = {
  ...BUTTON_PRIMARY,
  backgroundColor: '#ffffff',
  color: COLOR_BLUE,
  fontWeight: 500,
};

export const BUTTON_SECONDARY = {
  ...BUTTON_PRIMARY,
  background: '#ABABB7',
  boxShadow: '0px 0px 0px 1px #ABABB7',
};

export const BUTTON_EMPTY_STYLE = {
  padding: 0,
  margin: 0,
  background: 'none',
  minWidth: 0,
  '&:hover, &:active, & .MuiTouchRipple-root': {
    background: 'none',
  },
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
};

export const A_PRIMARY = {
  color: '#00ACEC',
  '&:hover': {
    textDecoration: 'none',
    color: COLOR_BLUE,
  },
};

export const SVG_COLOR_BLUE = {
  color: COLOR_BLUE,
  '& .stroke': {
    stroke: COLOR_BLUE,
  },
  '& .fill': {
    fill: COLOR_BLUE,
  },
  '& .strokeAndFill': {
    stroke: COLOR_BLUE,
    fill: COLOR_BLUE,
  },
};

export const STRIPE_CARD_STYLE = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: COLOR_BLUE,
      color: COLOR_TEXT,
      fontWeight: 500,
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: COLOR_LIGHT_YELLOW
      },
      '::placeholder': {
        color: COLOR_LIGHT_GREY
      }
    },
    invalid: {
      iconColor: COLOR_RED,
      color: COLOR_RED,
    }
  }
};
