const colorText = '#393939';
const colorRed = '#FF0000';
const fontFamily = 'Roboto';

const textFieldEmojiStyle = (theme) => ({
  root: {},

  textFieldRed: {
    color: colorRed,
  },

  textMessageLabel: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '32px',
    paddingTop: 14,
    fontFamily,
    '&.flex': {
      display: 'flex',
      justifyContent: 'space-between'
    },
    '& .text': {
      width: '100%'
    },
    '& .rightWrapper': {
      marginLeft: 10,
      lineHeight: '1px'
    },
    '& .svgIcon': {
      width: '32px',
      height: '32px',
      cursor: 'pointer'
    }
  },

  warningTextBefore: {
    fontFamily,
    fontWeight: 800,
    color: colorText,
    fontSize: '18px',
    lineHeight: '20px',
    paddingBottom: 5,
    width: '100%',

    '&.flex': {
      display: 'flex',
    },

    '& .middleText': {
      width: '100%',
      color: colorRed,
    },
  },

  textMessageAfter: {
    fontFamily,
    fontWeight: 400,
    color: colorText,
    fontSize: '12px',
    lineHeight: '14px',
    paddingTop: 5,
    width: '100%',

    '&.flex': {
      display: 'flex',
      justifyContent: 'space-between'
    },

    '& .icon': {
      fontSize: '14px',
      marginRight: 10,
      color: '#858585',

      '& .MuiSvgIcon-root': {
        fontSize: 'inherit'
      }
    },

    '& .middleText': {
      width: '100%',
      color: '#858585'
    },

    '& .rightText': {
      marginLeft: 10,
      textAlign: 'right',
      whiteSpace: 'nowrap',

      '& .txt': {
        display: 'inline-block',
        marginRight: '0.3em',

        [theme.breakpoints.down('sm')]: {
          display: 'none',
        }
      },
    }
  },
});

export default textFieldEmojiStyle;
