import { STYLE_OPTIONS } from 'src/constants';

const selectDynamicFieldDropDownStyle = (theme) => ({
  root: {
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 5,
    flexWrap: 'wrap',
    zIndex: 240,
    fontFamily: STYLE_OPTIONS.FONT_FAMILY,

    '& .inner': {
      marginRight: 24,
      marginTop: 15,
      '&:last-child': {
        marginRight: 0,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginRight: 0,
      },
    },
  },

  innerField: {
    position: 'relative',
    display: 'flex',
    border: `1px solid ${STYLE_OPTIONS.COLOR_LIGHT_GREY_2}`,
    width: 35,
    height: 30,
    boxSizing: 'border-box',
    padding: '10px 1px',
    backgroundColor: '#ebebeb',
    borderRadius: 4,
    justifyContent: 'space-between',
    alignItems: 'center',

    '&.withBtn': {
      padding: 0,

      '& .divBtn': {
        display: 'flex',
        width: 35,
        height: 50,
        alignItems: 'center',
      },
    },

    '& .icon': {
      width: 32,
      '& svg': {
        verticalAlign: 'middle',
      },
    },

    '& .input': {
      width: '100%',
      border: 'none !important',
      boxShadow: 'none !important',
      outline: 'none !important',
      background: '#ffffff !important',
      fontFamily: STYLE_OPTIONS.FONT_FAMILY,
      fontSize: '16px',
      lineHeight: '20px',
      color: '#263228',
      opacity: 1,
      flexShrink: 1,
      flexFlow: 1,
      '&::-webkit-input-placeholder, &::-moz-placeholder': {
        color: STYLE_OPTIONS.COLOR_LIGHT_GREY_2,
        opacity: 1,
      },
    },

    '& .iconRight': {
      width: 24,
      marginLeft: 10,
      '& svg': {
        verticalAlign: 'middle',
      },
    },

    '& .buttonWithoutStyles': {
      margin: 0,
      textDecoration: 'none',
      textAlign: 'left',
      cursor: 'pointer',
      background: 'none !important',
      border: 'none !important',
      padding: '0px',
    },
  },

  innerFieldDropDown: {
    position: 'absolute',
    display: 'block',
    top: 35,
    left: -356,
    width: 390,
    height: 'auto',
    padding: '20px 21px',
    backgroundColor: '#ffffff',
    borderRadius: 4,
    border: `1px solid ${STYLE_OPTIONS.COLOR_LIGHT_GREY_2}`,
    zIndex: 250,

    '& .searchLine': {
      display: 'flex',
      width: 'calc(100% + 8px)',
      height: 'auto',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginLeft: -8,
      '& .input': {
        fontSize: '14px',
      },

      '& .iconRight': {
        width: 20,
        marginLeft: 15,
      },
    },

    '& .buttonLine': {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingBottom: 13,
      borderBottom: '1px solid #E0E0E0',

      '& .MuiButton-root': {
        ...STYLE_OPTIONS.BUTTON_PRIMARY,
        marginLeft: 8,

        '&.cancel': {
          ...STYLE_OPTIONS.BUTTON_SECONDARY,
        },
      },
    },

    '& .formBox': {
      paddingTop: 12,
    },
  },
});

export default selectDynamicFieldDropDownStyle;
