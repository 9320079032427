import PropTypes from 'prop-types';
import React from 'react';
import { matchPath } from 'react-router';
import NavItem from 'src/layouts/DashboardLayout/NavBar/NavItem';
import NavItemsParent from 'src/layouts/DashboardLayout/NavBar/NavItemsParent';

function NavItemsChild({
  pathname,
  isPageBlocked,
  item,
  depth,
}) {
  const {
    icon,
    info,
    title,
    click,
    items,
    href,
    isShownWhenOtherHidden,
  } = item;
  const open = matchPath(pathname, {
    path: href,
    exact: false
  });

  return (
    <NavItem
      key={`${title}${depth}`}
      depth={depth}
      icon={icon}
      info={info}
      title={title}
      click={click}
      isPageBlocked={isPageBlocked}
      isShownWhenOtherHidden={isShownWhenOtherHidden}
      {...items ? { open } : { href }}
    >
      {items && (
        <NavItemsParent
          depth={depth + 1}
          pathname={pathname}
          items={items}
          isPageBlocked={isPageBlocked}
        />
      )}
    </NavItem>
  );
}

NavItemsChild.propTypes = {
  item: PropTypes.object.isRequired,
  isPageBlocked: PropTypes.bool.isRequired,
  pathname: PropTypes.string,
  depth: PropTypes.number,
};

NavItemsChild.defaultProps = {
  depth: 0,
  pathname: '',
};

export default NavItemsChild;
