import React from 'react';
import PropTypes from 'prop-types';
import { STORAGE } from 'src/constants';
import { Button, SvgIcon } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import helpers from 'src/helpers';

import { ReactComponent as UploadIcon } from 'src/assets/images/upload.svg';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import uploadImageStyle from 'src/components/UploadImage/uploadImage.style';

function UploadImage({
  title,
  classes,
  fileName,
  fileUpload,
  loading,
  errorValue,
  validateFileInput,
  setFileUploadToMessage,
  errorMessage,
  inputId,
  inputAccept,
  buttonSmallText,
  addHighlights,
  disabledImage,
  isDeleteBtnHidden,
}) {
  const highlightsClass = addHighlights ? ` highlightsType${addHighlights}` : '';

  const getFileToSrc = (file) => (file ? URL.createObjectURL(file) : null);

  const handleOnSelectedFile = async (event) => {
    const { target: { files, value } } = event;
    if (!files || !value) {
      return;
    }
    const uploadFileData = {
      file: files[0],
      value
    };
    if (validateFileInput(uploadFileData)) {
      setFileUploadToMessage(uploadFileData);
    }
  };

  const handleRemoveSelectedFile = () => {
    setFileUploadToMessage(null);
    validateFileInput(false);
  };

  return (
    <>
      <input
        accept={inputAccept}
        id={inputId}
        type="file"
        value=""
        className={classes.inputUpload}
        onChange={handleOnSelectedFile}
        disabled={loading}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        {...inputId && !loading ? { htmlFor: inputId } : {}}
      >
        <Button
          className={`${classes.buttonUpload}${highlightsClass}`}
          variant="contained"
          component="span"
          disabled={loading}
        >
          {disabledImage && (
            <div className={classes.disabledImageBox} />
          )}
          {fileUpload || (fileName && fileUpload !== null) ? (
            // eslint-disable-next-line jsx-a11y/img-redundant-alt
            <img
              src={fileUpload ? getFileToSrc(fileUpload?.file) : helpers.azure.addSasToUrl(fileName, STORAGE.sas)}
              alt={title}
            />
          ) : (
            <>
              <SvgIcon
                className="svgIcon"
                component={UploadIcon}
                viewBox="0 0 32 22"
              />
              <div className="buttonName">
                {title}
              </div>
              <div className="buttonSmallText">
                {buttonSmallText}
              </div>
            </>
          )}
        </Button>
      </label>
      {(fileUpload || fileName) && !isDeleteBtnHidden && (
        <Button
          className={classes.buttonRemove}
          onClick={handleRemoveSelectedFile}
          disabled={loading}
        >
          <DeleteForeverIcon />
        </Button>
      )}
      {(errorValue || errorMessage) && (
        <div className={classes.error}>
          {errorValue || errorMessage}
        </div>
      )}
    </>
  );
}

UploadImage.propTypes = {
  validateFileInput: PropTypes.func.isRequired,
  setFileUploadToMessage: PropTypes.func.isRequired,
  errorMessage: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  inputId: PropTypes.string,
  inputAccept: PropTypes.string,
  buttonSmallText: PropTypes.string,
  fileName: PropTypes.string,
  fileUpload: PropTypes.any,
  errorValue: PropTypes.any,
  addHighlights: PropTypes.oneOf([
    1,
  ]),
  disabledImage: PropTypes.bool,
  isDeleteBtnHidden: PropTypes.bool,
};

UploadImage.defaultProps = {
  inputId: 'contained-button-file',
  inputAccept: '.jpg,.jpeg,.png,.gif',
  buttonSmallText: 'Less than 1.5 MB.  Must be a jpg, jpeg, png, or gif file)',
  fileName: null,
  fileUpload: false,
  errorValue: false,
  addHighlights: null,
  disabledImage: false,
  isDeleteBtnHidden: false,
};

export default withStyles(UploadImage, uploadImageStyle);
