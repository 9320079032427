/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_CHECKED_SUBSCRIPTION,
  SET_EDIT_SUBSCRIPTION_CARD,
  SET_VALUE_FORM_REFERRAL_CODE_SEND_DATA,
  CLEAR_FORM_REFERRAL_CODE_SEND_DATA,
  GET_SUBSCRIPTION_LIST,
  GET_SUBSCRIPTION_LIST_SUCCESS,
  GET_SUBSCRIPTION_LIST_ERROR,
} from 'src/actions/subscriptionsActions';
import {
  APPLY_REFERRAL_CODE,
} from 'src/actions/accountSettingsActions';

const initialState = {
  loading: false,
  checkedSubscription: false,
  editSubscriptionCard: false,
  subscriptions: [],
  formReferralCodeSendData: {},
};

const subscriptionsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CHECKED_SUBSCRIPTION: {
      return produce(state, (draft) => {
        draft.checkedSubscription = payload;
      });
    }

    case SET_EDIT_SUBSCRIPTION_CARD: {
      return produce(state, (draft) => {
        draft.editSubscriptionCard = payload;
      });
    }

    case SET_VALUE_FORM_REFERRAL_CODE_SEND_DATA: {
      return produce(state, (draft) => {
        draft.formReferralCodeSendData = {
          ...state.formReferralCodeSendData,
          ...payload || {},
        };
      });
    }

    case CLEAR_FORM_REFERRAL_CODE_SEND_DATA: {
      return produce(state, (draft) => {
        draft.formReferralCodeSendData = {};
      });
    }

    case GET_SUBSCRIPTION_LIST: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_SUBSCRIPTION_LIST_SUCCESS: {
      return produce(state, (draft) => {
        draft.subscriptions = payload;
        draft.loading = false;
      });
    }

    case GET_SUBSCRIPTION_LIST_ERROR: {
      return produce(state, (draft) => {
        draft.subscriptions = [];
        draft.loading = false;
      });
    }

    /* ******************
     * here we are listening accountSettingsActions
    ******************* */
    case APPLY_REFERRAL_CODE: {
      return produce(state, (draft) => {
        draft.formReferralCodeSendData = payload || {};
      });
    }

    default: {
      return state;
    }
  }
};

export default subscriptionsReducer;
