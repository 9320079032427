import axios from 'src/utils/axios';
import helpers from 'src/helpers';
import { addNotification } from 'src/actions/notificationActions';
import { NOTIFICATION_STATUS, FORM_POST_HEADER } from 'src/constants';

export const SET_LOADING = '@signUp/set-loading';
export const SET_EMAIL_ADDRESS = '@signUp/set-formEmailAddress';

export const SIGNUP_STEPS = '@signUp/signup-steps';
export const SIGNUP_STEPS_SUCCESS = '@signUp/signup-steps-success';
export const SIGNUP_STEPS_ERROR = '@signUp/signup-steps-error';

export const CONFIRM_CONSULTANT = '@signUp/confirm-consultant';
export const CONFIRM_CONSULTANT_SUCCESS = '@signUp/confirm-consultant-success';
export const CONFIRM_CONSULTANT_ERROR = '@signUp/confirm-consultant-error';

export const UPDATE_FILTERS = '@signUp/update-filters';
export const UPDATE_FILTERS_SUCCESS = '@signUp/update-filters-success';
export const UPDATE_FILTERS_ERROR = '@signUp/update-filters-error';

export const SET_WATCHED_FORWARDING_VIDEO = '@signUp/set-watchedForwardingVideo';
export const SET_WATCHED_WELCOME_VIDEO = '@signUp/set-watchedWelcomeVideo';

export const GET_COMPANIES_LIST = '@signUp/get-companies-list';
export const GET_COMPANIES_LIST_SUCCESS = '@signUp/get-companies-list-success';
export const GET_COMPANIES_LIST_ERROR = '@signUp/get-companies-list-error';

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading
});

export const setFormEmailAddress = (formEmailAddress) => ({
  type: SET_EMAIL_ADDRESS,
  payload: formEmailAddress
});

export const signupSteps = (code, info, step, password, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SIGNUP_STEPS
    });

    const response = await axios.post('/api/signup/signupSteps', {
      info,
      password,
      authorizationCode: code,
      stepExecuting: step,
    }, FORM_POST_HEADER);
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SIGNUP_STEPS_ERROR, 'Invalid Email Address', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SIGNUP_STEPS_ERROR,
        payload: response?.data?.screenMessage || null, // 2023-08-28 changed to this
      });
      return;
    }

    dispatch(addNotification(SIGNUP_STEPS_SUCCESS, 'Completed Step!', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: SIGNUP_STEPS_SUCCESS,
      payload: response?.data || null,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const {
      screenMessage,
    } = error || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SIGNUP_STEPS_ERROR, message || errorMessage || error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SIGNUP_STEPS_ERROR,
      payload: screenMessage,
    });
    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const confirmIsAConsultant = (code, emailProvider, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: CONFIRM_CONSULTANT
    });

    const response = await axios.post('/api/signup/confirmIsAConsultant', { code, emailProvider });
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(CONFIRM_CONSULTANT_ERROR, 'Could Not Confirm Which Company You are With', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: CONFIRM_CONSULTANT_ERROR,
      });
      return;
    }

    dispatch(addNotification(CONFIRM_CONSULTANT_SUCCESS, 'Successfully Confirmed Your Company', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: CONFIRM_CONSULTANT_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(CONFIRM_CONSULTANT_ERROR, message || errorMessage || error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: CONFIRM_CONSULTANT_ERROR,
    });
    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const updateFiltersRules = (code, emailProvider, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_FILTERS
    });

    const response = await axios.put('/api/signup/updateFiltersRules', { code, emailProvider });
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(UPDATE_FILTERS_ERROR, "Could Not (Fully) Update Your Email's Filters/Rules", NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: UPDATE_FILTERS_ERROR,
      });
      return;
    }

    dispatch(addNotification(UPDATE_FILTERS_SUCCESS, "Successfully Updated Your Email's Filters/Rules", NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: UPDATE_FILTERS_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(UPDATE_FILTERS_ERROR, message || errorMessage || error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: UPDATE_FILTERS_ERROR,
    });
    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const getCompanies = () => async (dispatch) => {
  dispatch({
    type: GET_COMPANIES_LIST,
  });
  try {
    const response = await axios.get('/api/signup/getcompanieslist', FORM_POST_HEADER);
    dispatch({
      type: GET_COMPANIES_LIST_SUCCESS,
      payload: response?.data?.companies || []
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_COMPANIES_LIST_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_COMPANIES_LIST_ERROR,
    });
  }
};

export const setWatchedForwardingVideo = () => ({
  type: SET_WATCHED_FORWARDING_VIDEO,
});

export const setWatchedWelcomeVideo = () => ({
  type: SET_WATCHED_WELCOME_VIDEO,
});
