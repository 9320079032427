const popoverStyle = (theme) => ({
  popover: {
    maxWidth: '100%',
    width: 'auto',
  },
  paper: {
    padding: theme.spacing(1),
  },
});

export default popoverStyle;
