import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import helpers from 'src/helpers';

import messageBoxStyle from 'src/components/MessageBox/messageBox.style';

function MessageBox({
  classes,
  message,
  type,
  visible,
  paddingTop,
  paddingBottom,
  fullWidth,
}) {
  return visible && !helpers.isEmpty(message) && (
  <div className={`${classes.root} ${type} pt-${paddingTop} pb-${paddingBottom} ${fullWidth ? 'fullWidth' : ''}`}>
    <div className="inner">
      {message}
    </div>
  </div>
  );
}

MessageBox.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  type: PropTypes.oneOf([
    'type-1',
    'type-2',
    'type-3',
    'type-3-light',
    'type-3-red',
  ]),
  visible: PropTypes.bool,
  paddingTop: PropTypes.oneOf([
    '0',
    '10',
    '15',
    '20',
  ]),
  paddingBottom: PropTypes.oneOf([
    '0',
    '10',
    '15',
    '20',
  ]),
  fullWidth: PropTypes.bool,
};
MessageBox.defaultProps = {
  type: 'type-1',
  visible: false,
  paddingTop: '20',
  paddingBottom: '10',
  fullWidth: false,
};

export default withStyles(MessageBox, messageBoxStyle, { withTheme: true });
